import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Badge,
  Button,
  Row,
  Col,
} from "reactstrap";

import { Line } from "react-chartjs-2";

export default function CardChart({
  title,
  total,
  percent,
  color,
  chartExample,
}) {
  return (
    <Card>
      <CardHeader>
        <Row>
          <Col sm="7">
            <div className="numbers pull-left">{total}</div>
          </Col>
          <Col sm="5">
            <div className="pull-right">
              <Badge color={color} pill>
                +{percent}%
              </Badge>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <h6 className="big-title">{title}</h6>
        <Line
          data={chartExample.data}
          options={chartExample.options}
          height={380}
          width={826}
        />
      </CardBody>
      <CardFooter>
        <hr />
        <Row>
          <Col sm="7">
            <div className="footer-title">
              <a href="/" style={{ textDecoration: "none" }}>
                Mais detalhes
              </a>
            </div>
          </Col>
          <Col sm="5">
            <div className="pull-right">
              <Button className="btn-round btn-icon" color={color} size="sm">
                <i className="nc-icon nc-simple-add" />
              </Button>
            </div>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
}
