import { Form } from "@unform/web";
import { useAuth } from "context/AuthContext";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaTrash } from "react-icons/fa";
import NotificationAlert from "react-notification-alert";
import { Button } from "reactstrap";
import apiGd from "services/apiGd";
import Select from "views/components/Form/SelectInput";
import ImageNotifications from "views/components/ImageNotifications";
import { Category, Content, Fixed, Image, Link, Row, Table } from "./styles";

export const NotificationsSettings = () => {
  const notificationAlert = useRef(null);
  const { tokenGd } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [changes, setChanges] = useState([]);
  const [created, setCreated] = useState({});
  const [asteriskCount, setAsteriskCount] = useState(1);

  const categoryOptions = [
    { value: "new", label: "Novidade" },
    { value: "fix", label: "Correção" },
    { value: "update", label: "Atualização" },
  ];

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const getNotifications = async () => {
    const res = await apiGd.get("/admin/notifications", {
      headers: {
        Authorization: `Bearer: ${tokenGd}`,
      },
    });

    setNotifications(res.data);
  };

  const handleLink = (value) => {
    if (value.substring(0, 8) === "https://") {
      return value;
    } else {
      return "https://" + value;
    }
  };

  const handleChanges = useCallback(
    (id, field, value) => {
      const item = changes.find((c) => c.id === id);

      if (!item) {
        setChanges((state) => [...state, { id, [field]: value }]);
      } else {
        setChanges((state) => [
          ...state.filter((s) => s.id !== id),
          { ...item, [field]: value },
        ]);
      }
    },
    [changes]
  );

  const handleCreate = (field, value) => {
    setCreated((state) => {
      return { ...state, [field]: value };
    });
  };

  const handleDelete = async (id) => {
    const deleted = await apiGd.delete(`/admin/notifications/${id}`, {
      headers: {
        Authorization: `Bearer: ${tokenGd}`,
      },
    });

    setNotifications((state) => state.filter((s) => s.id !== deleted.data.id));
    notify("tr", "success", "Notificação deletada");
  };

  const putHtmlTags = (text) => {
    let asterisk = 0;
    let tilde = 0;
    const newValue = text.split("").map((c) => {
      if (c === "*") {
        asterisk++;
        if (asterisk % 2 === 0) {
          return "</b>";
        } else {
          return "<b>";
        }
      }
      if (c === "~") {
        tilde++;
        if (tilde % 2 === 0) {
          return "</i>";
        } else {
          return "<i>";
        }
      }
      return c;
    });

    return newValue.join("");
  };

  const saveChanges = useCallback(async () => {
    if (changes.length > 0) {
      changes.forEach((change) => {
        if (change.description) {
          change.description = putHtmlTags(change.description);
        }
      });

      const update = await apiGd.put(
        "/admin/notifications",
        { changes },
        {
          headers: {
            Authorization: `Bearer: ${tokenGd}`,
          },
        }
      );

      setNotifications((state) => [
        ...state.filter((s) => !update.data.ids.includes(s.id)),
        ...update.data.notifications,
      ]);
      notify("tr", "success", "Notificações atualizadas");
      setChanges([]);
    }

    if (!created.category && created.description) {
      notify("tr", "danger", "Categoria obrigatória");
    }
    if (created.category && !created.description) {
      notify("tr", "danger", "Conteúdo obrigatório");
    }
    if (created.category && created.description) {
      created.description = putHtmlTags(created.description);
      const res = await apiGd.post("/admin/notifications", created, {
        headers: {
          Authorization: `Bearer: ${tokenGd}`,
        },
      });

      setNotifications((state) => [...state, res.data]);
      notify("tr", "success", "Notificação criada");

      setCreated({});
      setIsCreating(false);
    }
  }, [created, changes, asteriskCount]);

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <h3>Configurar Notificações</h3>
        <div>
          <p>
            Negrito: *frase exemplo* = <b>frase exemplo</b>
          </p>
          <p>
            Itálico: ~frase exemplo~ = <i>frase exemplo</i>
          </p>
        </div>
        <div>
          <p>Tamanho ideal para as imagens: Largura entre 350px e 450px</p>
        </div>
        <Button color="success" onClick={() => setIsCreating(true)}>
          Criar novidade nova (kkkk)
        </Button>
        <Table>
          <Row>
            <Fixed>Fixado</Fixed>
            <Category>Categoria</Category>
            <Content>Conteúdo</Content>
            <Link>Link</Link>
            <Image>Imagem</Image>
          </Row>
          <Form>
            {isCreating && (
              <Row>
                <Fixed>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCreate("fixed", e.target.checked)}
                  />
                </Fixed>
                <Category>
                  <Select
                    name="type"
                    options={categoryOptions}
                    onChange={(e) => handleCreate("category", e.value)}
                  />
                </Category>
                <Content>
                  <textarea
                    onChange={(e) =>
                      handleCreate("description", e.target.value)
                    }
                  />
                </Content>
                <Link>
                  <input
                    onChange={(e) =>
                      handleCreate("link", handleLink(e.target.value))
                    }
                  />
                </Link>
                <Image>
                  <ImageNotifications
                    name="image"
                    handleCreate={handleCreate}
                  />
                </Image>
              </Row>
            )}
          </Form>
          {notifications.map((not) => (
            <Form initialData={{ image: not.file }}>
              <Row>
                <Fixed>
                  <input
                    type="checkbox"
                    defaultChecked={not.fixed}
                    onChange={(e) =>
                      handleChanges(not.id, "fixed", e.target.checked)
                    }
                  />
                </Fixed>
                <Category>
                  <Select
                    name="category"
                    options={categoryOptions}
                    defaultValue={categoryOptions.find(
                      (co) => co.value === not.category
                    )}
                    onChange={(e) => handleChanges(not.id, "category", e.value)}
                  />
                </Category>
                <Content>
                  <textarea
                    defaultValue={not.description}
                    onChange={(e) =>
                      handleChanges(not.id, "description", e.target.value)
                    }
                  />
                </Content>
                <Link>
                  <input
                    defaultValue={not.link?.substring(8)}
                    onChange={(e) =>
                      handleChanges(not.id, "link", handleLink(e.target.value))
                    }
                  />
                </Link>
                <Image>
                  <ImageNotifications
                    name="image"
                    handleChanges={handleChanges}
                    not_id={not.id}
                  />
                </Image>
                <FaTrash
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() => handleDelete(not.id)}
                />
              </Row>
            </Form>
          ))}
        </Table>
        <Button
          color="success"
          onClick={() => saveChanges()}
          style={{ float: "right" }}
        >
          Salvar
        </Button>
      </div>
    </>
  );
};
