import React from "react";
import { CardBody, Row, Col } from "reactstrap";
import { CardStyled } from "./styles";

export default function CardRestaurantGD({ restaurant }) {
  const data = new Date(restaurant.created_at);
  const image = restaurant.avatar ? restaurant.avatar.url : "";
  const newestSession = restaurant.last_session_date
    ? new Date(restaurant.last_session_date)
    : data;

  return (
    <>
      <CardStyled>
        <CardBody>
          <Row>
            <Col md="3" style={{ margin: "auto" }}>
              {image !== "" ? <img alt="..." src={image} /> : <p>Sem foto</p>}
            </Col>
            <Col md="9">
              <h6>{restaurant.fantasy_name}</h6>
              <span>{restaurant.name}</span>
              <p>{restaurant.email}</p>

              <p>
                Cliente desde {data.getUTCMonth() + 1 < 10 && "0"}
                {data.getUTCMonth() + 1}/{data.getFullYear()}
              </p>
              <p>Comandas: {restaurant.sessions_count}</p>
            {/* 
              <p>
                Última comanda:{" "}
                {newestSession.toLocaleDateString("pt-BR", { timeZone: "UTC" })}
              </p> */}
            </Col>
          </Row>
        </CardBody>
      </CardStyled>
    </>
  );
}
