import React from "react";
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from "reactstrap";

import { Bar } from "react-chartjs-2";

import { chartExample4 } from "variables/charts.jsx";

export default function CardChartPlus({ title, subtitle, legend, footer }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">{title}</CardTitle>
        <p className="card-category">{subtitle}</p>
      </CardHeader>
      <CardBody>
        <Bar data={chartExample4.data} options={chartExample4.options} />
      </CardBody>
      <CardFooter>
        <div className="legend">
          <i className="fa fa-circle text-danger" />
          {legend}
        </div>
        <hr />
        <div className="stats">
          <i className="fa fa-check" />
          {footer}
        </div>
      </CardFooter>
    </Card>
  );
}
