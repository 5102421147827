import React, { useState, useEffect, useRef, useCallback } from "react";
import NotificationAlert from "react-notification-alert";

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Button,
} from "reactstrap";

import { Form } from "@unform/web";
import Input from "./Input";

import { useRestaurants } from "context/RestaurantsContext";

import CardDeletedRestaurants from "./CardDeletedRestaurants";

import apiGd from "../../../../services/apiGd";

function RecoverRestaurants() {
  const notificationAlert = useRef(null);
  let { restaurants, getRestaurants } = useRestaurants();

  const [deletedRestaurants, setDeletedRestaurants] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (restaurants?.length === 0) {
      getRestaurants();
    }
  }, [restaurants, getRestaurants]);

  useEffect(() => {
    const deleted =
      search
        ? restaurants.filter(
          (restaurant) =>
            restaurant.deleted_at && (
              restaurant.fantasy_name.toLowerCase().includes(search) ||
              restaurant.name.replace(/^deleted\d+-/,'').includes(search)
            )
        )
        : restaurants.filter((restaurant) => restaurant.deleted_at);

    deleted.sort((a, b) => a.id - b.id);

    setDeletedRestaurants(deleted);
  }, [restaurants, search]);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: 'now-ui-icons ui-1_bell-53',
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [fill, setFill] = useState({});

  const [id, setId] = useState(0);

  const closeModal = () => {
    setFill({});
    setEmail('');
    setName('');
    setId(0);

    setShowModal(false);
  };

  const onRecover = useCallback(async (restaurant_id) => {
    const token = localStorage.getItem("@QRExpress:tokenGd");
    
    const data = { name, email };
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const response = await apiGd.put(
        `/admin/restaurants/recover/${restaurant_id}`,
        data,
        reqConfig
      );

      closeModal();

      const restaurant = deletedRestaurants.find((r) => r.id === restaurant_id);

      if (restaurant) {
        restaurant.name = response.data.name;
        restaurant.email = response.data.email;
        restaurant.deleted_at = null;
      }

      setDeletedRestaurants(
        deletedRestaurants.filter((r) => r.id !== restaurant_id)
      );

      notify("tr", "success", "Restaurante recuperado com sucesso!");
    } catch (err) {
      if (err?.response?.data?.errorType === 'name_or_email_already_in_use') {
        const { payload } = err.response.data;

        const shouldFill = {};

        if (payload.email) {
          setEmail(payload.email);
          shouldFill.email = true;
        }

        if (payload.name) {
          setName(payload.name);
          shouldFill.name = true;
        }

        setFill(shouldFill);
        setId(restaurant_id);
        setShowModal(true);
      }

      notify("tr", "danger", "Não foi possível recuperar o restaurante!");
    }
  }, [name, email, deletedRestaurants]);

  const confirm = useCallback(() => {
    onRecover(id);
  }, [onRecover, id]);

  return (
    <>
      <NotificationAlert ref={notificationAlert}  zIndex={10009999}/>

      <div className="content" style={{marginLeft: '50px', marginRight: '50px'}}>
        <Row md="12">
          <Col md="8">
            <h3>Estabelecimentos Apagados</h3>
          </Col>

          <Col md="4">
            <Form>
              <Input
                name="search"
                placeholder="Pesquisar"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form>
          </Col>
        </Row>

        {deletedRestaurants.length !== 0 ? (
          <Row>
            {deletedRestaurants.map((restaurant, i) => (
              <Col md="4" key={i}>
                <CardDeletedRestaurants
                  restaurant={restaurant}
                  onRecover={onRecover}/>
              </Col>
            ))}
          </Row>
        ) : (
          <h4 style={{margin: '20px', width: 'fit-content'}}>
            Nenhum restaurante nessa categoria
          </h4>
        )}

        <Modal isOpen={showModal} toggle={() => closeModal(!showModal)}>
          <ModalHeader> Recuperar restaurante </ModalHeader>
          <ModalBody>
            <div style={{marginBottom: '12px', color: 'grey', fontWeight: 'bold'}}>
              Já existe um outro restaurante com o(s) campo(s) a seguir, defina-os novamente.
            </div>

            <Form initialData={{ name, email }}>
              <Container>
                {fill.name && (
                  <Row>
                    <Col md="10">
                      <label> Username </label>
                      <Input
                        name="name"
                        type="text"
                        placeholder="Username"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Col>
                  </Row>
                )}

                {fill.email && (
                  <Row>
                    <Col md="10">
                      <label> E-mail </label>
                      <Input
                        name="email"
                        type="text"
                        placeholder="E-mail"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Col>
                  </Row>
                )}
              </Container>
            </Form>
          </ModalBody>
          <ModalFooter style={{ paddingRight: '6px' }}>
            <Button color="primary" onClick={confirm}>
              Confirmar
            </Button>{" "}
            <Button color="danger" onClick={closeModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default RecoverRestaurants;
