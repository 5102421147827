import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { Modal, ModalBody } from "reactstrap";
import formatCompleteDate from "../../../services/formatCompleteDate";
import EyeModalOrders from "../EyeModalOrders";

import { Eye } from "./styles";

export default function Order({ order }) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  let data = "";
  let dataApproved = "";

  if (
    order.order_status === "canceled" ||
    order.order_status === "chargeback"
  ) {
    data = order.start_time;
    if (order.canceled_at !== null) {
      dataApproved = order.canceled_at;
    }
  } else {
    data = order.start_time;
    if (order.payment_approved_date !== null) {
      dataApproved = order.payment_approved_date;
    }
  }

  let status = "";
  switch (order.order_status) {
    case "canceled":
      status = "Cancelado";
      break;
    case "chargeback":
      status = "Reembolsado";
      break;
    case "done":
      status = "Pronto";
      break;
    case "finished":
      status = "Entregue";
      break;
    case "expired":
      status = "Expirado";
      break;
    case "pending":
      status = "Pendente";
      break;
    case "waiting_payment":
      status = "Aguardando Pagamento";
      break;
    case "accepted":
      status = "Em Preparo";
      break;
    default:
      break;
  }

  console.log(order);

  let status_payment =
    order.payment_method === "withdrawal"
      ? "Pagamento na retirada."
      : "Aguardando pagamento.";
  let accepted_in = order.time_to_accept;
  let done_in = order.time_to_be_done;

  if (order.order_status === "pending") {
    accepted_in = "-";
    done_in = "-";
  } else if (order.order_status === "accepted") {
    done_in = "-";
  }
  return (
    <>
      <tr>
        <td>
          {order.food_court.avatar ? (
            <img alt="" src={order.food_court.avatar.url} width={130} />
          ) : (
            <span>{order.food_court.fantasy_name}</span>
          )}
        </td>
        <td>{order.restaurant.fantasy_name}</td>
        {/* <td>{order.payment_approved_date && <span>tem</span>}</td> */}
        <td>
          {data !== "" ? formatCompleteDate(data) : <>Aguardando pagamento.</>}
        </td>
        <td>
          {dataApproved !== "" ? (
            formatCompleteDate(dataApproved)
          ) : (
            // <>{data}</>
            <>{status_payment}</>
          )}
        </td>
        <td>{order.attendance_password.password_number}</td>
        <td>R${order.total_price_with_sms}</td>
        <td>{status}</td>
        <td>{accepted_in} min.</td>
        <td>{done_in} min.</td>
        <td className="text-right">
          <Eye onClick={toggle}>
            <AiOutlineEye color="blue" size={20} />
          </Eye>
        </td>

        <Modal isOpen={modal} toggle={toggle}>
          <ModalBody>
            <EyeModalOrders item={order} status={status} done_in={done_in} />
          </ModalBody>
        </Modal>
      </tr>
    </>
  );
}
