export default function style(from = null) {
  if (from) {
    return { ...from };
  }

  return {
    Font(color, valign = '', halign = '') {
      if (this.font) {
        this.font.color = { rgb: `ff${color}` };
      } else {
        this.font = {
          color: { rgb: `ff${color}` },
        };
      }

      if (valign || halign) {
        this.alignment = {};

        if (valign) {
          this.alignment.vertical = valign;
        }

        if (halign) {
          this.alignment.horizontal = halign;
        }
      }
      return this;
    },
    Bold(sz = 11, b = true) {
      if (this.font) {
        this.font.bold = b;
        this.font.sz = sz;
      } else {
        this.font = {
          bold: b,
          sz,
        };
      }

      return this;
    },
    Bg(color) {
      this.fill = {
        fgColor: { rgb: `ff${color}` },
      };
      return this;
    },
    Borders(directions, color = '000000') {
      if (!this.border) {
        this.border = {};
      }

      const bStyle = {
        color: { rgb: `ff${color}` },
        style: 'thin',
      };

      for (let i = 0; i < directions.length; i++) {
        switch (directions[i]) {
          case 'l':
            this.border.left = bStyle;
            break;
          case 'r':
            this.border.right = bStyle;
            break;
          case 'b':
            this.border.bottom = bStyle;
            break;
          case 't':
            this.border.top = bStyle;
            break;
          default:
            break;
        }
      }

      return this;
    },
    Number(fmt = '0.00') {
      this.numFmt = fmt;
      return this;
    },
    Get() {
      const obj = { ...this };
      delete obj.Get;
      delete obj.Font;
      delete obj.Bold;
      delete obj.Number;
      delete obj.Bg;
      delete obj.Borders;

      return obj;
    },
  };
}
