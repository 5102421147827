import styled from "styled-components";

export const Table = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  padding: 10px 20px;

  input,
  textarea {
    &:focus {
      outline: none;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid lightgrey;
`;

export const Fixed = styled.div`
  flex-basis: 75px;
  padding: 0 10px;
`;

export const Category = styled.div`
  flex-basis: 150px;
  padding: 0 5px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  flex-basis: 300px;
  height: 100%;

  textarea {
    width: 100%;
    height: 90%;
    resize: none;
    border-radius: 4px;
    border: 1px solid grey;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Link = styled.div`
  flex-basis: 200px;

  input {
    width: 95%;
    border-radius: 4px;
    border: 1px solid grey;
    text-align: center;
  }
`;

export const Image = styled.div`
  flex-grow: 1;
  padding: 5px;
  max-height: 78px;

  div {
    margin: 0 auto;
  }
`;

export const ButtonNew = styled.button`
  padding: 5px 10px;
  border: none;
  margin-bottom: 15px;
  border-radius: 8px;
`;
