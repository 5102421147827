import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  Collapse,
} from "reactstrap";
import CardProfileRestaurant from "components/Cards/CardProfileRestaurant";
import { Form } from "@unform/web";
import { Input } from "@material-ui/core";
import { DateSelectorDiv, DateDiv, SubmitDate } from "./styles";
import Order from "../../components/Order";

import CardDash from "components/Cards/CardDash";
// import CardTable from "components/Cards/CardTable/CardTable";
// import CardTopEstablishment from "components/Cards/CardTopEstablishment";
// import CardChart from "components/Cards/CardChart";
// import CardChartPlus from "components/Cards/CardChartPlus";
// import {
//   chartExample1,
//   chartExample2,
//   chartExample3,
// } from "variables/charts.jsx";
import NotificationAlert from "react-notification-alert";
import api from "../../../services/api";
import history from "../../../services/history";
import { format, startOfDay, endOfDay, subHours, startOfWeek, startOfMonth, addHours} from 'date-fns';

function ViewRestaurantExpress(props) {
  const { id } = props.match.params;
  let [restaurant, setRestaurant] = useState([]);
  const formRef = useRef(null);
  const [time, setTime] = useState(null);
  const [ordersPending, setOrdersPending] = useState([]);
  const [ordersNotPending, setOrdersNotPending] = useState([]);
  const [ordersCanceled, setOrdersCanceled] = useState([]);
  const [ordersTotal, setOrdersTotal] = useState(0);
  const token = localStorage.getItem("@QRExpress:token");
  const notificationAlert = useRef();

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  let inicialFormat = format(new Date(defaultInicial), "dd/MM/yyyy, HH:mm");
  let finalFormat = format(new Date(defaultFinal), "dd/MM/yyyy, HH:mm");
  let phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  function setToday(){
    setInicialDate(format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek(){
    setInicialDate(format(startOfWeek(subHours(new Date(), 3), {weekStartsOn: 1}), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfWeek(subHours(new Date(), 3), {weekStartsOn: 1}), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth(){
    setInicialDate(format(startOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfMonth(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  let [initialPre, setInitialPre] = useState(inicialDate);
  let [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    setInicialDate(format(subHours(new Date(initialPre), 3), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(subHours(new Date(finalPre), 3), "yyyy-MM-dd'T'HH:mm:ss"));
    let inicialFormat = format(new Date(initialPre), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(new Date(finalPre), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    
    setHourPhrase(phrase);
  }

  const [inicialDateSearch, setInicialDateSearch] = useState(format(addHours(new Date(inicialDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));
  const [finalDateSearch, setFinalDateSearch] = useState(format(addHours(new Date(finalDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));

  useEffect(() => {
    setInicialDateSearch(format(addHours(new Date(inicialDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDateSearch(format(addHours(new Date(finalDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));  
  }, [inicialDate, finalDate]);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const getOrdersPending = useCallback(async () => {
    try {
      const response = await api.get(
        `admin/food-courts/restaurants/orders/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            status: "pending",
          },
        }
      );
      let orderPending = response.data.filter(
        (order) => order.payment_approved_date !== null
      );
      setOrdersPending(orderPending);

      const data = new Date();
      const time = `${data.getUTCHours() - 3}:${data.getUTCMinutes()}`;
      setTime(time);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token, id]);

  const getOrdersNotPending = useCallback(async () => {
    try {
      const response = await api.get(
        `admin/food-courts/restaurants/orders/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            start_date: `${inicialDateSearch}`,
            end_date: `${finalDateSearch}`,
          },
        }
      );
      let orderNotPending = response.data.filter(
        (order) =>
          order.payment_approved_date !== null &&
          order.order_status !== "pending" &&
          order.order_status !== "canceled" &&
          order.order_status !== "chargeback"
      );
      setOrdersNotPending(orderNotPending);

      let ordersTotalPrice = 0;

      orderNotPending.forEach((order) => {
        ordersTotalPrice = ordersTotalPrice + parseFloat(order.total_price);
      });

      setOrdersTotal(`R$${ordersTotalPrice.toFixed(2)}`);

      let orderCanceled = response.data.filter(
        (order) =>
          order.order_status === "canceled" ||
          order.order_status === "chargeback"
      );
      setOrdersCanceled(orderCanceled);

      const data = new Date();
      const time = `${data.getUTCHours() - 3}:${
        data.getUTCMinutes() < 10
          ? `0${data.getUTCMinutes()}`
          : data.getUTCMinutes()
      }`;
      setTime(time);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token, inicialDateSearch, finalDateSearch, id]);

  const getInfo = useCallback(async () => {
    try {
      const token = localStorage.getItem("@QRExpress:token");
      const response = await api.get(`admin/restaurants/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setRestaurant(response.data);
    } catch (err) {
      notify("tr", "danger", "Falha ao atualizar.");
    }
  }, [id]);

  useEffect(() => {
    getOrdersPending();
    getOrdersNotPending();
    getInfo();
  }, [getOrdersPending, getOrdersNotPending, getInfo]);

  function refresh() {
    getOrdersPending();
    getOrdersNotPending();
  }

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="3">
            <Button color="default" onClick={() => history.goBack()}>
              <span className="btn-label">
                <i className="nc-icon nc-minimal-left" />
              </span>
              Voltar
            </Button>
          </Col>
          <Col md="9">
            <div style={{ float: "right" }}>
              <Button color="danger" onClick={refresh}>
                <span className="btn-label">
                  <i className="nc-icon nc-refresh-69" />
                </span>
                Atualizar
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
            <Col md="4"><p>{hourPhrase}</p></Col>
            <Col md="8">
              <div style={{ float: "right" }}>
                <Button className="btn-round mr-auto" onClick={setToday} color="info">
                  Hoje
                </Button>
                <Button className="btn-round mr-auto" onClick={setWeek} color="info">
                  Essa semana
                </Button>
                <Button className="btn-round mr-auto" onClick={setMonth} color="info">
                  Esse mês
                </Button>
                <Button className="btn-round mr-auto" onClick={toggle} color="info">
                  Selecionar Período
                  <b className="caret" style={{marginLeft: "10px"}}/>
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div style={{ float: "right", marginRight: "15px" }}>
                <Collapse isOpen={isOpen}>
                  <Form onSubmit={handleChangeDate} ref={formRef}>
                    <DateSelectorDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Inicial"
                          type="datetime-local"
                          name="initialDate"
                          onChange={(e) => setInitialPre(e.target.value)}
                          defaultValue={defaultInicial}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Final"
                          type="datetime-local"
                          name="finalDate"
                          onChange={(e) => setFinalPre(e.target.value)}
                          defaultValue={defaultFinal}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <div>
                        <SubmitDate onClick="submit">Filtrar</SubmitDate>
                      </div>
                    </DateSelectorDiv>
                  </Form>
                </Collapse>
              </div>
            </Col>
          </Row>
        <Row>
          <Col md="5">
            <CardProfileRestaurant
              restaurant={restaurant}
            ></CardProfileRestaurant>
          </Col>
          <Col md="7">
            <Row>
              <Col lg="12" md="6" sm="6">
                <CardDash
                  title="Arrecadação"
                  total={ordersTotal}
                  icon="nc-icon nc-money-coins text-success"
                ></CardDash>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" sm="6">
                <CardDash
                  title="Pedidos Feitos"
                  total={ordersNotPending.length}
                  icon="nc-icon nc-tap-01 text-info"
                ></CardDash>
              </Col>
              <Col lg="6" md="6" sm="6">
                <CardDash
                  title="Cancelados"
                  total={ordersCanceled.length}
                  icon="nc-icon nc-simple-remove text-danger"
                ></CardDash>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardHeader style={{ display: "flex", flexDirection: "row" }}>
                <Col md="8">
                  <CardTitle tag="h4">Pedidos Pendentes</CardTitle>
                </Col>
                <Col md="4">
                  <div style={{ float: "right" }}>
                    <p>Última Atualização {time}</p>
                  </div>
                </Col>
              </CardHeader>
              <CardBody>
                {ordersPending.length !== 0 ? (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Shopping</th>
                        <th>Restaurante</th>
                        <th>Pedido Feito</th>
                        <th>Pedido Aprovado</th>
                        <th>Pedido</th>
                        <th>Valor</th>
                        <th>Status</th>
                        <th className="text-right">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordersPending.map((order) => (
                        <Order order={order} />
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div style={{ textAlign: "center", color: "red" }}>
                    <span>Nenhum pedido pendente.</span>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardHeader style={{ display: "flex", flexDirection: "row" }}>
                <Col md="8">
                  <CardTitle tag="h4">Pedidos Feitos</CardTitle>
                </Col>
                <Col md="4">
                  <div style={{ float: "right" }}>
                    <p>Última Atualização {time}</p>
                  </div>
                </Col>
              </CardHeader>
              <CardBody>
                {ordersNotPending.length > 0 ? (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Shopping</th>
                        <th>Restaurante</th>
                        <th>Pedido Feito</th>
                        <th>Pedido Aprovado</th>
                        <th>Pedido</th>
                        <th>Valor</th>
                        <th>Status</th>
                        <th className="text-right">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordersNotPending.map((order) => (
                        <Order order={order} />
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div style={{ textAlign: "center", color: "red" }}>
                    <span>Nenhum pedido feito no período selecionado.</span>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardHeader style={{ display: "flex", flexDirection: "row" }}>
                <Col md="8">
                  <CardTitle tag="h4">Pedidos Cancelados</CardTitle>
                </Col>
                <Col md="4">
                  <div style={{ float: "right" }}>
                    <p>Última Atualização {time}</p>
                  </div>
                </Col>
              </CardHeader>
              <CardBody>
                {ordersCanceled.length > 0 ? (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Shopping</th>
                        <th>Restaurante</th>
                        <th>Pedido Feito</th>
                        <th>Pedido Aprovado</th>
                        <th>Pedido</th>
                        <th>Valor</th>
                        <th>Status</th>
                        <th className="text-right">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordersCanceled.map((order) => (
                        <Order order={order} />
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div style={{ textAlign: "center", color: "red" }}>
                    <span>Nenhum pedido cancelado no período selecionado.</span>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* <Row>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Clientes"
                total={987}
                icon="nc-icon nc-single-02 text-info"
              ></CardDash>
            </Col>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Arrecadação"
                total="R$1,247.68"
                icon="nc-icon nc-money-coins text-success"
              ></CardDash>
            </Col>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Pedidos"
                total="125"
                icon="nc-icon nc-tap-01 text-danger"
              ></CardDash>
            </Col>
          </Row>
          <Row>
            <Col lg="4" sm="6">
              <CardChart
                total="124"
                percent="12"
                title="Clientes na última hora"
                color="info"
                chartExample={chartExample3}
              ></CardChart>
            </Col>
            <Col lg="4" sm="6">
              <CardChart
                total="R$258.37"
                percent="16"
                title="Arrecadações na última hora"
                color="success"
                chartExample={chartExample1}
              ></CardChart>
            </Col>
            <Col lg="4" sm="6">
              <CardChart
                total="84"
                percent="11.4"
                title="Pedidos na última hora"
                color="danger"
                chartExample={chartExample2}
              ></CardChart>
            </Col>
          </Row>
          <Row>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Média Avaliações Estabelecimentos"
                total="4.1"
                icon="fa fa-star text-warning"
              ></CardDash>
            </Col>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Praças de Alimentação"
                total="13"
                icon="nc-icon nc-shop text-danger"
              ></CardDash>
            </Col>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Estabelecimentos"
                total="45"
                icon="nc-icon nc-shop text-success"
              ></CardDash>
            </Col>
          </Row>
          <Row>
            <Col md="7">
              <CardTable
                title="Vendas Por Estabelecimento"
                subtitle="Confira quanto cada estabelecimento esta arrecadando e tenha
                    controle de seu Shopping!"
              ></CardTable>
            </Col>

            <Col md="5">
              <CardTopEstablishment source="mcdonalds"></CardTopEstablishment>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <CardChartPlus
                title="Vendas de Julho"
                subtitle="Venda total do shopping por dia do mês"
                legend="Dias do Mês"
                footer="Atualizado diariamente"
              ></CardChartPlus>
            </Col>
          </Row> */}
      </div>
    </>
  );
}

export default ViewRestaurantExpress;
