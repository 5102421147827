import React, { createContext, useCallback, useState, useContext } from "react";
import api from "../services/api";
import apiGd from "../services/apiGd";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem("@QRExpress:token");
    const tokenGd = localStorage.getItem("@QRExpress:tokenGd");
    const user = localStorage.getItem("@QRExpress:user");

    if (token && user && tokenGd) {
      return { token, tokenGd, user: JSON.parse(user) };
    }

    return {};
  });

  const signIn = useCallback(async ({ email, password }) => {
    // const response = await api.post("public/sessions/restaurants", {
    //   email,
    //   password,
    // });

    const responseGd = await apiGd.post("public/sessions/restaurants", {
      email,
      password,
    });

    const tokenGd = responseGd.data.token;
    const { token, user } = responseGd.data;

    if (user.admin) {
      localStorage.setItem("@QRExpress:token", token);
      localStorage.setItem("@QRExpress:tokenGd", tokenGd);
      localStorage.setItem("@QRExpress:user", JSON.stringify(user));

      setData({ token, user, tokenGd });
      return true;
    } else {
      return false;
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@QRExpress:token");
    localStorage.removeItem("@QRExpress:tokenGd");
    localStorage.removeItem("@QRExpress:user");

    setData({});
  }, []);

  const updateProfile = useCallback(async (props) => {
    try {
      const token = localStorage.getItem("@QRExpress:token");
      const profile = {
        fantasy_name: props.fantasy_name,
        email: props.email,
        phone: props.phone,
        avatar_id: props.avatar,
      };

      const response = await api.put("restaurants", profile, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const user = response.data;
      localStorage.setItem("@QRExpress:user", JSON.stringify(user));

      setData({ token, user });
      return true;
    } catch (err) {
      return false;
    }
  }, []);

  const updatePassword = useCallback(async ({ oldPassword, password }) => {
    try {
      const token = localStorage.getItem("@QRExpress:token");

      if(!data.user.user_id){
        return false;
      }

      await apiGd.put(`restaurants/users/${data.user.user_id}`, 
        { oldPassword, password }, 
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return true;
    } catch (err) {
      return false;
    }
  }, [data]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        updateProfile,
        updatePassword,
        tokenGd: data.tokenGd,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
