import React, { useEffect, useState } from "react";
import { CardBody, Row, Col } from "reactstrap";
import { CardStyled, Link } from "./styles";

export default function CardDeletedRestaurants({ restaurant, onRecover }) {
  const image = restaurant.avatar ? restaurant.avatar.url : "";
  const username = restaurant.name.replace(/^deleted\d+-/,'');
  const email = restaurant.email.replace(/^deleted\d+-/,'');

  const createdAt = new Date(restaurant.created_at);
  const deletedAt = new Date(restaurant.deleted_at);

  return (
    <>
      <CardStyled>
        <CardBody>
          <Row>
            <Col md="3" style={{ margin: "auto" }}>
              {image !== "" ? <img alt="..." src={image} /> : <p>Sem foto</p>}
            </Col>
            <Col md="9">
              <h6>{restaurant.fantasy_name}</h6>
              <span>{username}</span>
              <p>{email}</p>

              <div>
                Criado em:{" "}
                {createdAt.toLocaleDateString("pt-BR", { timeZone: "UTC" })}
              </div>
              <div>
                Apagado em:{" "}
                {deletedAt.toLocaleDateString("pt-BR", { timeZone: "UTC" })}
              </div>
            </Col>
          </Row>

          <hr />
          <Link onClick={() => onRecover(restaurant.id)}>
            Recuperar
          </Link>
        </CardBody>
      </CardStyled>
    </>
  );
}
