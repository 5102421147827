/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import XLSX from "xlsx-js-style";
import { format, parseISO } from "date-fns";

async function getData(restaurants) {
  const sheet_body2 = [];
  const sheet_body = restaurants.map((rest => {
    const sheet_item = {
      restaurant: `Geral ${rest.fantasy_name}`,
      mrr: rest.mrr ? rest.mrr : "-",
      variation: (rest.answers[0] > 0 && rest.answers[1] > 0) ? (rest.answers[0] / rest.answers[1]).toFixed(2) : "-",
    };
    rest.answers.forEach((ans, i) => {
      const dt = new Date(rest.users[0].answers[i].createdAt);
      const year = dt.getFullYear();
      const month = dt.getMonth();
      const day = dt.getDate();
      const hour = dt.getHours() + 3;
      sheet_item[`${format(new Date(year, month, day, hour), "MMM")} value`] = ans > 0 ? { v: ans.toFixed(2), t: "n" } : "-";
      sheet_item[`${format(new Date(year, month, day, hour), "MMM")} comment`] = ans.comment ? `(${format(new Date(year, month, day, hour), "dd/MMM")}) - ${ans.comment}` : "-";
    });
    sheet_body2.push([...Object.values(sheet_item)]);

    rest.users.forEach((usr) => {
      const usr_item = {
        restaurant: `- usuario: ${usr.name}\n -email: ${usr.email}`,
        mrr: rest.mrr ? rest.mrr : "-",
        variation: (usr.answers[0].value > 0 && usr.answers[1].value > 0) ? (usr.answers[0].value / usr.answers[1].value).toFixed(2) : "-",
      };
      usr.answers.forEach((ans) => {
        const dt = new Date(ans.createdAt);
        const year = dt.getFullYear();
        const month = dt.getMonth();
        const day = dt.getDate();
        const hour = dt.getHours() + 3;
        usr_item[`${format(new Date(year, month, day, hour), "MMM")} value`] = ans.value > 0 ? { v: ans.value, t: "n" } : "-";
        usr_item[`${format(new Date(year, month, day, hour), "MMM")} comment`] = ans.comment.length > 0 ? `(${format(new Date(year, month, day, hour), "dd/MMM")}) - ${ans.comment}` : "-";
      });
      sheet_body2.push([...Object.values(usr_item)]);
    })
    return [...Object.values(sheet_item)];
  }));


  const sheet_header = [
    "Restaurante",
    "MRR",
    "Variação CSAT (mês anterior)",
  ];

  restaurants[0].users[0].answers.forEach(ans => {
    const dt = new Date(ans.createdAt);
    const year = dt.getFullYear();
    const month = dt.getMonth();
    const day = dt.getDate() + 1;
    sheet_header.push(`CSAT ${format(new Date(year, month, day), "MMM")}`);
    sheet_header.push(`Comentário ${format(new Date(year, month, day), "MMM")}`);
  });

  return {
    data: [sheet_header, ...sheet_body],
    data2: [sheet_header, ...sheet_body2],
  };
}

export async function generateCsatResearchSheet(
  restaurants
) {
  const { data, data2 } = await getData(
    restaurants
  );

  const columns = "abcdefghijklmnopqrstuvwxyz".toLocaleUpperCase().split("");

  const num_cols = data[0].length;

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(data);
  const worksheet2 = XLSX.utils.aoa_to_sheet(data2);

  worksheet["!cols"] = new Array(num_cols);
  worksheet["!cols"].fill({ wch: 15 });
  worksheet2["!cols"] = new Array(num_cols);
  worksheet2["!cols"].fill({ wch: 15 });
  const num_lines = data.length;

  for (let j = 0; j < num_cols; j++) {
    for (let i = 1; i <= num_lines; i++) {
      const cell = worksheet[columns[j] + i];
      const cell2 = worksheet2[columns[j] + i];
      if (i === 1) {
        cell.s = {
          fill: {
            fgColor: {
              rgb: "ff6d6d",
            },
            bgColor: {
              rgb: "ff6d6d",
            },
          },
          border: {
            top: {
              style: "thin",
              color: {
                rgb: "000000",
              },
            },
            bottom: {
              style: "thin",
              color: {
                rgb: "000000",
              },
            },
          },
        };
        cell2.s = {
          fill: {
            fgColor: {
              rgb: "ff6d6d",
            },
            bgColor: {
              rgb: "ff6d6d",
            },
          },
          border: {
            top: {
              style: "thin",
              color: {
                rgb: "000000",
              },
            },
            bottom: {
              style: "thin",
              color: {
                rgb: "000000",
              },
            },
          },
        }
      }

      if (j > 0) {
        cell.s = { ...cell.s, alignment: { horizontal: "center" } };
        cell2.s = { ...cell.s, alignment: { horizontal: "center" } };
      }
      
      if (j === 2 || j === 4 || j === 6 || j === 8 || j === 10 || j === 12 || j === 14) {
        cell.s = { ...cell.s, alignment: { horizontal: "center", wrapText: true } };
        cell2.s = { ...cell2.s, alignment: { horizontal: "center", wrapText: true } };
        if (cell.v.length > 1) {
          cell.s = { ...cell.s, alignment: { horizontal: "left", wrapText: true  } };
        }
        if (cell2.v.length > 1) {
          cell2.s = { ...cell2.s, alignment: { horizontal: "left", wrapText: true  } };
        }
      }
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, "Pesquisa");
  XLSX.utils.book_append_sheet(workbook, worksheet2, "Pesquisa Detalhada");

  return XLSX.write(workbook, { type: "buffer" });
}
