import React from "react";
import { Row, Col } from "reactstrap";
import CardRestaurantGD from "../CardRestaurantGD";
import CardNewRestaurantGD from "../CardNewRestaurantsGD";
import CardInactiveRestaurants from "../CardInactiveRestaurants";
import { LinkStyled } from "views/pages/styles";
import { MessageNoRestaurant } from "./styles";

import { startOfDay, subDays } from "date-fns";

export function AllList({ array, search }) {
  if (search?.length > 0) {
    array = array?.filter((rest) =>
      rest.fantasy_name.toLowerCase().includes(search)
    );
  }
  
  return array?.length !== 0 ? (
    <Row>
      {array?.map((restaurant, i) => (
        <Col md="4">
          <LinkStyled
            to={{
              pathname: `/gd/restaurants/edit/${restaurant.id}`,
              state: { restaurant },
            }}
          >
            <CardRestaurantGD restaurant={restaurant} />
          </LinkStyled>
        </Col>
      ))}
    </Row>
  ) : (
    <MessageNoRestaurant>
      Nenhum restaurante nessa categoria
    </MessageNoRestaurant>
  );
}

export function LessThan100List({ array, search }) {
  const restaurants =
    search?.length > 0
      ? array.filter(
          (restaurant) =>
            !restaurant.only_qrcode &&
            restaurant.sessions_count < 100 &&
            restaurant.fantasy_name.toLowerCase().includes(search)
        )
      : array.filter(
          (restaurant) =>
            !restaurant.only_qrcode && restaurant.sessions_count < 100
        );

  restaurants.sort((a, b) => b.id - a.id);

  return restaurants.length !== 0 ? (
    <Row>
      {restaurants.map((restaurant, i) => (
        <Col md="4" key={i}>
          <LinkStyled
            to={{
              pathname: `/gd/restaurants/edit/${restaurant.id}`,
              state: { restaurant },
            }}
          >
            <CardNewRestaurantGD restaurant={restaurant} />
          </LinkStyled>
        </Col>
      ))}
    </Row>
  ) : (
    <MessageNoRestaurant>
      Nenhum restaurante nessa categoria
    </MessageNoRestaurant>
  );
}

export function ThreeDaysList({ array, search }) {
  const today = startOfDay(new Date());
  const start_date = subDays(today, 7).valueOf();
  const end_date = subDays(today, 3).valueOf();

  const restaurants =
    search?.length > 0
      ? array.filter((restaurant) => {
          const created_at = new Date(restaurant.created_at).valueOf();
          const last_session_date = new Date(
            restaurant.last_session_date || 0
          ).valueOf();

          return (
            !restaurant.only_qrcode &&
            created_at < start_date &&
            last_session_date > start_date &&
            last_session_date < end_date &&
            restaurant.fantasy_name.toLowerCase().includes(search)
          );
        })
      : array.filter((restaurant) => {
          const created_at = new Date(restaurant.created_at).valueOf();
          const last_session_date = new Date(
            restaurant.last_session_date || 0
          ).valueOf();

          return (
            !restaurant.only_qrcode &&
            created_at < start_date &&
            last_session_date > start_date &&
            last_session_date < end_date
          );
        });

  restaurants.sort((a, b) => a.id - b.id);

  return restaurants.length !== 0 ? (
    <Row>
      {restaurants.map((restaurant, i) => (
        <Col md="4" key={i}>
          <LinkStyled
            to={{
              pathname: `/gd/restaurants/edit/${restaurant.id}`,
              state: { restaurant },
            }}
          >
            <CardInactiveRestaurants restaurant={restaurant} />
          </LinkStyled>
        </Col>
      ))}
    </Row>
  ) : (
    <MessageNoRestaurant>
      Nenhum restaurante nessa categoria
    </MessageNoRestaurant>
  );
}

export function SevenDaysList({ array, search }) {
  const today = startOfDay(new Date());
  const start_date = subDays(today, 7).valueOf();

  const restaurants =
    search?.length > 0
      ? array.filter((restaurant) => {
          const created_at = new Date(restaurant.created_at).valueOf();
          const last_session_date = new Date(
            restaurant.last_session_date
          ).valueOf();

          return (
            !restaurant.only_qrcode &&
            created_at < start_date &&
            last_session_date < start_date &&
            restaurant.fantasy_name.toLowerCase().includes(search)
          );
        })
      : array.filter((restaurant) => {
          const created_at = new Date(restaurant.created_at).valueOf();
          const last_session_date = new Date(
            restaurant.last_session_date
          ).valueOf();

          return (
            !restaurant.only_qrcode &&
            created_at < start_date &&
            last_session_date < start_date
          );
        });

  restaurants.sort((a, b) => a.id - b.id);

  return restaurants.length !== 0 ? (
    <Row>
      {restaurants.map((restaurant, i) => (
        <Col md="4" key={i}>
          <LinkStyled
            to={{
              pathname: `/gd/restaurants/edit/${restaurant.id}`,
              state: { restaurant },
            }}
          >
            <CardInactiveRestaurants restaurant={restaurant} />
          </LinkStyled>
        </Col>
      ))}
    </Row>
  ) : (
    <MessageNoRestaurant>
      Nenhum restaurante nessa categoria
    </MessageNoRestaurant>
  );
}

export function SuspensionList({ array, search }) {
  const restaurants =
    search?.length > 0
      ? array.filter(
          (restaurant) =>
            restaurant.suspension_alert &&
            restaurant.fantasy_name.toLowerCase().includes(search)
        )
      : array.filter((restaurant) => restaurant.suspension_alert);

  restaurants.sort((a, b) => a.id - b.id);

  return restaurants.length !== 0 ? (
    <Row>
      {restaurants.map((restaurant, i) => (
        <Col md="4" key={i}>
          <LinkStyled
            to={{
              pathname: `/gd/restaurants/edit/${restaurant.id}`,
              state: { restaurant },
            }}
          >
            <CardInactiveRestaurants restaurant={restaurant} />
          </LinkStyled>
        </Col>
      ))}
    </Row>
  ) : (
    <MessageNoRestaurant>
      Nenhum restaurante nessa categoria
    </MessageNoRestaurant>
  );
}
