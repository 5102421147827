/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import XLSX from 'xlsx-js-style';
import { format } from 'date-fns';

async function getData(answers) {
  const sheet_body = answers.map((ans => {
    const sheet_item = {
      restaurant: `${ans?.restaurant?.fantasy_name} (${ans?.restaurant?.name})`,
      value: ans.value,
      comment: ans.comment || '-',
      user: `${ans?.user?.name} (${ans?.user?.access})`,
      created_at: ans.createdAt
        ? format(new Date(ans.createdAt), 'dd/MM/yyyy')
        : '-',
    };

    return [
      sheet_item.restaurant,
      sheet_item.value,
      sheet_item.comment,
      sheet_item.user,
      sheet_item.created_at,
    ];
  }));

  const sheet_header = [
    'Restaurante',
    'Nota',
    'Comentário',
    'Usuário',
    'Data',
  ];

  return {
    data: [sheet_header, ...sheet_body],
  };
}

export async function generateResearchSheet(
  answers
) {
  const { data } = await getData(
    answers
  );

  const columns = 'abcdefghijklmnopqrstuvwxyz'.toLocaleUpperCase().split('');

  const num_cols = data[0].length;
  // const cols_comps = dataisEarning[0].length;

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(data);
  // const comp_sheet = XLSX.utils.aoa_to_sheet(dataisEarning);

  // Every column with 15 chars of width
  worksheet['!cols'] = new Array(num_cols);
  worksheet['!cols'].fill({ wch: 15 });
  // comp_sheet['!cols'] = new Array(cols_comps);
  // comp_sheet['!cols'].fill({ wch: 15 });
  const num_lines = data.length;
  // const comp_num_lines = dataisEarning.length;

  for (let j = 0; j < num_cols; j++) {
    for (let i = 1; i <= num_lines; i++) {
      const cell = worksheet[columns[j] + i];

      if (i === 1) {
        cell.s = {
          fill: {
            fgColor: {
              rgb: 'ff6d6d',
            },
            bgColor: {
              rgb: 'ff6d6d',
            },
          },
          border: {
            top: {
              style: 'thin',
              color: {
                rgb: '000000',
              },
            },
            bottom: {
              style: 'thin',
              color: {
                rgb: '000000',
              },
            },
          },
        };
      }

      if (j > 0) {
        cell.s = { ...cell.s, alignment: { horizontal: 'center' } };
      }
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Pesquisa');

  return XLSX.write(workbook, { type: 'buffer' });
}
