import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import formatCompleteDate from "../../../services/formatCompleteDate";
import EyeModalOrders from "../EyeModalOrders";
import { Button, Card, CardBody, Col, Modal, ModalBody, Row } from "reactstrap";
import { Form } from "@unform/web";
import Select from "components/Form/SelectInput";
import apiGd from "services/apiGd";

import { Eye } from "./styles";

export default function AsaasComponent({ restaurant, asaasRestaurants, notify }) {
  const tokenGd = localStorage.getItem("@QRExpress:tokenGd");

  const handleUpdate = async (data) => {
    try {
      const asaas_id = data.asaas_id;

      const response = await apiGd.put(`/admin/restaurants/asaas/${restaurant.id}`,
        {
          asaas_id
        },
        {
          headers: { Authorization: `Bearer ${tokenGd}` },
        }
      );

      notify("tr", "success", "Restaurante atualizado");
    } catch (err) {
      console.log(err);
      notify("tr", "danger", "Algo deu errado, culpa do Alan");
    }
  };

  return (
    <Card style={{ width: "90%", marginTop: "20px" }}>
      <Form onSubmit={handleUpdate}>
       <CardBody>
          <Row>
            <Col md="3">
              <strong>
                {restaurant.fantasy_name}
              </strong>
             <p>{restaurant.name} - {restaurant.id}</p>
            </Col>
            <Col>
              <Select
                name="asaas_id"
                options={asaasRestaurants}
              />
            </Col>
            <Col md="3">
              <Button color="success" type="submit">
                Atualizar
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Form>
    </Card>
  );
}
