import { Form } from "@unform/web";
import { useAuth } from "context/AuthContext";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaTrash } from "react-icons/fa";
import NotificationAlert from "react-notification-alert";
import { Button, CardBody, Spinner, Table, Modal, ModalBody, Col, Row, ModalFooter } from "reactstrap";
import apiGd from "services/apiGd";
import Select from "views/components/Form/SelectInput";
import ResearchTR from "views/components/ResearchTR";
import ImageNotifications from "views/components/ImageNotifications";
import { Category, Content, Fixed, Image, Link } from "./styles";
import UserTR from "views/components/UserTR";
import Input from '../../../components/Input';

export const Users = () => {
  const notificationAlert = useRef(null);
  const { tokenGd } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [modal, setModal] = useState(false);
  function toggleModal() {
    setModal(!modal);
  }

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const getUsers = async () => {
    try{
      const res = await apiGd.get("/restaurants/users", {
        headers: {
          Authorization: `Bearer: ${tokenGd}`,
        },
      });
      
      //removendo o usuario Admin/Padrao do nosso dash
      setUsers(res.data.filter(data => data.id !== 1));
    }catch(err){
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  };

  const createUser = async (data) => {
    try{
      await apiGd.post("/admin/users", 
        data,
        {
          headers: {
            Authorization: `Bearer: ${tokenGd}`,
          },
        }
      );
      
      getUsers();
      toggleModal();
      notify("tr", "success", "Usuário cadastrado com sucesso.");
    }catch(err){
      notify("tr", "danger", err?.response?.data?.message || "Erro ao carregar informações.");
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%"
        }}>
          <h3>Usuários</h3>
        
          <Button className="btn-round" color="success" outline onClick={() => toggleModal()}>
            <i className="fa fa-plus" />
            Criar Usuário
          </Button>
        </div>

        <CardBody>
         { loading ? 
            <div style={{color: 'red', padding: 15, textAlign: 'center'}}>
              <Spinner /> 
            </div> :
          <Table>

            <tbody>
              {
                users.map(user => (
                  <UserTR user={user}/>
                ))
              }
            </tbody>
          </Table>
          }
        </CardBody>

        <Modal isOpen={modal} toggle={toggleModal} size='md'>
          <Form onSubmit={createUser}>
            <ModalBody>
              <Row>
                <Col>
                  <label>Nome</label>
                  <Input name="name" type="text"/>
                </Col>
              </Row>
              <Row style={{marginTop: 10}}>
                <Col>
                  <label>E-mail</label>
                  <Input name="email" type="text" />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Row>
                <Col style={{marginRight: 50}}>
                  <Button color="success" type="submit">
                    Criar
                  </Button>
                </Col>
              </Row>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </>
  );
};