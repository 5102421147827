import React, { useState, useEffect, useRef, useCallback } from "react";
import NotificationAlert from "react-notification-alert";
import { Button, Row, Col } from "reactstrap";
import CardRestaurantQR from "components/Cards/express/CardRestaurantQR/index";
import api from "../../../services/api";
import { LinkStyled } from "./styles.js";

function RestaurantsExpress() {
  const notificationAlert = useRef(null);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  let [restaurants, setRestaurants] = useState([]);
  const token = localStorage.getItem("@QRExpress:token");

  const getRestaurants = useCallback(async () => {
    try {
      const response = await api.get("admin/food-courts", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRestaurants(response.data);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token]);

  useEffect(() => {
    getRestaurants();
  }, [getRestaurants]);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row md="12">
          <Col md="8">
            <h3>Estabelecimentos com QR Express</h3>
          </Col>
          <Col md="4">
            <div style={{ float: "right" }}>
              <LinkStyled to={"/qr/restaurants/new"}>
                <Button className="btn-round" color="success" outline>
                  <i className="fa fa-plus" />
                  Novo Estabelecimento
                </Button>
              </LinkStyled>
            </div>
          </Col>
        </Row>
        <Row>
          {restaurants.map(
            (restaurant) =>
              restaurant.is_single_restaurant &&
              restaurant.restaurants[0] && (
                <Col md="4">
                  <LinkStyled
                    to={`/qr/restaurants/edit/${restaurant.restaurants[0].id}`}
                  >
                    <CardRestaurantQR
                      restaurant={restaurant.restaurants[0]}
                      avatar={restaurant.avatar}
                      createdAt={restaurant.created_at}
                    ></CardRestaurantQR>
                  </LinkStyled>
                </Col>
              )
          )}
        </Row>
      </div>
    </>
  );
}

export default RestaurantsExpress;
