import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import api from "../services/api";

import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  startOfWeek,
  startOfMonth,
  addHours
} from 'date-fns';

import { Form } from "@unform/web";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Collapse,
  Modal,
  ModalBody,
  Table
} from "reactstrap";

import {
  ToLine,
  ToPie,
} from "variables/charts.jsx";

import { Pie } from "react-chartjs-2";
import { ButtonLink } from 'components/Cards/styles';

import CardDash from "components/Cards/CardDash";
import CardTable from "components/Cards/CardTable/CardTable";
import CardTopEstablishment from "components/Cards/CardTopEstablishment";

import NotificationAlert from "react-notification-alert";
import { Input } from "@material-ui/core";
import { DateSelectorDiv, DateDiv, SubmitDate } from "./pages/styles";
import { useRestaurants } from "context/RestaurantsContext";

function Dashboard(){
  const formRef = useRef(null);
  // const [ordersPending, setOrdersPending] = useState([]);
  // const [ordersCanceled, setOrdersCanceled] = useState([]);
  const { validateUser } = useRestaurants();

  const [ordersNotPending, setOrdersNotPending] = useState([]);
  const [clients, setClients] = useState([]);
  const [ordersTotal, setOrdersTotal] = useState(0);
  const token = localStorage.getItem("@QRExpress:token");
  const notificationAlert = useRef();
  const [restaurantsData, setRestaurantsData] = useState([]);

  const [payments, setPayments] = useState([]);
  const [paymentsToChart, setPaymentsToChart] = useState({});

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  let inicialFormat = format(new Date(defaultInicial), "dd/MM/yyyy, HH:mm");
  let finalFormat = format(new Date(defaultFinal), "dd/MM/yyyy, HH:mm");
  let phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  function setToday(){
    setInicialDate(format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek(){
    setInicialDate(format(startOfWeek(subHours(new Date(), 3), {weekStartsOn: 1}), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfWeek(subHours(new Date(), 3), {weekStartsOn: 1}), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth(){
    setInicialDate(format(startOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfMonth(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  let [initialPre, setInitialPre] = useState(inicialDate);
  let [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    setInicialDate(format(subHours(new Date(initialPre), 3), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(subHours(new Date(finalPre), 3), "yyyy-MM-dd'T'HH:mm:ss"));
    let inicialFormat = format(new Date(initialPre), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(new Date(finalPre), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    
    setHourPhrase(phrase);
  }

  const [inicialDateSearch, setInicialDateSearch] = useState(format(addHours(new Date(inicialDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));
  const [finalDateSearch, setFinalDateSearch] = useState(format(addHours(new Date(finalDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));

  useEffect(() => {
    setInicialDateSearch(format(addHours(new Date(inicialDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDateSearch(format(addHours(new Date(finalDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));  
  }, [inicialDate, finalDate]);

  
  const getPayments = useCallback(async () => {
    try {
      const response = await api.get(`admin/food-courts/reports/cashier`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          start_date: `${inicialDateSearch}`,
          end_date: `${finalDateSearch}`,
        },
      });
      let data = response.data.forEach((fc) => {
        if (fc.restaurants.length > 0){
          return fc.restaurants;
        }
      });
      setPayments(response.data);
      console.log('respose', data);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token, inicialDateSearch, finalDateSearch]);

  const [chartPayments, setChartPayments] = useState(ToLine([], []));
  const getChartPayments = useCallback(async () => {
    try {
      let labels = ["Picpay (R$)", "Cartão de Crédito (R$)", "Pagar na Retirada (R$)"];
      let label = "Formas de Pagamento";
      let picpay = paymentsToChart.total_picPay_payment > 0 ? paymentsToChart.total_picPay_payment.toFixed(2) : 0;
      let paytime = paymentsToChart.total_paytime_payment > 0 ? paymentsToChart.total_paytime_payment.toFixed(2) : 0;
      let withdrawal = paymentsToChart.total_withdrawal_payment > 0 ? paymentsToChart.total_withdrawal_payment.toFixed(2) : 0;
      let data = [picpay, paytime, withdrawal];
      let colors = ["#11c76f", "blue", "red"];
      setChartPayments(ToPie(labels, label, data, colors));
      console.log('to Pie', ToPie(labels, label, data, colors));
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [paymentsToChart.total_picPay_payment, paymentsToChart.total_paytime_payment, paymentsToChart.total_withdrawal_payment]);


  const getOrdersNotPending = useCallback(async () => {
    try {
      const response = await api.get(`admin/food-courts/restaurants/orders`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          start_date: `${inicialDateSearch}`,
          end_date: `${finalDateSearch}`,
        },
      });
      let orderNotPending = response.data.filter(
        (order) =>
          order.order_status !== "waiting_payment" &&
          order.order_status !== "pending" &&
          order.order_status !== "canceled" &&
          order.order_status !== "expired" &&
          order.order_status !== "chargeback"
      );
      setOrdersNotPending(orderNotPending);

      let ordersTotalPrice = 0;
      let paymentForms = [];
      let paymentFormsTotal = [];

      orderNotPending.forEach((order) => {
        !paymentForms.includes(order.payment_method) && paymentForms.push(order.payment_method);
        ordersTotalPrice = ordersTotalPrice + parseFloat(order.total_price);
      });

      for (let x=0; x<paymentForms.length; x++){
        let total = 0;
        orderNotPending.forEach((order) => {
          if(order.payment_method === paymentForms[x]){
            total = total + parseFloat(order.total_price_with_sms);
          }
        });

        let forma = "";
        if (paymentForms[x] === "picpay") {
          forma = "Picpay";
        } else if (paymentForms[x] === "paytime") {
          forma = "Cartão de Crédito";
        } else if (paymentForms[x] === "withdrawal") {
          forma = "Retirada";
        } else {
          forma = "Outras";
        }

        paymentFormsTotal.push([forma, total.toFixed(2), (total*100/ordersTotalPrice).toFixed(2)]);
      }

      let methods = ['Picpay', "Cartão de Crédito", "Retirada", "Outras"];

      let paymentsGroup = [];
      let total_all = 0;
      methods.forEach((method) => {
        let total = 0;
        let percent = 0;
        paymentFormsTotal.forEach((form) => {
          if (form[0] === method){
            total = total + parseFloat(form[1]);
            percent = percent + parseFloat(form[2]);
            total_all = total_all + parseFloat(form[1]);
          }
        });
        paymentsGroup.push([method, total.toFixed(2).toString(), percent.toFixed(2).toString()]);
      });
      paymentsGroup.push(['Total', total_all.toFixed(2).toString(), '100%']);
      
      let paymentsFinal = {total_picPay_payment : 0, total_paytime_payment: 0, total_withdrawal_payment: 0, total_payment_received: 0};

      paymentsGroup.forEach(payment => {
        if (payment[0] === 'Picpay') {
          paymentsFinal.total_picPay_payment = parseFloat(payment[1]);
        } else if (payment[0] === 'Cartão de Crédito') {
          paymentsFinal.total_paytime_payment = parseFloat(payment[1]);
        } else if (payment[0] === 'Retirada') {
          paymentsFinal.total_withdrawal_payment = parseFloat(payment[1]);
        } else if (payment[0] === 'Outros') {
          paymentsFinal.total_withdrawal_payment =
            paymentsFinal.total_withdrawal_payment + parseFloat(payment[1]);
        } else if (payment[0] === 'Total') {
          paymentsFinal.total_payment_received = parseFloat(payment[1]);
        }
      });

      setPaymentsToChart(paymentsFinal);
      setPayments(paymentsGroup);
      setOrdersTotal(`R$${ordersTotalPrice.toLocaleString('pt-BR')}`);

      // let orderCanceled = response.data.filter(
      //   (order) =>
      //     order.order_status === "canceled" ||
      //     order.order_status === "chargeback"
      // );
      // setOrdersCanceled(orderCanceled);

      let restaurants = [];
      let clients = [];
      let restaurantsDataLocal = [];
      let vetor = orderNotPending;

      for (let i=0; i<vetor.length; i++){
        !restaurants.includes(vetor[i].restaurant.fantasy_name) && restaurants.push(vetor[i].restaurant.fantasy_name);
        !clients.includes(vetor[i].buyer.phone) && clients.push(vetor[i].buyer.phone);
      }

      setClients(clients);

      for (let j=0; j<restaurants.length; j++){
        let count = 0;
        let totalPrice = 0;
        let url = '';
        for(let a=0; a<vetor.length; a++){
          if(restaurants[j]===vetor[a].restaurant.fantasy_name){
            count++;
            totalPrice = totalPrice + parseFloat(vetor[a].total_price);
            if (url === ''){
              url = vetor[a].restaurant.avatar != null && vetor[a].restaurant.avatar.url;
            }
          }
        }
        let percent = (totalPrice === 0 ? 0 : (totalPrice * 100/ordersTotalPrice).toFixed(2));
        restaurantsDataLocal.push({"amount": count, "fantasy_name": restaurants[j], "total_price": totalPrice.toFixed(2), "percent": percent, "avatar_url": url}); 
      }

      function ordenarPorVendas(a, b){
        return b.total_price - a.total_price;
      }

      console.log(restaurantsDataLocal.sort(ordenarPorVendas));
      restaurantsDataLocal = restaurantsDataLocal.sort(ordenarPorVendas);
      setRestaurantsData(restaurantsDataLocal);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token, inicialDateSearch, finalDateSearch]);

  useEffect(() => {
    validateUser();
    getPayments();

    getOrdersNotPending();
    getChartPayments();
  }, [getOrdersNotPending, getPayments, getChartPayments, validateUser]);

  console.log('payments', payments);
  console.log('restaurants data', restaurantsData);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [modalPayments, setModalPayments] = useState(false);
  const togglePayments = () => setModalPayments(!modalPayments);

    return (
      <>
      <NotificationAlert ref={notificationAlert} />
        <div className="content">
          <h2>Dashboard</h2>
          
          <Row>
            <Col md="4"><p>{hourPhrase}</p></Col>
            <Col md="8">
              <div style={{ float: "right" }}>
                <Button className="btn-round mr-auto" onClick={setToday} color="info">
                  Hoje
                </Button>
                <Button className="btn-round mr-auto" onClick={setWeek} color="info">
                  Essa semana
                </Button>
                <Button className="btn-round mr-auto" onClick={setMonth} color="info">
                  Esse mês
                </Button>
                <Button className="btn-round mr-auto" onClick={toggle} color="info">
                  Selecionar Período
                  <b className="caret" style={{marginLeft: "10px"}}/>
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div style={{ float: "right", marginRight: "15px" }}>
                <Collapse isOpen={isOpen}>
                  <Form onSubmit={handleChangeDate} ref={formRef}>
                    <DateSelectorDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Inicial"
                          type="datetime-local"
                          name="initialDate"
                          onChange={(e) => setInitialPre(e.target.value)}
                          defaultValue={defaultInicial}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Final"
                          type="datetime-local"
                          name="finalDate"
                          onChange={(e) => setFinalPre(e.target.value)}
                          defaultValue={defaultFinal}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <div>
                        <SubmitDate onClick="submit">Filtrar</SubmitDate>
                      </div>
                    </DateSelectorDiv>
                  </Form>
                </Collapse>
              </div>
            </Col>
          </Row>
         
          <Row>
          <Col lg="4" md="6" sm="6">
            <CardDash
              title="Arrecadação"
              total={ordersTotal}
              icon="nc-icon nc-money-coins text-success"
              toggle={togglePayments}
            ></CardDash>
          </Col>

          <Modal isOpen={modalPayments} toggle={togglePayments}>
            <ModalBody>
              <h3>Formas de Pagamento</h3>
              <Table>
                <thead>
                  <tr>
                    <th>Forma</th>
                    <th>Arrecadado</th>
                    <th className="text-right">Porcentagem</th>
                  </tr>
                </thead>
                <tbody>
                  {payments.map((payment)=> 
                    <tr>
                      <td>{payment[0]}</td>
                      <td>R${payment[1]}</td>
                      <td className="text-right">{payment[2]}%</td>
                    </tr>)
                  }
                </tbody>
              </Table>
            </ModalBody>
          </Modal>

          <Col lg="4" md="6" sm="6">
            <CardDash
              title="Pedidos Feitos"
              total={ordersNotPending.length}
              icon="nc-icon nc-tap-01 text-danger"
            ></CardDash>
          </Col>
          <Col lg="4" md="6" sm="6">
              <CardDash
                title="Clientes Únicos"
                total={clients.length}
                icon="nc-icon nc-single-02 text-info"
              ></CardDash>
          </Col>
        </Row>
          <Row>
          <Col md="4">
            </Col>
          </Row>

          <Row>
            <Col md="7">
              <CardTable
                title="Vendas Por Estabelecimento"
                subtitle="Confira quanto cada estabelecimento esta arrecadando e tenha
                    controle de seu Shopping!"
                restaurants={restaurantsData}
              ></CardTable>
            </Col>

            <Col md="5">
              <Row style={{margin: 0}}>
              <Card>
                <CardHeader>
                  <CardTitle>Formas de Pagamento</CardTitle>
                </CardHeader>
                <CardBody>
                  <div style={{textAlign: "center"}}>
                  {payments.total_payment_received === 0 ? <p style={{color: "red"}}>Não houve nenhum pedido no período selecionado.</p> : 
                  <Pie
                    data={chartPayments.data}
                    options={chartPayments.options}
                    width={456}
                    height={400}
                  />}
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="legend">
                    <i className="fa fa-circle" style={{color: "#11c76f"}} />
                    Picpay {paymentsToChart.total_payment_received > 0 ? (paymentsToChart.total_picPay_payment * 100/paymentsToChart.total_payment_received).toFixed(2) : 0}%
                  </div>
                  <div className="legend">
                    <i className="fa fa-circle"  style={{color: "blue"}} />
                    Cartão de Crédito {paymentsToChart.total_payment_received > 0 ? (paymentsToChart.total_paytime_payment * 100/paymentsToChart.total_payment_received).toFixed(2) : 0}%
                  </div>
                  <div className="legend">
                  <i className="fa fa-circle"  style={{color: "red"}} />
                    Pagar na Retirada {paymentsToChart.total_payment_received > 0 ? (paymentsToChart.total_withdrawal_payment * 100/paymentsToChart.total_payment_received).toFixed(2) : 0}%
                  </div>
                  <hr />
                  <div className="stats">
                  <ButtonLink onClick={togglePayments}>
                    Mais detalhes
                  </ButtonLink>
                  </div>
                </CardFooter>
              </Card>
              </Row>
              <Row>
              <CardTopEstablishment source={restaurantsData}></CardTopEstablishment>

              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
}

export default Dashboard;
