import React from "react";
import { Button, Card, CardBody, Col, ModalBody, Row } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import apiGd from "services/apiGd";
import { useCallback } from "react";
import { Form } from "@unform/web";
import Input from "views/components/Input";
import { toast } from "react-toastify";
import { ModalCreate } from "./styles";
import CheckboxInput from "views/components/Checkbox";
import formatCompleteDate from "services/formatCompleteDate";

function Sessions() {
  const notificationAlert = useRef(null);
  const tokenGd = localStorage.getItem("@QRExpress:tokenGd");
  const [sessions, setSessions] = useState([]);
  const [modalCreate, setModalCreate] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  const toggleModalCreate = () => [setModalCreate(!modalCreate)];

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const getSession = useCallback(async () => {
    const response = await apiGd.get(`/admin/session/${sessionId}`, {
      headers: {
        Authorization: `Bearer: ${tokenGd}`,
      },
    });

    setSessions(response.data);
  }, [tokenGd, sessionId]);

  const handleUpdate = async (id) => {
    try {
      const response = await apiGd.put(`/admin/session/nfce-ref/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${tokenGd}` },
        }
      );

      notify("tr", "success", "Referência atualizada");
    } catch (err) {
      console.log(err);
      notify("tr", "danger", "Algo deu errado, culpa do Alan");
    }
  };

  const handleCompleteSession = async (key) => {
    if(window.confirm('Tem certeza que deseja finalizar essa comanda?')){
      try {
        await apiGd.put(`/admin/session/completed`,
          {
            session_key: key
          },
          {
            headers: { Authorization: `Bearer ${tokenGd}` },
          }
        );
  
        notify("tr", "success", "Comanda finalizada!");
      } catch (err) {
        console.log(err);
        notify("tr", "danger", "Algo deu errado, culpa do Alan");
      }
    }
  };

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content" style={{ marginLeft: "50px" }}>
        <Row>
          <Col md="10">
            <h3>Sessões</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Digite o número da comanda ou o número do pedido se for delivery (#XXX-XXXX)</p>
          </Col>
        </Row>
        <Form>  
          <Row>
            <Col md="10">
              <Input
                name="session_id"
                placeholder="Número da Comanda"
                type="text"
                onChange={e => setSessionId(e.target.value)}
              />
            </Col>
            <Col md="2">
              <Button onClick={() => getSession()}>
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        {sessions
          .map((session) => {
            return (
              <Card style={{ width: "90%", marginTop: "20px" }}>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <strong>
                        {session.restaurant.fantasy_name}
                      </strong> 
                    </Col>
                    <Col md="2">
                      {session.start_time ? formatCompleteDate(session.start_time) : null}
                    </Col>
                    <Col md="2">
                      R${session.total_service_price}
                    </Col>
                    <Col md="2">
                      Mesa: {session.table.table_number}
                    </Col>
                    <Col md="3">
                      <Button color="link" onClick={() => handleUpdate(session.id, tokenGd)}>
                        Mudar Referência
                      </Button>

                      <Button color="link" onClick={() => handleCompleteSession(session.key, tokenGd)}>
                        Finalizar
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            );
          })}
      </div>
    </>
  );
}

export default Sessions;
