import React from "react";
import { Card, CardBody } from "reactstrap";
import { LinkStyled } from "./styles.js";

export default function CardProfileRestaurant({ restaurant }) {
  const image = restaurant.avatar ? restaurant.avatar.url : "";
  return (
    <Card className="card-user">
      <CardBody>
        <div style={{ width: 150, height: 150, margin: "auto" }}>
          <img alt="..." src={image} />
        </div>
        <div style={{ textAlign: "center" }}>
          <h5 className="title">{restaurant.fantasy_name}</h5>
        </div>
        <div style={{ textAlign: "center" }}>
          <LinkStyled to={`/qr/restaurants/edit/${restaurant.id}?single=false`}>Editar Restaurante</LinkStyled>
        </div>
      </CardBody>
    </Card>
  );
}
