import React from "react";
import {
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { CardStyled } from "./styles";

export default function CardRestaurantShop({ shopping }) {
  const data = new Date(shopping.created_at);
  return (
    <CardStyled>
      <CardBody>
        <Row>
          <Col md="4" style={{ margin: "auto" }}>
            {shopping.avatar !== null ? (
              <img alt="..." src={shopping.avatar.url} />
            ) : (
              <p>Sem foto</p>
            )}
          </Col>
          <Col md="8">
            <h6>{shopping.fantasy_name}</h6>
            <span>{shopping.email}</span>
            <p>{shopping.username}</p>
            <p>
              Cliente desde {data.getUTCMonth() + 1 < 10 && "0"}
              {data.getUTCMonth() + 1}/{data.getFullYear()}
            </p>
          </Col>
        </Row>
      </CardBody>
    </CardStyled>
  );
}
