import React from "react";

import {
  Button,
  Row,
  Col,
} from "reactstrap";

import CardDash from "components/Cards/CardDash";
import CardTable from "components/Cards/CardTable/CardTable";
import CardTopEstablishment from "components/Cards/CardTopEstablishment";
import CardChart from "components/Cards/CardChart";
import CardChartPlus from "components/Cards/CardChartPlus";

import {
  chartExample1,
  chartExample2,
  chartExample3,
} from "variables/charts.jsx";

class DashboardGD extends React.Component {
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="5">
              <h3>Dashboard Garçom Digital</h3>
            </Col>
            <Col md="7">
              <div style={{ float: "right" }}>
                <Button className="btn-round mr-auto" active color="info">
                  Hoje
                </Button>
                <Button className="btn-round mr-auto" color="info">
                  Essa semana
                </Button>
                <Button className="btn-round mr-auto" color="info">
                  Esse mês
                </Button>
                <Button className="btn-round mr-auto" color="info">
                  Selecionar Período
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Clientes"
                total={987}
                icon="nc-icon nc-single-02 text-info"
              ></CardDash>
            </Col>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Arrecadação"
                total="R$1,247.68"
                icon="nc-icon nc-money-coins text-success"
              ></CardDash>
            </Col>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Pedidos"
                total="125"
                icon="nc-icon nc-tap-01 text-danger"
              ></CardDash>
            </Col>
          </Row>
          <Row>
            <Col lg="4" sm="6">
              <CardChart
                total="124"
                percent="12"
                title="Clientes na última hora"
                color="info"
                chartExample={chartExample3}
              ></CardChart>
            </Col>
            <Col lg="4" sm="6">
              <CardChart
                total="R$258.37"
                percent="16"
                title="Arrecadações na última hora"
                color="success"
                chartExample={chartExample1}
              ></CardChart>
            </Col>
            <Col lg="4" sm="6">
              <CardChart
                total="84"
                percent="11.4"
                title="Pedidos na última hora"
                color="danger"
                chartExample={chartExample2}
              ></CardChart>
            </Col>
          </Row>
          <Row>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Média Avaliações Estabelecimentos"
                total="4.1"
                icon="fa fa-star text-warning"
              ></CardDash>
            </Col>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Estabelecimentos Clientes"
                total="45"
                icon="nc-icon nc-shop text-danger"
              ></CardDash>
            </Col>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Estabelecimentos Abertos"
                total="37"
                icon="nc-icon nc-shop text-success"
              ></CardDash>
            </Col>
          </Row>

          <Row>
            <Col md="7">
              <CardTable
                title="Vendas Por Estabelecimento"
                subtitle="Confira quanto cada estabelecimento esta arrecadando e tenha
                    controle de seu Shopping!"
              ></CardTable>
            </Col>

            <Col md="5">
              <CardTopEstablishment source="mcdonalds"></CardTopEstablishment>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <CardChartPlus
                title="Vendas de Julho"
                subtitle="Venda total do shopping por dia do mês"
                legend="Dias do Mês"
                footer="Atualizado diariamente"
              ></CardChartPlus>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default DashboardGD;
