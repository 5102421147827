import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import { AiOutlineQrcode } from "react-icons/ai";

import logoTakeat from "assets/img/logotakeat.png";

import { StyledCollapse } from "../NewSidebar/styles";
import {
  FaBackward,
  FaBell,
  FaClone,
  FaDollarSign,
  FaNotesMedical,
  FaPlayCircle,
} from "react-icons/fa";
import { MdLockOutline } from "react-icons/md";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { user: props.user };
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <>
            {prop.show !== false ? (
              <li
                className={
                  this.getCollapseInitialState(prop.views) ? "active" : ""
                }
                key={key}
              >
                <a
                  href="#pablo"
                  data-toggle="collapse"
                  aria-expanded={this.state[prop.state]}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState(st);
                  }}
                >
                  {prop.icon !== undefined ? (
                    <>
                      <i className={prop.icon} />
                      <p>
                        {prop.name}
                        <b className="caret" />
                      </p>
                    </>
                  ) : (
                    <>
                      <span className="sidebar-mini-icon">{prop.mini}</span>
                      <span className="sidebar-normal">
                        {prop.name}
                        <b className="caret" />
                      </span>
                    </>
                  )}
                </a>
                <Collapse isOpen={this.state[prop.state]}>
                  <ul className="nav">{this.createLinks(prop.views)}</ul>
                </Collapse>
              </li>
            ) : (
              <></>
            )}
          </>
        );
      }
      return (
        <>
          {prop.show !== false ? (
            <li className={this.activeRoute(prop.path)} key={key}>
              <NavLink to={prop.path} activeClassName="">
                {prop.icon !== undefined ? (
                  <>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </>
                ) : (
                  <>
                    <span className="sidebar-mini-icon">{prop.mini}</span>
                    <span className="sidebar-normal">{prop.name}</span>
                  </>
                )}
              </NavLink>
            </li>
          ) : (
            <></>
          )}
        </>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo">
          <a
            href="/dashboard"
            className="logo-normal"
            style={{ width: 130, margin: "auto", marginTop: 0 }}
          >
            <div>
              <img src={logoTakeat} alt="react-logo" />
            </div>
          </a>
        </div>

        <div className="sidebar-wrapper" ref="sidebar">
          <div className="user">
            <div className="info">
              <NavLink id="collapse8">
                <span>
                  {this.state.user.fantasy_name}
                  <b className="caret" />
                </span>
              </NavLink>
              <StyledCollapse toggler="collapse8">
                <ul className="nav">
                  <li>
                    <NavLink to="/profile" activeClassName="">
                      <span className="sidebar-mini-icon">MP</span>
                      <span className="sidebar-normal">Meu Perfil</span>
                    </NavLink>
                  </li>
                </ul>
              </StyledCollapse>
            </div>
          </div>
          <Nav>
            <ul className="nav">
              {/* <li>
                <NavLink to="/dashboard" activeClassName="">
                  <i className="nc-icon nc-bank" />
                  <p>Dashboard</p>
                </NavLink>
              </li> */}
              <li className="admin">
                <NavLink id="collapse3">
                  <i className="nc-icon nc-shop" />
                  <p>
                    Garçom Digital <b className="caret" />
                  </p>
                </NavLink>
                <StyledCollapse toggler="#collapse3">
                  {/* <NavLink to="/gd/dashboard" activeClassName="">
                    <span className="sidebar-mini-icon">DGD</span>
                    <span className="sidebar-normal">Dashboard GD</span>
                  </NavLink> */}

                  <NavLink to="/gd/restaurants" activeClassName="">
                    <span className="sidebar-mini-icon">E</span>
                    <span className="sidebar-normal">Estabelecimentos</span>
                  </NavLink>

                  {/* <NavLink to="/restaurants/new" activeClassName="">
                    <span className="sidebar-mini-icon">AE</span>
                    <span className="sidebar-normal">
                      Adicionar Estabelecimento
                    </span>
                  </NavLink> */}
                </StyledCollapse>
                <StyledCollapse toggler="#collapse3">
                  {/* <NavLink to="/gd/dashboard" activeClassName="">
                    <span className="sidebar-mini-icon">DGD</span>
                    <span className="sidebar-normal">Dashboard GD</span>
                  </NavLink> */}

                  <NavLink to="/gd/menuclone" activeClassName="">
                    <span className="sidebar-mini-icon">
                      <FaClone />
                    </span>
                    <span className="sidebar-normal">Clonar cardápio</span>
                  </NavLink>

                  <NavLink to="/gd/recover" activeClassName="">
                    <span className="sidebar-mini-icon">
                      <FaBackward />
                    </span>
                    <span className="sidebar-normal">
                      Recuperar Estabelecimentos
                    </span>
                  </NavLink>

                  {/* <NavLink to="/restaurants/new" activeClassName="">
                    <span className="sidebar-mini-icon">AE</span>
                    <span className="sidebar-normal">
                      Adicionar Estabelecimento
                    </span>
                  </NavLink> */}
                </StyledCollapse>
                <StyledCollapse toggler="#collapse3">
                  <NavLink to="/gd/admin-settings" activeClassName="">
                    <span className="sidebar-mini-icon">
                      <MdLockOutline />
                    </span>
                    <span className="sidebar-normal">Configurar Admin</span>
                  </NavLink>
                </StyledCollapse>
                <StyledCollapse toggler="#collapse3">
                  <NavLink to="/gd/videos-settings" activeClassName="">
                    <span className="sidebar-mini-icon">
                      <FaPlayCircle />
                    </span>
                    <span className="sidebar-normal">Configurar Videos</span>
                  </NavLink>
                </StyledCollapse>
                <StyledCollapse toggler="#collapse3">
                  <NavLink to="/gd/notifications" activeClassName="">
                    <span className="sidebar-mini-icon">
                      <FaBell />
                    </span>
                    <span className="sidebar-normal">
                      Configurar Notificações
                    </span>
                  </NavLink>
                </StyledCollapse>
                <StyledCollapse toggler="#collapse3">
                  <NavLink to="/gd/sessions" activeClassName="">
                    <span className="sidebar-mini-icon">C</span>
                    <span className="sidebar-normal">Comandas</span>
                  </NavLink>
                </StyledCollapse>
                <StyledCollapse toggler="#collapse3">
                  <NavLink to="/gd/asaas" activeClassName="">
                    <span className="sidebar-mini-icon">
                      <FaDollarSign />
                    </span>
                    <span className="sidebar-normal">Asaas</span>
                  </NavLink>
                </StyledCollapse>
              </li>
              {/* <li className="admin">
                <NavLink id="collapse4">
                  <i>
                    <AiOutlineQrcode size={25}></AiOutlineQrcode>
                  </i>
                  <p>
                    QR Express <b className="caret" />
                  </p>
                </NavLink>
                <StyledCollapse toggler="#collapse4">
                  <NavLink to="/qr/dashboard" activeClassName="">
                    <span className="sidebar-mini-icon">DE</span>
                    <span className="sidebar-normal">Dashboard Express</span>
                  </NavLink>

                  <NavLink to="/qr/shoppings" activeClassName="">
                    <span className="sidebar-mini-icon">S</span>
                    <span className="sidebar-normal">Shoppings</span>
                  </NavLink>

                  <NavLink to="/qr/restaurants" activeClassName="">
                    <span className="sidebar-mini-icon">E</span>
                    <span className="sidebar-normal">Estabelecimentos</span>
                  </NavLink>
                </StyledCollapse>
              </li> */}
              <li>
                <NavLink to="/gd/general-reports" activeClassName="">
                  <i className="nc-icon nc-chart-bar-32" />
                  <p>Relatório Geral</p>
                </NavLink>
              </li>
              <li>
                <NavLink to="/gd/researches" activeClassName="">
                  <i className="nc-icon nc-zoom-split" />
                  <p>Pesquisas</p>
                </NavLink>
              </li>
              <li>
                <NavLink to="/gd/success" activeClassName="">
                  <i className="nc-icon nc-zoom-split" />
                  <p>Sucesso</p>
                </NavLink>
              </li>

              <li>
                <NavLink to="/gd/users" activeClassName="">
                  <i className="nc-icon nc-circle-10" />
                  <p>Usuários</p>
                </NavLink>
              </li>
              {/* <li className="admin">
                <NavLink id="collapse5">
                  <i className="nc-icon nc-globe-2" />
                  <p>
                    QR Concierge <b className="caret" />
                  </p>
                </NavLink>
                <StyledCollapse toggler="#collapse5">
                  <NavLink to="/concierge/dashboard" activeClassName="">
                    <span className="sidebar-mini-icon">DE</span>
                    <span className="sidebar-normal">Dashboard Concierge</span>
                  </NavLink>
                  <NavLink to="/concierge/franchise" activeClassName="">
                    <span className="sidebar-mini-icon">F</span>
                    <span className="sidebar-normal">Franquias</span>
                  </NavLink>
                  <NavLink to="/concierge/hotels" activeClassName="">
                    <span className="sidebar-mini-icon">HMP</span>
                    <span className="sidebar-normal">
                      Hotéis, Motéis e Pousadas
                    </span>
                  </NavLink>
                </StyledCollapse>
              </li> */}
              <li className="admin">
                <NavLink to="/settings" activeClassName="">
                  <i className="nc-icon nc-settings-gear-65" />
                  <p>Configurações</p>
                </NavLink>
              </li>
            </ul>
          </Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
