import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo
} from "react";

import api from "../services/api";

import {
  format,
  startOfDay,
  endOfDay,
  subHours,
  startOfWeek,
  startOfMonth,
  addHours
} from 'date-fns';

import { Form } from "@unform/web";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Collapse,
  Table
} from "reactstrap";

import CardDash from "components/Cards/CardDash";

import NotificationAlert from "react-notification-alert";
import { Input } from "@material-ui/core";
import { DateSelectorDiv, DateDiv, SubmitDate } from "./pages/styles";

function Financial(){
  const formRef = useRef(null);
  const [time, setTime] = useState(null);
  const token = localStorage.getItem("@QRExpress:token");
  const notificationAlert = useRef();

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  let inicialFormat = format(new Date(defaultInicial), "dd/MM/yyyy, HH:mm");
  let finalFormat = format(new Date(defaultFinal), "dd/MM/yyyy, HH:mm");
  let phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  function setToday(){
    setInicialDate(format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek(){
    setInicialDate(format(startOfWeek(subHours(new Date(), 3), {weekStartsOn: 1}), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfWeek(subHours(new Date(), 3), {weekStartsOn: 1}), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth(){
    setInicialDate(format(startOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfMonth(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  let [initialPre, setInitialPre] = useState(inicialDate);
  let [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    setInicialDate(format(subHours(new Date(initialPre), 3), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(subHours(new Date(finalPre), 3), "yyyy-MM-dd'T'HH:mm:ss"));
    let inicialFormat = format(new Date(initialPre), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(new Date(finalPre), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    
    setHourPhrase(phrase);
  }

  const [inicialDateSearch, setInicialDateSearch] = useState(format(addHours(new Date(inicialDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));
  const [finalDateSearch, setFinalDateSearch] = useState(format(addHours(new Date(finalDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));

  useEffect(() => {
    setInicialDateSearch(format(addHours(new Date(inicialDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDateSearch(format(addHours(new Date(finalDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));  
  }, [inicialDate, finalDate]);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const [shoppings, setShoppings] = useState([]);
  const [totalTaxs, setTotalTaxs] = useState(`R$0.00`);

  const getRestaurants = useCallback(async () => {
    try {
      const response = await api.get(`admin/food-courts/reports/cashier`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          start_date: `${inicialDateSearch}`,
          end_date: `${finalDateSearch}`,
        },
      });
      let shoppingsData = response.data.filter((a) => a.is_single_restaurant === false && a.username !== "shoppingtakeat");

      let shoppings = [];
      let all_total_taxs = 0;

      function ordenarPorVendasRestaurante(a, b){
        return b.total_tax - a.total_tax;
      }
      
      console.log(shoppingsData);
      shoppingsData.forEach((shopping) => {
        let shopping_taxs = 0;
        let shopping_total = 0;

        let shoppingRestaurant = [];
        shopping.restaurants.forEach((restaurant) => {

          let total_tax = (restaurant.report.total_paytime_tax + restaurant.report.total_picpay_tax + restaurant.report.total_withdrawal_tax);
          all_total_taxs += total_tax;
          shopping_taxs += total_tax;
          shopping_total += restaurant.report.total_payment_received;

          shoppingRestaurant.push({
          "fantasy_name": restaurant.fantasy_name,
          "tax": restaurant.tax_multiplier,
          "total_tax": total_tax,
          "total": restaurant.report.total_payment_received,
          });
        })

        shoppingRestaurant = shoppingRestaurant.sort(ordenarPorVendasRestaurante);

        shoppings.push({
          "fantasy_name": shopping.fantasy_name,
          "avatar": shopping.avatar,
          "restaurants": shoppingRestaurant,
          "total_taxs": shopping_taxs,
          "shopping_total": shopping_total,
        })
      });

      function ordenarPorVendas(a, b){
        return b.total_taxs - a.total_taxs;
      }

      shoppings = shoppings.sort(ordenarPorVendas);
      setShoppings(shoppings);
      setTotalTaxs(`R$${all_total_taxs.toFixed(2)}`);

      const data = new Date();
      const time = `${data.getUTCHours() - 3}:${
        data.getUTCMinutes() < 10
          ? `0${data.getUTCMinutes()}`
          : data.getUTCMinutes()
      }`;
      setTime(time);
      
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token, inicialDateSearch, finalDateSearch]);

  useEffect(() => {
    getRestaurants();
  }, [getRestaurants]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
    <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <h2>Financeiro</h2>
        <Row>
          <Col md="4"><p>{hourPhrase}</p> <p>Última Atualização {time}</p></Col>
          <Col md="8">
            <div style={{ float: "right" }}>
              <Button className="btn-round mr-auto" onClick={setToday} color="info">
                Hoje
              </Button>
              <Button className="btn-round mr-auto" onClick={setWeek} color="info">
                Essa semana
              </Button>
              <Button className="btn-round mr-auto" onClick={setMonth} color="info">
                Esse mês
              </Button>
              <Button className="btn-round mr-auto" onClick={toggle} color="info">
                Selecionar Período
                <b className="caret" style={{marginLeft: "10px"}}/>
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <div style={{ float: "right", marginRight: "15px" }}>
              <Collapse isOpen={isOpen}>
                <Form onSubmit={handleChangeDate} ref={formRef}>
                  <DateSelectorDiv>
                    <DateDiv>
                      <Input
                        id="datetime-local"
                        label="Data Inicial"
                        type="datetime-local"
                        name="initialDate"
                        onChange={(e) => setInitialPre(e.target.value)}
                        defaultValue={defaultInicial}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DateDiv>
                    <DateDiv>
                      <Input
                        id="datetime-local"
                        label="Data Final"
                        type="datetime-local"
                        name="finalDate"
                        onChange={(e) => setFinalPre(e.target.value)}
                        defaultValue={defaultFinal}
                        className="data"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DateDiv>
                    <div>
                      <SubmitDate onClick="submit">Filtrar</SubmitDate>
                    </div>
                  </DateSelectorDiv>
                </Form>
              </Collapse>
            </div>
          </Col>
        </Row>
        
      <Row>
        <Col md="4">
        <Col md="12">
          <CardDash
            title="Lucro"
            total={totalTaxs}
            icon="nc-icon nc-money-coins text-success"
          ></CardDash>
        </Col>
        <Col md="12">
          <Card>
            <CardBody>
              {shoppings.length > 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <th>Shopping</th>
                      <th className="text-right">Total a Pagar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shoppings.map((shopping) =>
                      <tr>
                        <td>{shopping.avatar ? <img width="180" src={shopping.avatar.url} alt={shopping.fantasy_name}/> : <>{shopping.fantasy_name}</>}</td>
                        <td className="text-right">R${shopping.total_taxs.toFixed(2)}</td>
                      </tr>)}
                  </tbody>
                </Table>
              ) : (
                <div style={{ textAlign: "center", color: "red" }}>
                  <span>Nenhum pedido feito no período selecionado.</span>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Col>
      
      <Col md="8">
        {shoppings.map((shopping) => 
        <Col md="12">
          <Card>
            <CardHeader style={{ display: "flex", flexDirection: "row" }}>
              <Col md="9">
                <CardTitle tag="h4">{shopping.fantasy_name}</CardTitle>
                <h6>R${shopping.total_taxs.toFixed(2)}</h6>
              </Col>
              <Col md="3">
                {shopping.avatar && <img width="200" src={shopping.avatar.url} alt={shopping.fantasy_name}/>}
              </Col>
            </CardHeader>
            <CardBody>
              {shoppings.length > 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <th>Restaurante</th>
                      <th>Taxa</th>
                      <th>Arrecadado</th>
                      {/* <th>SMS</th> */}
                      <th className="text-right">Total a Pagar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shopping.restaurants.map((restaurant) =>
                      <tr>
                        <td>{restaurant.fantasy_name}</td>
                        <td>{(restaurant.tax * 100).toFixed(2)}%</td>
                        <td>R${restaurant.total.toFixed(2)}</td>
                        {/* <td>R$0.00</td> */}
                        <td className="text-right">R${restaurant.total_tax.toFixed(2)}</td>
                      </tr>)}
                  </tbody>
                </Table>
              ) : (
                <div style={{ textAlign: "center", color: "red" }}>
                  <span>Nenhum pedido feito no período selecionado.</span>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
        )}
      </Col>
    </Row>
    </div>
    </>
  );
}

export default Financial;
