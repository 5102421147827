import styled from "styled-components";

export const Content = styled.div`
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction:column;
    gap: 16px;
`;

export const Body = styled.div`
    display: grid;
    gap: 16px;
    grid-template:
        "cep street number"
        "neighborhood city state"
        "checkbox . ."
        ;
    grid-template-columns: repeat(3, 1fr);
`;

export const InputAddressArea = styled.div`
    display: flex;
    flex-direction: column;

    span{
        font-size: 16px;
        color: #3d3d3d;
        font-weight: bold;
    }
`