import styled from "styled-components";
import { Col, Row } from "reactstrap";

export const Body = styled(Row)`
  display: flex;
  align-items: stretch; 
  margin-bottom: 20px;

`;

export const ColStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Card = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
`;

export const SwitchersArea = styled.div`
  display: flex;
  flex-direction: column;
`

export const SwitchArea = styled.div`
  display: flex;
  gap: 12px;
  padding: 8px;

  p {
    display: flex;
    margin: 0px;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
  }
`;

export const AsaasArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  
  p {
    font-weight: 600;
    font-size: 20px;
  }
`;

export const ReportArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 0;

  .title {
    font-weight: 600;
    font-size: 20px;
  }
  
  .info {
    font-size: 18px;
  }
`;
