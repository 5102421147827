import React, { useState, useCallback } from "react";
import { AiOutlineEye } from "react-icons/ai";
import formatCompleteDate from "../../../services/formatCompleteDate";
import EyeModalOrders from "../EyeModalOrders";
import { Button, Card, CardBody, Col, Modal, ModalBody, Row, Table } from "reactstrap";
import { Form } from "@unform/web";
import Select from "components/Form/SelectInput";
import apiGd from "services/apiGd";

import { Eye, StatusTagInvoice } from "./styles";
import formatDateYear from "services/formatDateYear";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import { MdOpenInNew } from "react-icons/md";

export default function GeneralReportTR({ restaurant, typeFilter }) {
  const tokenGd = localStorage.getItem("@QRExpress:tokenGd");

  const [invoices, setInvoices] = useState([]);

  const getInvoices = useCallback(async () => {
    const response = await apiGd.get(`/admin/restaurants/invoices/${restaurant.id}`, {
      headers: {
        Authorization: `Bearer: ${tokenGd}`,
      },
    });

    setInvoices(response.data);
  }, [tokenGd, restaurant]);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
    if(!modal === true){
      getInvoices();
    }
  }

  function getStatus(status){
    switch(status){
      case 'PENDING':
        return 'Pendente';
      case 'RECEIVED':
        return 'Pago';
      case 'CONFIRMED':
        return 'Pago';
      case 'OVERDUE':
        return 'Vencida';
      case 'REFUNDED':
        return 'Estornada';
      case 'DELETED':
        return 'Deletada';
    }
  }

  function getStatusColor(status){
    switch(status){
      case 'PENDING':
        return '#CC9900';
      case 'RECEIVED':
        return 'green';
      case 'CONFIRMED':
        return 'green';
      case 'OVERDUE':
        return '#dc3545';
      case 'REFUNDED':
        return 'blue';
      case 'DELETED':
        return 'black';
    }
  }

  function getButtonStatus(status){
    switch(status){
      case 'PENDING':
        return 'Acessar';
      case 'RECEIVED':
        return 'Recibo';
      case 'CONFIRMED':
        return 'Recibo';
      case 'OVERDUE':
        return 'Acessar';
      case 'REFUNDED':
        return 'Recibo';
      case 'DELETED':
        return 'Recibo';
    }
  }
  
  return (
    <>
      <tr>
        <td className={restaurant.name.startsWith('deleted') ? "text-danger" : "text-info"}>
          <strong>{restaurant.fantasy_name}</strong>
          <p>{restaurant.name}</p>
          <MdOpenInNew style={{cursor: 'pointer', marginTop: -20, color: '#424D4B', marginRight: 5 }} size={17} 
            onClick={() => window.open(
            `https://nossodashboard.takeat.app/gd/restaurants/edit/${restaurant.id}`,
            '_blank'
            )}
          />
          <FaFileInvoiceDollar style={{cursor: 'pointer', marginTop: -20, color: '#B1AFA9'}} size={15} onClick={() => toggleModal()}/>
          {restaurant?.instagram && restaurant?.instagram !== '' && restaurant?.instagram !== ' ' && <AiOutlineInstagram style={{cursor: 'pointer', marginTop: -20, marginLeft: 5, color: '#f5425d'}} size={20} onClick={() => window.open(`https://www.instagram.com/${restaurant.instagram}`)}/>}
        </td>
        <td className="text-center">
          {restaurant?.responsible_user?.name || '-'}
        </td>
        <td className="text-center">{parseFloat(restaurant.tables_count).toLocaleString('pt-br', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </td>
        <td className="text-center">{parseFloat(restaurant.sessions_count).toLocaleString('pt-br', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </td>
        <td className="text-center">{restaurant.mrr ? `R$${parseFloat(restaurant.mrr).toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}` : '-'}
        </td>
        <td className="text-center">{restaurant.created_at ? formatCompleteDate(restaurant.created_at) : '-'}</td>
        <td className="text-center">
          {restaurant.hundred_sessions_date ? formatCompleteDate(restaurant.hundred_sessions_date) : '-'}
          {restaurant.hundred_sessions_date && <p>({restaurant.difference_activate_days} dias)</p>}
        </td>
        {/* <td className="text-center">{restaurant.difference_activate_days}</td> */}
        <td className="text-center">
          {restaurant.last_session_date ? formatCompleteDate(restaurant.last_session_date) : '-'}
          {restaurant.last_session_date && <p>({restaurant.days_since_last_session} dias)</p>}
        </td>
        {(typeFilter === 6 || typeFilter === 7) && 
          <td className="text-center">
            {restaurant.last_asaas_event ? formatCompleteDate(restaurant.last_asaas_event) : '-'}
            {restaurant.last_asaas_event && <p>({restaurant.difference_last_asaas_event} dias)</p>}
          </td>
        }
        <td className="text-center">
          {restaurant.deleted_at ? formatCompleteDate(restaurant.deleted_at) : '-'}
          {restaurant.deleted_at && <p>({restaurant.days_since_deleted} dias)</p>}
        </td>
        <td className="text-center">
          {restaurant.suspension_date ? formatCompleteDate(restaurant.suspension_date) : '-'}
        </td>
        <td className="text-right">
          {restaurant.temporary_active ? formatCompleteDate(restaurant.temporary_active) : '-'}
        </td>
      </tr>

      <Modal isOpen={modal} toggle={toggleModal} size='lg'>
        <ModalBody>
          <Table>
            <thead>
              <tr>
                <th>Vencimento</th>
                <th>Status</th>
                <th>Tipo</th>
                <th>Criado</th>
                <th>Valor</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                invoices.map(invoice => 
                  <tr>
                    <td>{formatDateYear(invoice.due_date)}</td>
                    <td>
                    <StatusTagInvoice color={getStatusColor(invoice.status)}>
                      <span>
                        {getStatus(invoice.status)}
                      </span>
                    </StatusTagInvoice>
                    </td>
                    <td>{invoice.billing_type}</td>
                    <td>{formatDateYear(invoice.createdAt)}</td>
                    <td>R${invoice.value}</td>
                    <td>
                      <Button style={{background: getButtonStatus(invoice.status) === 'Acessar' ? 'green' : '#0dcaf0'}} onClick={() => {window.open(invoice.invoice_url)}}>
                        {getButtonStatus(invoice.status)}
                      </Button>
                    </td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    </>
  );
}
