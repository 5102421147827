import React, { useState, useEffect, useRef, useCallback } from "react";
import NotificationAlert from "react-notification-alert";
import { Line } from "react-chartjs-2";

import {
  Button,
  Row,
  Col,
  Spinner,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";

import CardDash from "components/Cards/CardDash";
import CardSettings from "components/Cards/CardSettings";

import {
  format,
  subHours,
  isBefore,
  startOfMonth,
  addHours,
  isAfter,
  addMonths,
  lastDayOfMonth,
  startOfYear,
  getMonth,
  subDays,
} from "date-fns";

import { ToLine } from "variables/charts.jsx";

import apiGd from "../../../../services/apiGd";
import { LinkStyled } from "../../express/styles.js";
import { SevenDaysList } from "components/Cards/gd/CardsList";
import { ThreeDaysList } from "components/Cards/gd/CardsList";
import { LessThan100List } from "components/Cards/gd/CardsList";
import { SuspensionList } from "components/Cards/gd/CardsList";
import { AllList } from "components/Cards/gd/CardsList";
import Input from "views/components/Input";
import { Form } from "@unform/web";
import { useRestaurants } from "context/RestaurantsContext";

function Restaurants() {
  const notificationAlert = useRef(null);
  const { restaurants, getRestaurants, validateUser } = useRestaurants();
  const filteredRestaurants = restaurants.filter((r) => r.deleted_at === null);
  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const [loading, setLoading] = useState(false);
  const [listToShow, setListToShow] = useState("all");
  const [chartYearly, setChartYearly] = useState(ToLine([], []));
  const [last30Days, setLast30Days] = useState(0);
  const [search, setSearch] = useState(null);

  const restaurantsDataCalculations = () => {
    let inicialDate = format(
      addHours(startOfYear(subHours(new Date(), 3)), 3),
      "yyyy-MM-dd'T'HH:mm:ss"
    );

    let filterDateInicial30Days = format(
      subDays(subHours(new Date(), 3), 30),
      "yyyy-MM-dd'T'HH:mm:ss"
    );

    let data = [];
    let lossData = [];
    let last30Days = 0;
    let label = [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ];
    for (let i = 0; i < getMonth(new Date()) + 1; i++) {
      let totalMonthly = 0;
      let lossMonthly = 0;
      let filterDateInicial = format(
        startOfMonth(addMonths(new Date(inicialDate), i)),
        "yyyy-MM-dd'T'HH:mm:ss"
      );
      let filterDateFinal = format(
        lastDayOfMonth(addMonths(new Date(inicialDate), i)),
        "yyyy-MM-dd'T'HH:mm:ss"
      );

      restaurants.forEach((restaurant) => {
        if (
          isBefore(
            new Date(restaurant.created_at),
            new Date(filterDateFinal)
          ) &&
          isAfter(new Date(restaurant.created_at), new Date(filterDateInicial))
        ) {
          totalMonthly++;
        }
        if (
          isBefore(
            new Date(restaurant.deleted_at),
            new Date(filterDateFinal)
          ) &&
          isAfter(new Date(restaurant.deleted_at), new Date(filterDateInicial))
        ) {
          lossMonthly++;
        }
      });
      lossData.push(lossMonthly);
      data.push(totalMonthly);
    }

    restaurants.forEach((restaurant) => {
      if (
        isAfter(
          new Date(restaurant.created_at),
          new Date(filterDateInicial30Days)
        )
      ) {
        last30Days++;
      }
    });
    setChartYearly({
      labels: label,
      datasets: [
        {
          label: "Novos",
          data: data,
          borderColor: "#6bd098",
          backgroundColor: "transparent",
        },
        {
          label: "Cancelados",
          data: lossData,
          borderColor: "#e61c54",
          backgroundColor: "transparent",
        },
        {
          label: "Saldo",
          data: data.map((d, i) => d - lossData[i]),
          borderColor: "#51bcda",
          backgroundColor: "transparent",
        },
      ],
    });
    setLast30Days(last30Days);
  };

  useEffect(() => {
    if (restaurants?.length < 1) {
      getRestaurants();
    }
  }, []);

  useEffect(() => {
    if (restaurants) {
      restaurantsDataCalculations();
    }
  }, [restaurants]);

  useEffect(() => {
    validateUser();
  }, []);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row md="12">
          <Col md="8">
            <h3>Estabelecimentos com Garçom Digital</h3>
          </Col>
          <Col md="4">
            <div style={{ float: "right" }}>
              <LinkStyled to={"/gd/restaurants/new"}>
                <Button className="btn-round" color="success" outline>
                  <i className="fa fa-plus" />
                  Novo Estabelecimento
                </Button>
              </LinkStyled>
            </div>
          </Col>
        </Row>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <Spinner
              style={{ width: "3rem", height: "3rem" }}
              type="grow"
              color="danger"
            />
          </div>
        ) : (
          <div>
            <Row>
              <Col lg="4">
                <Row>
                  <Col lg="12" md="6" sm="6">
                    <CardDash
                      title="Clientes"
                      total={filteredRestaurants?.length}
                      icon="nc-icon nc-shop text-danger"
                    ></CardDash>
                  </Col>
                  <Col lg="12" md="6" sm="6">
                    <CardDash
                      title="Clientes Último Mês"
                      total={last30Days}
                      icon="nc-icon nc-refresh-69 text-info"
                    ></CardDash>
                  </Col>
                  {/* <Col lg="12" md="6" sm="6">
                    <CardSettings />
                  </Col> */}
                </Row>
              </Col>

              <Col md="8">
                <Card className="card-chart">
                  <CardHeader>
                    <h6>Clientes Novos por Mês</h6>
                  </CardHeader>
                  <CardBody>
                    <p className="card-category">Clientes Novos x Mês do Ano</p>
                    <Col md="10" className="ml-auto mr-auto">
                      <Line data={chartYearly} />
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Form>
                  <Input
                    name="search"
                    placeholder="Pesquisar"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Form>
              </Col>
              
              {/* <Col md="8">
                <Button
                  className="btn-round mr-auto"
                  onClick={() => setListToShow("all")}
                  color="info"
                >
                  Todos
                </Button>
                <Button
                  className="btn-round mr-auto"
                  onClick={() => setListToShow("new")}
                  color="info"
                >
                  Menos de 100 comandas
                </Button>
                <Button
                  className="btn-round mr-auto"
                  onClick={() => setListToShow("threeDays")}
                  color="info"
                >
                  Inativo 3 dias
                </Button>
                <Button
                  className="btn-round mr-auto"
                  onClick={() => setListToShow("sevenDays")}
                  color="info"
                >
                  Inativo 7 dias
                </Button>
                <Button
                  className="btn-round mr-auto"
                  onClick={() => setListToShow("suspensionAlert")}
                  color="info"
                >
                  alerta de Suspensão
                </Button>
              </Col> */}
            </Row>
            {listToShow === "all" ? (
              <AllList
                array={filteredRestaurants}
                search={search?.toLowerCase()}
              />
            ) : listToShow === "new" ? (
              <LessThan100List
                array={filteredRestaurants}
                search={search?.toLowerCase()}
              />
            ) : listToShow === "threeDays" ? (
              <ThreeDaysList
                array={filteredRestaurants}
                search={search?.toLowerCase()}
              />
            ) : listToShow === "sevenDays" ? (
              <SevenDaysList
                array={filteredRestaurants}
                search={search?.toLowerCase()}
              />
            ) : (
              <SuspensionList
                array={filteredRestaurants}
                search={search?.toLowerCase()}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Restaurants;
