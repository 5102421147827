import React from "react";
import { CardBody, Row, Col } from "reactstrap";
import { CardStyled } from "./styles";

export default function CardRestaurantQR({ restaurant, avatar, createdAt }) {
  const data = new Date(createdAt);
  const image = avatar ? avatar.url : "";
  return (
    <CardStyled>
      <CardBody>
        <Row>
          <Col md="3" style={{ margin: "auto" }}>
            {image !== "" ? <img alt="..." src={image} /> : <p>Sem foto</p>}
          </Col>
          <Col md="9">
            <h6>{restaurant.fantasy_name}</h6>
            <span>{restaurant.email}</span>
            <p>{restaurant.username}</p>
            <p>
              Cliente desde {data.getUTCMonth() + 1 < 10 && "0"}
              {data.getUTCMonth() + 1}/{data.getFullYear()}
            </p>
          </Col>
        </Row>
      </CardBody>
    </CardStyled>
  );
}
