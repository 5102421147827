import styled from "styled-components";
import { Card } from "reactstrap";

export const CardStyled = styled(Card)`
  height: auto;
`;

export const ButtonSeeCash = styled.button`
  z-index: 10;
`;

export const Link = styled.a`
  float: right;
  margin-right: 5px;

  color: #51c0ce !important;
  font-size: 12pt;

  &:link {
    text-decoration: none !important;
  }

  &:visited {
    text-decoration: none !important;
  }

  &:hover {
    text-decoration: underline !important;
    cursor: pointer;
  }

  &:active {
    text-decoration: underline !important;
  }
`;
