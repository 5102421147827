import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import Login from "../views/pages/Login";
import Dashboard from "../views/Dashboard.jsx";
import Financial from "../views/Financial.jsx";
// import Restaurants from "views/pages/Restaurants/Restaurants.jsx";
// import NewRestaurant from "views/pages/Restaurants/NewRestaurant.jsx";
import UserProfile from "views/pages/UserProfile.jsx";
// import ViewRestaurant from "views/pages/Restaurants/ViewRestaurant.jsx";
import Restaurants from "views/pages/gd/Restaurants/Restaurants.jsx";
import RestaurantsExpress from "views/pages/express/RestaurantsExpress.jsx";
import ViewRestaurant from "views/pages/gd/Restaurants/ViewRestaurant.jsx";
import NewRestaurant from "views/pages/gd/Restaurants/NewRestaurant.jsx";
import EditRestaurantGD from "views/pages/gd/Restaurants/EditRestaurantGD.jsx";
import Shoppings from "views/pages/express/Shoppings.jsx";
import NewShopping from "views/pages/express/NewShopping.jsx";
import DashboardGD from "views/pages/gd/DashboardGD.jsx";
import DashboardExpress from "views/pages/express/DashboardExpress.jsx";
import DashboardConcierge from "views/pages/concierge/DashboardConcierge.jsx";
import NewRestaurantExpress from "views/pages/express/NewRestaurantExpress.jsx";
import Franchise from "views/pages/concierge/Franchise";
import Concierge from "views/pages/concierge/Concierge";
import NewConcierge from "views/pages/concierge/NewConcierge";
import Settings from "views/pages/Settings.jsx";
import EditRestaurantExpress from "views/pages/express/EditRestaurantExpress.jsx";
import ShoppingRestaurants from "views/pages/express/ShoppingRestaurants.jsx";
import NewShopRestaurantExpress from "views/pages/express/NewShopRestaurantExpress.jsx";
import ViewRestaurantExpress from "views/pages/express/ViewRestaurantExpress.jsx";
import ShoppingDashboard from "views/pages/express/ShoppingDashboard.jsx";

import routes from "routes.js";
import MenuClone from "views/pages/gd/MenuClone/MenuClone";
import RecoverRestaurants from "views/pages/gd/RecoverRestaurants/RecoverRestaurants.jsx";
import AdminSettings from "views/pages/gd/AdminSettings";
import VideosSettings from "views/pages/gd/VideosSettings/Videos";
import Sessions from "views/pages/gd/Sessions/Sessions";
import AsaasRelation from "views/pages/gd/AsaasRelation/AsaasRelation";
import GeneralReports from "views/pages/gd/GeneralReports/GeneralReports";
import { NotificationsSettings } from "views/pages/gd/NotificationsSettings";
import {Researches} from "views/pages/gd/Researches";
import { Users } from "views/pages/gd/Users";
import { Success } from "views/pages/gd/Success";

var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
      user: props.user,
    };
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainPanel.scrollTop = 0;
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };
  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          user={this.state.user}
        />
        <div className="main-panel" ref="mainPanel">
          <AdminNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            user={this.state.user}
          />
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/dashboard" exact component={Dashboard} isPrivate />
            <Route path="/financial" exact component={Financial} isPrivate />

            <Route
              path="/restaurants/new"
              exact
              component={NewRestaurant}
              isPrivate
            />

            <Route path="/gd/menuclone" exact component={MenuClone} isPrivate />
            <Route
              path="/gd/recover"
              component={RecoverRestaurants}
              isPrivate
            />

            <Route
              path="/gd/admin-settings"
              exact
              component={AdminSettings}
              isPrivate
            />

            <Route
              path="/gd/videos-settings"
              exact
              component={VideosSettings}
              isPrivate
            />

            <Route
              path="/gd/notifications"
              exact
              component={NotificationsSettings}
              isPrivate
            />

            <Route path="/gd/sessions" exact component={Sessions} isPrivate />

            <Route
              path="/gd/general-reports"
              exact
              component={GeneralReports}
              isPrivate
            />

            <Route
              path="/gd/researches"
              exact
              component={Researches}
              isPrivate
            />

            <Route
              path="/gd/success"
              exact
              component={Success}
              isPrivate
            />

            <Route
              path="/gd/users"
              exact
              component={Users}
              isPrivate
            />

            <Route path="/gd/asaas" exact component={AsaasRelation} isPrivate />

            <Route
              path="/restaurants/view"
              component={ViewRestaurant}
              isPrivate
            />
            <Route
              path="/restaurants"
              exact
              component={Restaurants}
              isPrivate
            />
            <Route path="/profile" exact component={UserProfile} isPrivate />

            <Route path="/gd/dashboard" component={DashboardGD} isPrivate />
            <Route
              path="/gd/restaurants/edit/:id"
              component={EditRestaurantGD}
              isPrivate
            />
            <Route
              path="/gd/restaurants/new"
              component={NewRestaurant}
              isPrivate
            />
            <Route path="/gd/restaurants" component={Restaurants} isPrivate />

            <Route
              path="/qr/dashboard"
              component={DashboardExpress}
              isPrivate
            />

            <Route
              path="/qr/restaurants/view/:id"
              component={ViewRestaurantExpress}
              isPrivate
            />
            <Route
              path="/qr/restaurants/edit/:id"
              component={EditRestaurantExpress}
              isPrivate
            />
            <Route
              path="/qr/restaurants/new"
              component={NewRestaurantExpress}
              isPrivate
            />
            <Route
              path="/qr/restaurants"
              component={RestaurantsExpress}
              isPrivate
            />

            <Route path="/qr/shoppings/new" component={NewShopping} isPrivate />
            <Route
              path="/qr/shop-restaurants/new/:id"
              component={NewShopRestaurantExpress}
              isPrivate
              exact
            />
            <Route
              path="/qr/shoppings/view/:id"
              component={ShoppingRestaurants}
              isPrivate
            />
            <Route
              path="/qr/shoppings/:id"
              component={ShoppingDashboard}
              isPrivate
            />

            <Route path="/qr/shoppings" component={Shoppings} isPrivate />

            <Route
              path="/concierge/dashboard"
              component={DashboardConcierge}
              isPrivate
            />
            <Route
              path="/concierge/franchise"
              component={Franchise}
              isPrivate
            />
            <Route
              path="/concierge/hotels/new"
              component={NewConcierge}
              isPrivate
            />
            <Route path="/concierge/hotels" component={Concierge} isPrivate />
            <Route path="/settings" component={Settings} isPrivate />
          </Switch>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default Admin;
