import React, { useState, useEffect } from "react";

import { Body, SwitchArea, AsaasArea, ReportArea, ColStyled, Card, SwitchersArea } from "./styles";
import { Button, ToggleButton } from "ui-kit-takeat";
import { format } from "date-fns";
import { subMonths } from "date-fns";
import apiGd from "services/apiGd";
import Select from "components/Form/SelectInput";

const research = {
  title: "CSAT Gestor",
  question: "Pesquisa CSAT para Dashboard do gestor",
  method: "custom",
  createdAt: "2021-08-01",
  id: 136,
  type: "one-per-user-per-month",
};

function EditArea({ setAsaasId, user, asaasDefault, asaasRestaurants, changeIsActive, changeWppReport,isActive, setRestaurantData, restaurantData, createTablePos, getAsaas, notify, id }) {
  const tokenGd = localStorage.getItem("@QRExpress:tokenGd");
  const [restaurants, setRestaurants] = useState([]);
  const [months, setMonths] = useState([]);
  const [mrr, setMrr] = useState('0');
  const [asaasButtonLoading, setAsaasButtonLoading] = useState(false);
  const [createTableTicketButtonLoading, setCreateTableTicketLoading] = useState(false);

  const getAnswers = async () => {
    const m = [];
    const today = new Date();
    for (let i = 0; i < 6; i++) {
      m.push(subMonths(today, i));
    }
    setMonths(m);

    await apiGd.get("/admin/research-csat-manager", {
      params: {
        research_id: research.id,
      },
      headers: {
        Authorization: `Bearer: ${tokenGd}`,
      },
    }).then((response) => {
      setRestaurants(response.data);
    }).catch((error) => {
      console.log("error", error);
    });
  };

  useEffect(() => {
    getAnswers();
  }, []);

  useEffect(() => {
    const restaurant = restaurants?.find((restaurant) => restaurant.fantasy_name === restaurantData.fantasy_name);
    if (restaurant) {
      setMrr(restaurant?.mrr);
    }
  }, [restaurantData, restaurants]);

  const handleToggleChange = (field) => {
    // Atualiza o campo específico em restaurantData
    setRestaurantData({
      ...restaurantData,
      [field]: !restaurantData[field],
    });
  };

  const handleSettingsChange = (field) => {
    // Atualiza campos dentro de settings
    setRestaurantData({
      ...restaurantData,
      settings: {
        ...restaurantData.settings,
        [field]: !restaurantData.settings[field],
      },
    });
  };

  const handleAsaas = async () => {
    setAsaasButtonLoading(true);
    await getAsaas();
    setAsaasButtonLoading(false);
  };

  const handleCreateTableTicket = async () => {
    setCreateTableTicketLoading(true);
    await createTablePos();
    setCreateTableTicketLoading(false);
  };

  const recoverBalcony = async () => {
    await apiGd.put(`/admin/restaurants/recover/balcony/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${tokenGd}` },
      }
    ).then((response) => {
      notify("tr", "success", response?.data?.message || "Balcão retornado!");
    }).catch((error) => {
      notify("tr", "danger", error?.response?.data?.message || "Erro ao recuperar balcão");
      console.log("error", error);
    });
  };

  return (
    <Body>
      <ColStyled md={3}>
        <Card>
          <SwitchersArea>
          <SwitchArea>
            <ToggleButton
              value={restaurantData?.is_active}
              onClick={changeIsActive}   
              height=""         
            />
            <p>Ativo</p>
          </SwitchArea>
          <SwitchArea>
            <ToggleButton
              value={restaurantData?.settings?.wpp_report_active}
              onClick={changeWppReport}
              minWidth={45}
            />
            <p>Relatório via WhatsApp</p>
          </SwitchArea>
            
          </SwitchersArea>
        
        </Card>
      </ColStyled>
      <ColStyled md={3}>
        <Card>
          <AsaasArea>
            <p>Asaas</p>  
              {restaurantData?.asaas_id && !asaasDefault ? (                          
                <Button
                  title="Carregar Asaas"
                  buttonColor="#7A7A7A"
                  onClick={handleAsaas}
                  isLoading={asaasButtonLoading}
                />
              ) : asaasDefault ? (
                <div style={{width: '180px'}}>
                  <Select
                    name="asaas_id"
                    options={asaasRestaurants}
                    defaultValue={asaasDefault}
                    onChange={(e) => setAsaasId(e.value)}
                    styles={{}}
                    
                  />
                </div>
               
              ) : (
                <p style={{ textAlign: "center" }}>Carregando...</p>
              )}
          </AsaasArea>
        </Card>
      </ColStyled>
      <ColStyled md={3}>
        <Card>
          <ReportArea>
            <span className="title">Relatório via Whatsapp</span>
            <span className="info"><strong>MRR:</strong> {mrr === undefined ? 'R$ ' + mrr.replace('.', ',') : 'Sem dados disponíveis'}</span>
            {restaurantData?.settings?.wpp_report_expires_at ? (
              <span className="info">Teste encerrado em {format(new Date(restaurantData.settings.wpp_report_expires_at), 'dd/MM/yyyy - HH:mm')}</span>
            ) : (
              <span className="info"><strong>Válido até: </strong>Primeiro relatório ainda não enviado. Validade de 21 dias após a primeira mensagem</span>
            )}
          </ReportArea>
        </Card>
      </ColStyled>
      <ColStyled md={3}>
        <Card>
          <AsaasArea>
            <p>Mesa ficha</p>
            <Button
              title="Criar Mesa ficha"
              buttonColor="#7A7A7A"
              onClick={handleCreateTableTicket}
              isLoading={createTableTicketButtonLoading}
            />

            <Button
              title="Recuperar mesa balcão"
              buttonColor="#7A7A7A"
              onClick={recoverBalcony}
            />
          </AsaasArea>
        </Card>
      </ColStyled>
    </Body>
  );
}

export default EditArea;
