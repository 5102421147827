import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

import { CheckboxContainer } from "./styles";

function CheckboxInput({ name, label, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "checked",
    });
  }, [fieldName, inputRef, registerField]);

  return (
    <CheckboxContainer>
      <input
        type="checkbox"
        id={fieldName}
        ref={inputRef}
        value="true"
        defaultChecked={defaultValue}
        {...rest}
      />
      <label htmlFor={fieldName}>{label}</label>
      {error && <span>{error}</span>}
    </CheckboxContainer>
  );
}

export default CheckboxInput;
