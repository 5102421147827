import { Form } from "@unform/web";
import React, { useState, useCallback, useEffect } from "react";

import { Card, CardBody, CardFooter, CardTitle, Row, Col } from "reactstrap";
import api from "services/api";
import apiGd from "services/apiGd";
import CheckboxInput from "views/components/Checkbox";
import { ButtonLink } from "./styles";

export default function CardSettings() {
  const [menuEnable, setMenuEnable] = useState(true);
  const [systemEnable, setSystemEnable] = useState(true);
  const tokenGd = localStorage.getItem("@QRExpress:tokenGd");
  async function handleSetMenuEnable() {
    await apiGd.put(
      "/admin/settings",
      {
        name: "enable-menu",
        value: menuEnable === "true" ? "false" : "true",
      },
      {
        headers: { Authorization: `Bearer ${tokenGd}` },
      }
    );

    setMenuEnable(menuEnable === "true" ? "false" : "true");
  }

  async function handleSetClientEnable() {
    await apiGd.put(
      "/admin/settings",
      {
        name: "enable-clients",
        value: systemEnable === "true" ? "false" : "true",
      },
      { headers: { Authorization: `Bearer ${tokenGd}` } }
    );
    setSystemEnable(systemEnable === "true" ? "false" : "true");
  }

  const getRestaurantInfo = useCallback(async () => {
    const response = await apiGd.get("/admin/settings", {
      headers: { Authorization: `Bearer ${tokenGd}` },
    });

    const clients = response.data.filter(
      (item) => item.name === "enable-clients"
    )[0];
    const menu = response.data.filter((item) => item.name === "enable-menu")[0];

    setMenuEnable(menu?.value);
    setSystemEnable(clients?.value);
  }, []);

  // const history = useHistory()

  useEffect(() => {
    try {
      getRestaurantInfo();
    } catch (error) {
      console.log(error.message);
    }
  }, [getRestaurantInfo]);

  return (
    <Card className="card-stats">
      <Form>
        <CardBody>
          <Row>
            <Col>
              <CheckboxInput
                name="suspend_gd"
                label="Suspender Garçom Digital dos Clientes"
                onChange={(e) => handleSetClientEnable(!e.target.value)}
                checked={systemEnable === "true" ? false : true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CheckboxInput
                name="suspend_menu"
                label="Suspender Menus do Dashboard"
                onChange={(e) => handleSetMenuEnable(!e.target.value)}
                checked={menuEnable === "true" ? false : true}
              />
            </Col>
          </Row>
        </CardBody>
      </Form>
      <CardFooter>
        <hr />
        <div className="stats">
          <i className="fa fa-plus-slim" />
          <ButtonLink>Mais configurações</ButtonLink>
        </div>
      </CardFooter>
    </Card>
  );
}
