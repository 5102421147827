import React from "react";
import { Button, Card, CardBody, Col, ModalBody, Row } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import apiGd from "services/apiGd";
import { useCallback } from "react";
import { Form } from "@unform/web";
import Input from "views/components/Input";
import { toast } from "react-toastify";
import { ModalCreate } from "./styles";
import CheckboxInput from "views/components/Checkbox";
import formatCompleteDate from "services/formatCompleteDate";
import Select from "components/Form/SelectInput";
import AsaasComponent from "views/components/AsaasComponent";

function AsaasRelation() {
  const notificationAlert = useRef(null);
  const tokenGd = localStorage.getItem("@QRExpress:tokenGd");
  const [restaurants, setRestaurants] = useState([]);
  const [asaasRestaurants, setAsaasRestaurants] = useState([]);
  const [modalCreate, setModalCreate] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  const toggleModalCreate = () => [setModalCreate(!modalCreate)];

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const getRestaurants = useCallback(async () => {
    const response = await apiGd.get(`/admin/restaurants-minimal`, {
      headers: {
        Authorization: `Bearer: ${tokenGd}`,
      },
    });

    setRestaurants(response.data);
  }, [tokenGd, sessionId]);

  const getAsaas = useCallback(async () => {
    try {
      const responseAsaas = await apiGd.get(`admin/asaas-customers`, {
        headers: {
          Authorization: `Bearer: ${tokenGd}`,
        },
      });
  
      setAsaasRestaurants(responseAsaas.data?.response_array);
  
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações Asaas.");
    }
  }, [tokenGd, sessionId]);

  useEffect(() => {
    getRestaurants();
    getAsaas();
  }, [getRestaurants, getAsaas]);
  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content" style={{ marginLeft: "50px" }}>
        <Row>
          <Col md="10">
            <h3>Asaas</h3>
          </Col>
        </Row>
        {restaurants
          .map((restaurant) => <AsaasComponent restaurant={restaurant}  asaasRestaurants={asaasRestaurants} notify={notify}/>
        )}
      </div>
    </>
  );
}

export default AsaasRelation;
