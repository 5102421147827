import React, { useState, useEffect, useRef, useCallback } from "react";
import NotificationAlert from "react-notification-alert";
import { Button, Row, Col } from "reactstrap";
import CardRestaurantQR from "components/Cards/express/CardRestaurantQR/index";
import api from "../../../services/api";
import { LinkStyled, ShoppingInfos } from "./styles.js";
import history from "../../../services/history";

function ShoppingRestaurants(props) {
  const { id } = props.match.params;
  const notificationAlert = useRef(null);
  const [foodCourt, setFoodCourt] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const token = localStorage.getItem("@QRExpress:token");

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const getFoodCourt = useCallback(async () => {
    try {
      const response = await api.get(`admin/food-courts/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFoodCourt(response.data);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [id, token]);

  const getRestaurants = useCallback(async () => {
    try {
      const response = await api.get(`admin/food-courts/${id}/restaurants`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRestaurants(response.data);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [id, token]);

  useEffect(() => {
    getFoodCourt();
    getRestaurants();
  }, [getFoodCourt, getRestaurants]);

  let logoFoodcourt = foodCourt.avatar ? foodCourt.avatar.url : "";

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="8">
            <Button color="default" onClick={() => history.goBack()}>
              <span className="btn-label">
                <i className="nc-icon nc-minimal-left" />
              </span>
              Voltar
            </Button>
          </Col>
          <Col md="4">
            <div style={{ float: "right" }}>
              <LinkStyled to={`/qr/shop-restaurants/new/${foodCourt.id}`}>
                <Button className="btn-round" color="success" outline>
                  <i className="fa fa-plus" />
                  Novo Estabelecimento
                </Button>
              </LinkStyled>
            </div>
          </Col>
        </Row>
        <Row md="12">
          <ShoppingInfos>
            {logoFoodcourt !== "" && (
              <Col md="3">
                <img src={logoFoodcourt} alt="..." />
              </Col>
            )}
            <Col md="9">
              <h3>{foodCourt.fantasy_name}</h3>
            </Col>
          </ShoppingInfos>
        </Row>
        <Row>
          {restaurants.map((restaurant) => (
            <Col md="4">
              <LinkStyled to={`/qr/restaurants/view/${restaurant.id}`}>
                <CardRestaurantQR
                  restaurant={restaurant}
                  avatar={restaurant.avatar}
                  createdAt={restaurant.created_at}
                ></CardRestaurantQR>
              </LinkStyled>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default ShoppingRestaurants;
