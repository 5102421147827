import React from "react";
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from "reactstrap";

import { Doughnut } from "react-chartjs-2";

export default function CardPercent({
  title,
  subtitle,
  legend,
  footer,
  icon,
  chartExample,
  color,
}) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <p className="card-category">{subtitle}</p>
      </CardHeader>
      <CardBody>
        <Doughnut
          data={chartExample.data}
          options={chartExample.options}
          className="ct-chart ct-perfect-fourth"
          height={300}
          width={456}
        />
      </CardBody>
      <CardFooter>
        <div className="legend">
          <i className={`fa fa-circle text-${color}`} />
          {legend}
        </div>
        <hr />
        <div className="stats">
          <i className={icon} />
          {footer}
        </div>
      </CardFooter>
    </Card>
  );
}
