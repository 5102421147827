import React, { useState, useEffect, useRef, useCallback } from "react";
import NotificationAlert from "react-notification-alert";

import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup
} from "reactstrap";

import Select from '../../../components/Form/SelectInput';
import { Form } from "@unform/web";

import apiGd from "../../../../services/apiGd";
import Input from "views/components/Input";
import { useRestaurants } from "context/RestaurantsContext";

function AdminSettings() {
  const notificationAlert = useRef(null);
  const { validateUser } = useRestaurants();

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const tokenGd = localStorage.getItem("@QRExpress:tokenGd");

  const [restaurantsGD, setRestaurantsGD] = useState([]);
  const [restaurantsMultistore, setRestaurantsMultistore] = useState([]);

  const [restaurantGdDefault, setRestaurantGdDefault] = useState();
  const [restaurantMultistoreDefault, setRestaurantMultistoreDefault] = useState();

  const [userAdmin, setUserAdmin] = useState('');
  const [userAdminMultistore, setUserAdminMultistore] = useState('');

  const getInitialData = useCallback(async () => {
    try {
      const response = await apiGd.get("admin/restaurants", {
        headers: { Authorization: `Bearer ${tokenGd}` },
      });

      const responseUser = await apiGd.get("admin/user", {
        headers: { Authorization: `Bearer ${tokenGd}` },
      });

      const options = response.data.filter(rest => rest.is_multistore === false).map(restaurant => {
        return {
          label: restaurant.name,
          value: restaurant.id
        };
      });

      const optionsMultistore = response.data.filter(rest => rest.is_multistore === true).map(restaurant => {
        return {
          label: restaurant.name,
          value: restaurant.id
        };
      });

      const restaurantDefault = response.data.find(
        (rest) => rest.id === responseUser.data.admin.restaurant_id
      );

      const restaurantMultistoreDefault = response.data.find(
        (rest) => rest.id === responseUser.data.admin_multistore.restaurant_id
      );

      setRestaurantGdDefault({
        label: restaurantDefault.name,
        value:restaurantDefault.id,
      });

      setRestaurantMultistoreDefault({
        label: restaurantMultistoreDefault.name,
        value:restaurantMultistoreDefault.id,
      });

      setUserAdmin(responseUser.data.admin);
      setUserAdminMultistore(responseUser.data.admin_multistore);

      setRestaurantsGD(options);
      setRestaurantsMultistore(optionsMultistore);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
      console.log('errr', err);
    }
  }, [tokenGd]);

  const initialData = {
    username: userAdmin?.name,
    email: userAdmin?.email,
  };

  const initialDataMultistore = {
    username: userAdminMultistore?.name,
    email: userAdminMultistore?.email,
  };

  async function handleSubmit(data){
    try{
      await apiGd.put('/admin/user', {      
        name: data.username,
        email: data.email,
        password: data.password || null,
        restaurant_id: data.gd_restaurant
      },   
      { headers: { Authorization: `Bearer ${tokenGd}` },
    })

    notify("tr", "success", "Acesso configurado com sucesso.");
    }catch(error){
      notify("tr", "danger", "Erro ao configurar acesso.");
    }
  }

  async function handleSubmitMultistore(data){
    try{
      await apiGd.put('/admin/user/multistore', {      
        name: data.username,
        email: data.email,
        password: data.password || null,
        restaurant_id: data.gd_restaurant
      },   
      { headers: { Authorization: `Bearer ${tokenGd}` },
    })

    notify("tr", "success", "Acesso configurado com sucesso.");
    }catch(error){
      notify("tr", "danger", "Erro ao configurar acesso.");
    }
  }

  useEffect(() => {
    validateUser();
    getInitialData();
  }, [getInitialData, validateUser]);


  return (
    <>
    <NotificationAlert ref={notificationAlert} />

      <div className="content" style={{marginLeft: '50px'}}>
        <Row>
          <Col md="12">
            <h3>Configurar acesso do Usuário Admin.</h3>
          </Col>
    
        </Row>
        <Row>
          <Col md="4">
            <Row>
              <Form onSubmit={handleSubmit} initialData={initialData}>
                <Card body>
                  <CardBody style={{ width: 'clamp(300px, 30vw, 400px)' }}>
                    <Col>
                    <FormGroup>
                      <p>Administrador</p>
                    </FormGroup>             
                  </Col>
                  <Col>
                    <FormGroup>
                      <Input name="username"label="Nome"/>
                    </FormGroup>             
                  </Col>

                  <Col>
                    <FormGroup>
                      <Input name="email"label="Email"/>
                    </FormGroup>             
                  </Col>

                  <Col>
                    <FormGroup>
                      <Input name="password"label="Senha"/>
                    </FormGroup>             
                  </Col>
                  
                  <Col>
                    <FormGroup>
                      {restaurantGdDefault && (
                          <Select
                            label="Escolha o restaurante para ter acesso"
                            name="gd_restaurant"
                            options={restaurantsGD}
                            defaultValue={restaurantGdDefault}
                        />
                      )}
                  
                    </FormGroup>
                  </Col>

                  <Col>
                    <Button color="success" type="submit"> Salvar </Button>
                  </Col>
              
                  </CardBody>
                </Card>
              </Form>
            </Row>
          </Col>
          <Col md="4" style={{marginLeft: 20}}>
            <Row>
              <Form onSubmit={handleSubmitMultistore} initialData={initialDataMultistore}>
                <Card body>
                  <CardBody style={{ width: 'clamp(300px, 30vw, 400px)' }}>
                    <Col>
                    <FormGroup>
                      <p>Administrador Multilojas</p>
                    </FormGroup>             
                  </Col>
                  <Col>
                    <FormGroup>
                      <Input name="username"label="Nome"/>
                    </FormGroup>             
                  </Col>

                  <Col>
                    <FormGroup>
                      <Input name="email"label="Email"/>
                    </FormGroup>             
                  </Col>

                  <Col>
                    <FormGroup>
                      <Input name="password"label="Senha"/>
                    </FormGroup>             
                  </Col>
                  
                  <Col>
                    <FormGroup>
                      {restaurantMultistoreDefault && (
                          <Select
                            label="Escolha o restaurante para ter acesso"
                            name="gd_restaurant"
                            options={restaurantsMultistore}
                            defaultValue={restaurantMultistoreDefault}
                        />
                      )}
                  
                    </FormGroup>
                  </Col>

                  <Col>
                    <Button color="success" type="submit"> Salvar </Button>
                  </Col>
              
                  </CardBody>
                </Card>
              </Form>
            </Row>
          </Col>
        </Row>

        
   
      </div>
    </>
  );
}

export default AdminSettings;
