import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

import { FaDownload } from "react-icons/fa";

import { InputContainer } from "./styles";

function FileInput({ name, label, pattern, onModelClick, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, inputRef, registerField]);

  return (
    <InputContainer>
      <label htmlFor={fieldName} style={{ width: '120%' }}>
        {label}{' '}
        {onModelClick &&(
          <a title="Baixar Planilha Modelo" id="link" onClick={onModelClick}>
            (<FaDownload /> modelo)
          </a>
        )}
      </label>
      <input
        ref={inputRef}
        defaultValue={defaultValue}
        pattern={pattern}
        type="file"
        accept=".xls,.xlsx"
        {...rest}
      />
      {error && <span>{error}</span>}
    </InputContainer>
  );
}

export default FileInput;