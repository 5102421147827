import React from "react";
import { Card, CardBody, CardFooter, CardTitle, Row, Col } from "reactstrap";
import {ButtonLink} from './styles';

export default function CardDash({ title, total, icon, toggle, obs }) {
  return (
    <Card className="card-stats">
      <CardBody>
        <Row>
          <Col md="4" xs="5">
            <div className="icon-big text-center icon-warning">
              <i className={icon} />
            </div>
          </Col>
          <Col md="8" xs="7">
            <div className="numbers">
              <p className="card-category">{title}</p>
              <CardTitle tag="p">{total}</CardTitle>
              {obs && <p className="card-category">{obs}</p>}
              <p />
            </div>
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        {/* <hr />
        <div className="stats">
          <i className="fa fa-plus-slim" />
          <ButtonLink onClick={toggle}>
            Mais detalhes
          </ButtonLink>
        </div> */}
      </CardFooter>
    </Card>
  );
}
