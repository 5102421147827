import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Table,
} from "reactstrap";

import CardTableBody from "./CardTableBody";

export default function CardTableProducts({ title, subtitle, total, icon }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">{title}</CardTitle>
        <p className="card-category">{subtitle}</p>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="12">
            <Table responsive>
              <tbody>
                <CardTableBody
                  name="Big Mac"
                  total="405.87"
                  percent={30.58}
                  source="mc-bigmac"
                ></CardTableBody>

                <CardTableBody
                  name="Mc Lanche Feliz"
                  total="314.00"
                  percent={23.14}
                  source="mc-feliz"
                ></CardTableBody>

                <CardTableBody
                  name="McPicanha"
                  total="308.00"
                  percent={22.69}
                  source="mc-picanha"
                ></CardTableBody>

                <CardTableBody
                  name="McChicken"
                  total="298.86"
                  percent={19.45}
                  source="mc-chicken"
                ></CardTableBody>

                <CardTableBody
                  name="Big Tasty"
                  total="298.86"
                  percent={22.08}
                  source="mc-bigtasty"
                ></CardTableBody>
              </tbody>
            </Table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
