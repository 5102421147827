import styled from "styled-components";
import { Link } from "react-router-dom";

export const ButtonTab = styled(Link)`
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 10px 30px 10px 30px;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 40px;

  ${({ thisButton, activeButton }) =>
    activeButton === thisButton &&
    `
    border-bottom: #C8131B 2px solid;
  `}

  :hover {
    border-bottom: #C8131B 2px solid;
    color: inherit; /* blue colors for links t */
    text-decoration: inherit; /* no underline */
  }

  :focus {
    color: inherit; /* blue colors for links t */
    text-decoration: inherit; /* no underline */
  }
`;
export const Card = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
 
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
`;

export const CheckBoxArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  p {
    font-size: 18px;
    font-weight: bold;
  }

`