import React, { Component } from "react";
import { Form } from "@unform/web";
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Row,
  Col,
  FormText,
} from "reactstrap";
import history from "../../../services/history";
import Input from "../../components/Input";
import AvatarInput from "../../components/AvatarInput";
import api from "../../../services/api";

export default class NewShopping extends Component {
  render() {
    const notify = (place, color, message) => {
      var options = {};
      options = {
        place: place,
        message: (
          <div>
            <div>{message}</div>
          </div>
        ),
        type: color,
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 7,
      };
      this.refs.notificationAlert.notificationAlert(options);
    };
    const token = localStorage.getItem("@QRExpress:token");
    async function handleSubmitNewShopping(data) {
      try {
        const profile = {
          username: data.username,
          fantasy_name: data.fantasy_name,
          email: data.email,
          password: data.password,
          phone: data.phone,
          avatar_id: data.avatar,
        };

        await api.post("admin/food-courts", profile, {
          headers: { Authorization: `Bearer ${token}` },
        });

        window.location.replace("../shoppings");
        notify("tr", "success", "Cadastrado com sucesso!");
      } catch (err) {
        notify("tr", "danger", "Erro ao cadastrar.");
      }
    }

    return (
      <>
        <NotificationAlert ref="notificationAlert" />
        <div className="content">
          <Row>
            <Col md="3">
              <Button color="default" onClick={() => history.goBack()}>
                <span className="btn-label">
                  <i className="nc-icon nc-minimal-left" />
                </span>
                Voltar
              </Button>
            </Col>
          </Row>
          <h2>Cadastro de Shopping</h2>
          <Form className="form-horizontal" onSubmit={handleSubmitNewShopping}>
            <Row>
              <Col md="4" sm="4">
                <CardTitle tag="h4">Logo</CardTitle>
                <AvatarInput name="avatar" />
              </Col>
              <Col md="8">
                <Card>
                  <CardBody>
                    <Row>
                      <Label sm="2">Nome de Exibição</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input type="text" name="fantasy_name" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Nome de Usuário</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input type="text" name="username" />
                          <FormText color="default" tag="span">
                            Esse nome é um identificador único do
                            estabelecimento, deve ter letras minúsculas e sem
                            espaço. Ex: 'mcdonalds' / 'bobs' / 'burgerking'
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Row style={{ paddingTop: 15 }}>
                    <Label sm="2">Horário de Abertura</Label>
                    <Col sm="6">
                      <FormGroup>
                        <ReactDatetime
                          dateFormat={false}
                          defaultValue="11:00 AM"
                          inputProps={{
                            className: "form-control",
                          }}
                          name="open_time"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ paddingBottom: 15 }}>
                    <Label sm="2">Horário de Fechamento</Label>
                    <Col sm="6">
                      <FormGroup>
                        <ReactDatetime
                          dateFormat={false}
                          defaultValue="10:00 PM"
                          inputProps={{
                            className: "form-control",
                          }}
                          name="close_time"
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                    <Row>
                      <Label sm="2">Telefone</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input type="phone" name="phone" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">E-mail</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input type="email" name="email" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Senha</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            type="password"
                            name="password"
                            autoComplete="off"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: 15 }}>
                      <Col md="3" className="ml-auto">
                        <Button color="success" type="submit">
                          Cadastrar
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}
