import { Form } from "@unform/web";
import { useAuth } from "context/AuthContext";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaTrash } from "react-icons/fa";
import NotificationAlert from "react-notification-alert";
import { Button, CardBody, Spinner, Table } from "reactstrap";
import apiGd from "services/apiGd";
import Select from "views/components/Form/SelectInput";
import ResearchTR from "views/components/ResearchTR";
import ImageNotifications from "views/components/ImageNotifications";
import { Category, Content, Fixed, Image, Link, Row,  } from "./styles";

export const Researches = () => {
  const notificationAlert = useRef(null);
  const { tokenGd } = useAuth();
  const [researches, setResearches] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [changes, setChanges] = useState([]);
  const [created, setCreated] = useState({});
  const [asteriskCount, setAsteriskCount] = useState(1);
  const [loading, setLoading] = useState(false);

  const categoryOptions = [
    { value: "new", label: "Novidade" },
    { value: "fix", label: "Correção" },
    { value: "update", label: "Atualização" },
  ];

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const getResearches = async () => {
    const res = await apiGd.get("/admin/researches", {
      headers: {
        Authorization: `Bearer: ${tokenGd}`,
      },
    });

    setResearches(res.data);
  };

  useEffect(() => {
    getResearches();
  }, []);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <h3>Pesquisas</h3>

        <CardBody>
         { loading ? 
            <div style={{color: 'red', padding: 15, textAlign: 'center'}}>
              <Spinner /> 
            </div> :
          <Table>

            <tbody>
              {
                researches.map(research => (
                  <ResearchTR research={research}/>
                ))
              }
            </tbody>
          </Table>
          }
        </CardBody>
      </div>
    </>
  );
};