import React from "react";
import { Button, Card, CardBody, Col, ModalBody, Row } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import apiGd from "services/apiGd";
import { useCallback } from "react";
import { Form } from "@unform/web";
import Input from "views/components/Input";
import { toast } from "react-toastify";
import { ModalCreate } from "./styles";
import CheckboxInput from "views/components/Checkbox";

function VideosSettings() {
  const notificationAlert = useRef(null);
  const tokenGd = localStorage.getItem("@QRExpress:tokenGd");
  const [videos, setVideos] = useState([]);
  const [modalCreate, setModalCreate] = useState(false);

  const toggleModalCreate = () => [setModalCreate(!modalCreate)];

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const getVideos = useCallback(async () => {
    const response = await apiGd.get("/admin/videos", {
      headers: {
        Authorization: `Bearer: ${tokenGd}`,
      },
    });

    setVideos(response.data);
  }, [tokenGd]);

  const createVideo = async (data) => {
    try {
      const newVideo = await apiGd.post("/admin/videos", data, {
        headers: {
          Authorization: `Bearer: ${tokenGd}`,
        },
      });

      setVideos((state) => [...state, newVideo.data]);
      notify("tr", "success", "Botão criado");
      toggleModalCreate();
    } catch (err) {
      notify("tr", "danger", "Algo deu errado, culpa do Alan");
    }
  };

  const handleUpdate = async (data) => {
    const formattedUrl = data.url.substring(32);

    try {
      const updated = await apiGd.post(
        "/admin/videos/update",
        {
          ...data,
          url: formattedUrl,
        },
        {
          headers: {
            Authorization: `Bearer: ${tokenGd}`,
          },
        }
      );

      notify("tr", "success", "Botão atualizado");
    } catch (err) {
      console.log(err);
      notify("tr", "danger", "Algo deu errado, culpa do Alan");
    }
  };

  useEffect(() => {
    getVideos();
  }, []);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content" style={{ marginLeft: "50px" }}>
        <Row>
          <Col md="10">
            <h3>Configurar Videos do Dashboard</h3>
          </Col>
          <Col md="2">
            <Button onClick={() => toggleModalCreate()}>Criar</Button>
          </Col>
        </Row>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {videos
            ?.sort((a, b) => a.id - b.id)
            .map((v) => {
              return (
                <Card style={{ width: "400px", marginRight: "20px" }}>
                  <CardBody>
                    <Form
                      onSubmit={(data) => handleUpdate({ ...data, id: v.id })}
                      initialData={{
                        url: v.url
                          ? `https://www.youtube.com/watch?v=${v.url}`
                          : "",
                        title: v.title,
                        section_name: v.section_name,
                        is_active: v.is_active,
                        description: v.description,
                      }}
                    >
                      <Input
                        name="title"
                        placeholder="Texto do botão"
                        label="Texto do botão"
                      />
                      <Input
                        name="section_name"
                        placeholder="Seção"
                        label="Seção"
                      />
                      <Input name="url" placeholder="URL" label="URL" />
                      <Input
                        name="description"
                        placeholder="Descrição"
                        label="Descrição"
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <CheckboxInput name="is_active" label="Ativo" />
                        <h1
                          style={{
                            fontSize: "20px",
                            margin: 0,
                            textAlign: "right",
                          }}
                        >
                          ID: {v.id}
                        </h1>
                      </div>
                      <Button style={{ width: "-webkit-fill-available" }}>
                        Salvar
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              );
            })}
        </div>
      </div>
      <ModalCreate isOpen={modalCreate} toggle={toggleModalCreate}>
        <ModalBody>
          <Form onSubmit={createVideo}>
            <Input
              name="title"
              placeholder="Texto do botão"
              label="Texto do botão"
            />
            <Input name="section_name" placeholder="Seção" label="Seção" />
            <Input name="url" placeholder="URL" label="URL" />
            <Input
              name="description"
              placeholder="Descrição"
              label="Descrição"
            />
            <CheckboxInput name="is_active" label="Ativo" />
            <Button style={{ width: "-webkit-fill-available" }}>Salvar</Button>
          </Form>
        </ModalBody>
      </ModalCreate>
    </>
  );
}

export default VideosSettings;
