import XLSX from 'xlsx-js-style';
import style from './sheetStyle';

function createWorksheet(array) {
  const legend = [
    ['Legenda', ''],
    ['Abreviatura', 'Nome'],
    ['ST', 'Substituição Tributária'],
    ['SN', 'Simples Nacional'],
    ['RN', 'Regime Normal'],
  ];

  const headers = [
    ['Categoria', ''],
    ['Nome', ''],
    ['Descrição', ''],
    ['Preço', ''],
    ['Preço delivery', ''],
    ['CFOP', ''],
    ['NCM', ''],
    ['CSOSN', ''],
    ['Base Cálculo ICMS', 'vBC'],
    ['ICMS de origem', 'orig'],
    ['Alíquota ICMS SN', 'pICMS'],
    ['COFINS', ''],
    ['PIS', ''],
    ['CEST', ''],
    ['Alíquota PIS', 'pPIS'],
    ['Alíquota COFINS', 'pCOFINS'],
    ['CST', ''],
    ['CBENEF', ''],
    ['Alíquota ICMS RN', 'pICMS'],
    ['Modalidade Base Cálc. ICMS', 'modBC'],
    ['Valor ICMS', 'vICMS'],
    ['Base Cálc. ICMS ST', 'vBCSTRet'],
    ['Alíquota ICMS Final', 'pST'],
    ['Valor ICMS do Substituto', 'vICMSSubstituto'],
    ['ICMS ST Retido', 'vICMSSTRet'],
    ['Motivo da Deson. do ICMS', 'motDesICMS'],
    ['% da Redução Base de Cálc.', 'pRedBC'],
    ['ICMS Desonerado', 'vICMSDeson'],
    ['Alíq. do Crédito SN', 'pCredSN'],
    ['Valor do ICMS SN', 'vCredICMSSN'],
  ];

  const columns = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  columns.push(
    ...columns.map((c) => `A${c}`)
  );

  const rows = array.map((p) => {
    return [
      p.category, p.name, p.description,
      p.price, p.delivery_price,
      p.cfop, p.ncm,
      p.csosn, p.base_calculo,
      p.icms_origem, p.aliquota,
      p.cofins, p.pis,
      p.cest, p.pis_aliquota,
      p.cofins_aliquota, p.cst,
      p.cbenef, p.icms_aliquota,
      p.icms_modalidade_base_calculo, p.icms_valor,
      p.icms_base_calculo_retido_st, p.icms_aliquota_final,
      p.icms_valor_substituto, p.icms_valor_retido_st,
      p.icms_motivo_desoneracao, p.icms_reducao_base_calculo,
      p.icms_valor_desonerado, p.icms_aliquota_credito_simples,
      p.icms_valor_credito_simples,
    ];
  });

  for (const r of rows) {
    for (let j = 0; j < r.length; j += 1) {
      if (j === 3 || j === 4) {
        r[j] = r[j] ? parseFloat(r[j]) : '';
      } else {
        r[j] = r[j] || '';
      }
    }
  }

  const data_sheet = [...legend];
  data_sheet.push(
    [''],
    headers.map((r) => r[1]),
    headers.map((r) => r[0]),
  );
  data_sheet.push(...rows);

  const worksheet = XLSX.utils.aoa_to_sheet(data_sheet);

  // Every column with at least 16 chars of width
  worksheet['!cols'] = headers.map((h) => {
    if (h[0].length > 14) {
      return { wch: h[0].length + 2 };
    }

    return { wch: 16 };
  });

  worksheet["!merges"] = [{
    s: { r: 0, c: 0 },
    e: { r: 0, c: 1 },
  }];

  const white = 'ffffff';
  const black = '000000';
  const light_yellow = 'ffffd7';
  const yellow = 'ffffa6';
  const mustard = 'e6e905';

  const num_lines = rows.length;
  const num_cols = headers.length;

  for (let i = 1; i <= legend.length; i += 1) {
    for (let j = 0; j < 2; j += 1) {
      const cell = worksheet[columns[j] + i];

      if (cell) {
        const s = style();

        if (i < 3) {
          s.Font(black, 'center', 'center')
           .Bold()
           .Bg(yellow)
           .Borders('lrbt', mustard)
           .Get();
        } else {
          if ((i - 1) % 2 === 1) {
            s.Bg(light_yellow);
          } else {
            s.Bg(white);
          }

          if (j === 0) {
            s.Borders('btl', mustard);
          } else if (j === 1) {
            s.Borders('btr', mustard);
          } else {
            s.Borders('bt', mustard);
          }
        }

        cell.s = s;
      }
    }
  }

  for (let i = 7; i < 9; i += 1) {
    for (let j = 0; j < num_cols; j += 1) {
      const cell = worksheet[columns[j] + i];

      if (cell) {
        cell.s = style()
          .Font(black, 'center', 'center')
          .Bold()
          .Bg(yellow)
          .Borders('lrbt', mustard)
          .Get();
      }
    }
  }

  for (let i = 9; i <= data_sheet.length; i += 1) {
    for (let j = 0; j < num_cols; j += 1) {
      const cell = worksheet[columns[j] + i];

      if (cell) {
        const s = style();

        if (j > 2) {
          s.Font(black, '', 'right');
        } else {
          s.Font(black, '', 'left');
        }

        if ((i - 9) % 2 === 1) {
          s.Bg(light_yellow);
        } else {
          s.Bg(white);
        }

        if (j === 0) {
          s.Borders('btl', mustard);
        } else if (j === num_cols - 1) {
          s.Borders('btr', mustard);
        } else {
          s.Borders('bt', mustard);
        }

        if (j === 2 || j === 3) {
          s.Number();
        }

        cell.s = s.Get();
      }
    }
  }

  return worksheet;
}

export function generateProductsSheet(products, complements) {
  const workbook = XLSX.utils.book_new();

  const prod_worksheet = createWorksheet(products);
  const comp_worksheet = createWorksheet(complements);

  XLSX.utils.book_append_sheet(workbook, prod_worksheet, 'Produtos');
  XLSX.utils.book_append_sheet(workbook, comp_worksheet, 'Complementos');

  return XLSX.write(workbook, { type: 'buffer' });
}
