import React from "react";
import { Switch } from "react-router-dom";
import Login from "../views/pages/Login";
import Dashboard from "../views/Dashboard.jsx";
import Financial from "../views/Financial.jsx";
import Route from "./Route";
import UserProfile from "views/pages/UserProfile.jsx";
import Restaurants from "views/pages/gd/Restaurants/Restaurants.jsx";
import RestaurantsExpress from "views/pages/express/RestaurantsExpress.jsx";
import ViewRestaurantExpress from "views/pages/express/ViewRestaurantExpress.jsx";
import ViewRestaurant from "views/pages/gd/Restaurants/ViewRestaurant.jsx";
import NewRestaurant from "views/pages/gd/Restaurants/NewRestaurant.jsx";
import EditRestaurantGD from "views/pages/gd/Restaurants/EditRestaurantGD.jsx";
import Shoppings from "views/pages/express/Shoppings.jsx";
import NewShopping from "views/pages/express/NewShopping.jsx";
import DashboardExpress from "views/pages/express/DashboardExpress.jsx";
import DashboardConcierge from "views/pages/concierge/DashboardConcierge.jsx";
import NewRestaurantExpress from "views/pages/express/NewRestaurantExpress.jsx";
import Franchise from "views/pages/concierge/Franchise";
import Concierge from "views/pages/concierge/Concierge";
import NewConcierge from "views/pages/concierge/NewConcierge";
import Settings from "views/pages/Settings.jsx";
import EditRestaurantExpress from "views/pages/express/EditRestaurantExpress.jsx";
import ShoppingRestaurants from "views/pages/express/ShoppingRestaurants.jsx";
import ShoppingDashboard from "views/pages/express/ShoppingDashboard.jsx";
import NewShopRestaurantExpress from "views/pages/express/NewShopRestaurantExpress.jsx";
import MenuClone from "views/pages/gd/MenuClone/MenuClone.jsx";
import RecoverRestaurants from "views/pages/gd/RecoverRestaurants/RecoverRestaurants.jsx";
import AdminSettings from "views/pages/gd/AdminSettings";
import VideosSettings from "views/pages/gd/VideosSettings/Videos";
import Sessions from "views/pages/gd/Sessions/Sessions";
import AsaasRelation from "views/pages/gd/AsaasRelation/AsaasRelation";
import GeneralReports from "views/pages/gd/GeneralReports/GeneralReports";
import { NotificationsSettings } from "views/pages/gd/NotificationsSettings";
import { Researches } from "views/pages/gd/Researches";
import { Users } from "views/pages/gd/Users";
import { Success } from "views/pages/gd/Success";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/dashboard" exact component={Dashboard} isPrivate />
      <Route path="/financial" exact component={Financial} isPrivate />

      <Route
        path="/restaurants/new"
        exact
        component={NewRestaurant}
        isPrivate
      />

      <Route path="/restaurants/view" component={ViewRestaurant} isPrivate />
      <Route path="/restaurants" exact component={Restaurants} isPrivate />
      <Route path="/profile" exact component={UserProfile} isPrivate />
      <Route path="/gd/menuclone" component={MenuClone} isPrivate />
      <Route path="/gd/recover" component={RecoverRestaurants} isPrivate />
      <Route path="/gd/admin-settings" component={AdminSettings} isPrivate />
      <Route path="/gd/videos-settings" component={VideosSettings} isPrivate />
      <Route
        path="/gd/notifications"
        component={NotificationsSettings}
        isPrivate
      />
      <Route path="/gd/sessions" component={Sessions} isPrivate />
      <Route path="/gd/general-reports" component={GeneralReports} isPrivate />
      <Route path="/gd/researches" component={Researches} isPrivate />
      <Route path="/gd/success" component={Success} isPrivate />

      <Route path="/gd/users" component={Users} isPrivate />

      <Route path="/gd/asaas" component={AsaasRelation} isPrivate />

      <Route
        path="/gd/restaurants/edit/:id"
        component={EditRestaurantGD}
        isPrivate
      />
      <Route path="/gd/restaurants/new" component={NewRestaurant} isPrivate />
      <Route path="/gd/restaurants" component={Restaurants} isPrivate />

      <Route path="/qr/dashboard" component={DashboardExpress} isPrivate />

      <Route
        path="/qr/restaurants/edit/:id"
        component={EditRestaurantExpress}
        isPrivate
      />

      <Route
        path="/qr/restaurants/view/:id"
        component={ViewRestaurantExpress}
        isPrivate
      />

      <Route
        path="/qr/restaurants/new"
        component={NewRestaurantExpress}
        isPrivate
      />

      <Route path="/qr/restaurants" component={RestaurantsExpress} isPrivate />
      <Route path="/qr/shoppings/new" component={NewShopping} isPrivate />
      <Route
        path="/qr/shop-restaurants/new/:id"
        component={NewShopRestaurantExpress}
        isPrivate
        exact
      />
      <Route
        path="/qr/shoppings/view/:id"
        component={ShoppingRestaurants}
        isPrivate
      />
      <Route path="/qr/shoppings/:id" component={ShoppingDashboard} isPrivate />

      <Route path="/qr/shoppings" exact component={Shoppings} isPrivate />

      <Route
        path="/concierge/dashboard"
        component={DashboardConcierge}
        isPrivate
      />
      <Route path="/concierge/franchise" component={Franchise} isPrivate />
      <Route path="/concierge/hotels/new" component={NewConcierge} isPrivate />
      <Route path="/concierge/hotels" component={Concierge} isPrivate />

      <Route path="/settings" component={Settings} isPrivate />
    </Switch>
  );
}
