import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Table,
} from "reactstrap";

import CardTableBody from "./CardTableBody";

export default function CardTable({ title, subtitle, total, icon, restaurants }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">{title}</CardTitle>
        <p className="card-category">{subtitle}</p>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="12">
            {restaurants.length > 0 ?
            <Table>
                <thead>
                  <tr>
                    <th>Restaurante</th>
                    <th className="text-right">Pedidos</th>
                    <th className="text-right" >Arrecadado</th>
                    <th className="text-right">Porcentagem</th>
                    <th className="text-right">Tempo Médio p/ Aceitar</th>
                    <th className="text-right">Tempo Médio de Preparo</th>
                  </tr>
                </thead>
              <tbody>
                {restaurants.map((restaurant) => <CardTableBody
                  name={restaurant.fantasy_name}
                  total={restaurant.total_price}
                  percent={restaurant.percent}
                  amount={restaurant.amount}
                  source={restaurant.avatar_url}
                  time_to_accept={restaurant.time_to_accept_pound}
                  time_to_be_done={restaurant.time_to_be_done_pound}
                ></CardTableBody>)}
              </tbody>
            </Table> : <p style={{color: "red"}}>Não houve nenhum pedido no período selecionado.</p>}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
