import React, { useEffect, useState } from "react";
import { Content, Body } from './styles';
import { Button, Checkbox, DefaultInput, DefaultSelect } from "ui-kit-takeat";

function EditNFE({ restaurantData, setRestaurantData,deleteClubeModal,setDeleteClubeModal }) {
    const [tributaryMode, setTributaryMode] = useState(null);
    const tributaryRegim = [
        {
            value:'1',
            label:'Simples Nacional'
        },
        {
            value:'2',
            label:'Regime Normal'
        }
    ]
    const handleChange = (field, value) => {
        setRestaurantData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    useEffect(()=>{
        if(restaurantData?.regime_tributario === '1'){
            setTributaryMode({
                value:'1',
                label:'Simples Nacional'
            })
        }

        if(restaurantData?.regime_tributario === '2'){
            setTributaryMode({
                value:'2',
                label:'Regime Normal'
            })
        }
    },[restaurantData])


    return (
        <Content>
            <span style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 500 }}>
                NFe | Clube
            </span>
            <Checkbox
                label="Emissão NFCe"
                checked={restaurantData?.has_nfce}  
                onClick={(e) => handleChange('has_nfce', e)}  
            />
            <Body>
                <DefaultInput
                    title="Token de produção"
                    value={restaurantData?.nfce_token}  
                    onChange={(e) => handleChange('nfce_token', e.target.value)}  
                    containerStyles={{
                        gridArea: "productionToken"
                    }}
                />
                <DefaultInput
                    title="CNPJ"
                    value={restaurantData?.cnpj}  
                    onChange={(e) => handleChange('cnpj', e.target.value)}  
                    containerStyles={{
                        gridArea: "cnpj"
                    }}
                />
                <DefaultSelect
                    label="Regime tributário"
                    value={tributaryMode}  
                    onChange={(e) => {
                        handleChange('regime_tributario', e.value);
                    }}  
                    containerStyles={{
                        gridArea: "tributaryMode"
                    }}
                    options={tributaryRegim}
                />
                <Checkbox
                    label="Takeat Club ativo"
                    checked={restaurantData?.has_clube}  
                    onClick={(e) => handleChange('has_clube', e)}  
                    containerStyles={{
                        gridArea: "nfce"
                    }}
                />
                <DefaultInput
                    title="Token Takeat Club"
                    value={restaurantData?.token_clube}  
                    onChange={(e) => handleChange('token_clube', e.target.value)} 
                    containerStyles={{
                        gridArea: "takeatToken",
                    }}
                    style={{ height: 40 }}
                />
                <Button
                    title="Deletar clube"
                    buttonColor="#C8131B"
                    onClick={() => setDeleteClubeModal(!deleteClubeModal)} 
                    containerStyles={{
                        gridArea: "deletebutton",
                        height: 40
                    }}
                />
            </Body>
        </Content>
    );
}

export default EditNFE;
