import React, { createContext, useCallback, useState, useContext } from "react";
import { toast } from "react-toastify";
import api from "../services/api";
import apiGd from "../services/apiGd";
import { useAuth } from "./AuthContext";

const RestaurantsContext = createContext();

const RestaurantsProvider = ({ children }) => {
  const [restaurants, setRestaurants] = useState([]);
  const tokenGd = localStorage.getItem("@QRExpress:tokenGd");

  const { signOut } = useAuth();

  const getRestaurants = useCallback(async () => {
    try {
      const response = await apiGd.get("admin/restaurants", {
        headers: { Authorization: `Bearer ${tokenGd}` },
      });

      setRestaurants(response.data);
    } catch (err) {
      toast.error("tr", "danger", "Erro ao carregar informações.");
    }
  }, [tokenGd]);

  const validateUser = useCallback(async () => {
    try {
      const response = await apiGd.get("restaurants/users/validate", {
        headers: { Authorization: `Bearer ${tokenGd}` },
      });

      if(response.data.active === false){
        signOut();
      }
    } catch (err) {
      toast.error("tr", "danger", "Erro ao carregar informações.");
    }
  }, [tokenGd]);

  return (
    <RestaurantsContext.Provider value={{ restaurants, getRestaurants, validateUser }}>
      {children}
    </RestaurantsContext.Provider>
  );
};

function useRestaurants() {
  const context = useContext(RestaurantsContext);

  if (!context) {
    throw new Error(
      "useRestaurants must be used within an RestaurantsProvider"
    );
  }

  return context;
}

export { RestaurantsProvider, useRestaurants };
