import React, { useEffect, useState } from "react";
import { Content, Body } from './styles';
import { DefaultSelect } from "ui-kit-takeat";

function EditPlan({ restaurantData, setRestaurantData, handleChangeTypeOfPlans }) {
    const [selectedPlan, setSelectedPlan] = useState(null); 

    const userPlans = [
        {
            value: 'BÁSICO',
            label: 'BÁSICO'
        },
        {
            value: 'INTERMEDIÁRIO',
            label: 'INTERMEDIÁRIO'
        },
        {
            value: 'INOVAÇÃO',
            label: 'INOVAÇÃO'
        },
        {
            value: 'PRO',
            label: 'PRO'
        },
        {
            value: 'APENAS CARDÁPIO',
            label: 'APENAS CARDÁPIO'
        }
    ];


    const handleChange = (field, value) => {
        setRestaurantData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    useEffect(() => {
        const storedPlan = userPlans.find((plan) => plan.label === restaurantData?.settings.plan);
        if (storedPlan) {
            setSelectedPlan(storedPlan); 
        }
    }, [restaurantData?.settings?.plan]);

    return (
        <Content>
            <span style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 500 }}>
                Predefinição de plano
            </span>
            <Body>
                <DefaultSelect
                    label="Plano"
                    options={userPlans}
                    value={selectedPlan} 
                    onChange={(e) => {
                        handleChange('plan', e.label);
                        setSelectedPlan(e); 
                        handleChangeTypeOfPlans(e.label)
                    }}
                />
                {/* <DefaultSelect
                    label="Tipo de plano"
                    options={planDurationOption}
                    value={restaurantData?.plan_type}
                    onChange={(e) => handleChange('plan_type', e.label)}
                /> */}
            </Body>
        </Content>
    );
}

export default EditPlan;
