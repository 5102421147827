import React from "react";
import { Doughnut } from "react-chartjs-2";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import {
  chartExample1,
  chartExample3,
  chartApproval
} from "variables/charts.jsx";

import CardDash from "components/Cards/CardDash";
import CardProfileRestaurant from "components/Cards/CardProfileRestaurant";
import CardChart from "components/Cards/CardChart";
import CardTableProducts from "components/Cards/CardTable/CardTableProducts";
import CardTopProduct from "components/Cards/CardTopProduct";
import CardChartPlus from "components/Cards/CardChartPlus";

class ViewRestaurant extends React.Component {
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="3">
              {" "}
              <Button color="default" href="../restaurants">
                <span className="btn-label">
                  <i className="nc-icon nc-minimal-left" />
                </span>
                Voltar
              </Button>
            </Col>
            <Col md="9">
              <div style={{ float: "right" }}>
                <Button className="btn-round mr-auto" active color="info">
                  Hoje
                </Button>
                <Button className="btn-round mr-auto" color="info">
                  Essa semana
                </Button>
                <Button className="btn-round mr-auto" color="info">
                  Esse mês
                </Button>
                <Button className="btn-round mr-auto" color="info">
                  Selecionar Período
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <CardProfileRestaurant
                name="Mc Donald's"
                source="mcdonalds.png"
                sourceBg="mc-bg.jpg"
              ></CardProfileRestaurant>
            </Col>
            <Col md="7">
              <Row>
                <Col lg="6" md="6" sm="6">
                  <CardDash
                    title="Pedidos"
                    total="125"
                    icon="nc-icon nc-tap-01 text-danger"
                  ></CardDash>
                </Col>
                <Col lg="6" md="6" sm="6">
                  <CardDash
                    title="Arrecadação"
                    total="R$1,247.68"
                    icon="nc-icon nc-money-coins text-success"
                  ></CardDash>
                </Col>
              </Row>
              <Row>
                <Col lg="8" md="6" sm="6">
                  <CardDash
                    title="Média Avaliações Estabelecimento"
                    total="4.1"
                    icon="fa fa-star text-warning"
                  ></CardDash>
                </Col>
                <Col lg="4">
                  <Card>
                    <CardHeader>
                      <div style={{ textAlign: "center" }}>
                        <p className="card-category">Aprovação</p>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Doughnut
                        data={chartApproval.data}
                        options={chartApproval.options}
                        className="ct-chart ct-perfect-fourth"
                        height={300}
                        width={456}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg="6" sm="6">
              <CardChart
                total="124"
                percent="12"
                title="Clientes na última hora"
                color="info"
                chartExample={chartExample3}
              ></CardChart>
            </Col>
            <Col lg="6" sm="6">
              <CardChart
                total="R$258.37"
                percent="16"
                title="Arrecadações na última hora"
                color="success"
                chartExample={chartExample1}
              ></CardChart>
            </Col>
          </Row>
          <Row>
            <Col md="7">
              <CardTableProducts
                title="Produtos Mais Vendidos"
                subtitle="Confira quais são os produtos mais vendidos desse estabelecimento:"
              ></CardTableProducts>
            </Col>

            <Col md="5">
              <CardTopProduct
                source="mc-bigmac"
                name="Big Mac"
              ></CardTopProduct>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <CardChartPlus
                title="Vendas de Julho"
                subtitle="Arrecadação total do Mc Donald's por dia do mês"
                legend="Dias do Mês"
                footer="Atualizado diariamente"
              ></CardChartPlus>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default ViewRestaurant;
