import React, { useRef } from "react";
import Input from "../components/Input";
import { Form } from "@unform/web";
import { useAuth } from "../../context/AuthContext";
import NotificationAlert from "react-notification-alert";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Row,
  Col,
} from "reactstrap";

import logoTakeat from "assets/img/logotakeat.png";

function UserProfile() {
  const notificationAlert = useRef(null);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const formRef = useRef(null);
  const { user, updateProfile, updatePassword } = useAuth();

  const initialData = {
    fantasy_name: user.user_name,
    email: user.email,
    avatar: {
      id: user.avatar ? user.avatar.id : null,
      url: user.avatar ? user.avatar.url : null,
    },
  };

  async function handleSubmitProfile(data) {
    const response = await updatePassword({ oldPassword: data.oldPassword, password: data.password });
    response
      ? notify("tr", "success", "Perfil atualizado com sucesso!")
      : notify("tr", "danger", "Falha ao atualizar perfil.");
  }

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Form
          ref={formRef}
          initialData={initialData}
          onSubmit={handleSubmitProfile}
        >
          <Row>
            <Col md="4">
              <Card className="card-user">
                <div
                  className="image"
                  style={{ placeContent: "center", padding: 30 }}
                >
                  <img src={logoTakeat} alt="react-logo" />
                </div>
                <CardBody>
                  <div style={{ textAlign: "center" }}>
                    <h5 className="title">{user.fantasy_name}</h5>
                    <p style={{ color: "grey" }}>
                      Perfil de administração da Takeat.
                    </p>
                    <p style={{ color: "grey" }}>Desde 2020.</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Editar Perfil</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="pr-1" md="12">
                      <label>Nome</label>
                      <Input
                        name="fantasy_name"
                        placeholder="Nome"
                        type="text"
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="pr-1">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">E-mail</label>
                        <Input
                          name="email"
                          placeholder="mestrealvaro@gmail.com"
                          type="email"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" className="pr-1">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Senha Atual</label>
                        <Input
                          name="oldPassword"
                          type="password"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" className="pr-1">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Nova Senha</label>
                        <Input
                          name="password"
                          type="password"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 15 }}>
                    <Col md="4" className="ml-auto">
                      <Button color="success" type="submit">
                        Atualizar Perfil
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default UserProfile;
