import styled from "styled-components";

export const Content = styled.div`
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction:row;
    gap: 16px;
`;

export const Body = styled.div`
    display: grid;
    gap: 16px;
    grid-template:
        "name name username"
        "checkbox . ."
        "email phone whatsapp"
        "responsavel responsavel instagram"
        "saler saler .";
    grid-template-columns: repeat(3, 1fr);
`;

export const BodyImage = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;

    .buttttton{
        align-items:center;
    }
`
