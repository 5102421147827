import React from "react";

export default function CardTableBody({ name, total, percent, source, amount, time_to_accept, time_to_be_done }) {
  
  let accepted_in = time_to_accept !== undefined ? time_to_accept.toFixed(0) : 0;
  let done_in = time_to_be_done !== undefined ? time_to_be_done.toFixed(0) : 0;

  return (
    <tr>
      <td>
        <div className="flag" style={{ height: 80, width: 80}}>
          <img alt={name} src={source} />
        </div>
      </td>
      <td className="text-right">{amount}</td>
      <td className="text-right">R${total}</td>
      <td className="text-right">{percent}%</td>
      <td className="text-right">{accepted_in} min.</td>
      <td className="text-right">{done_in} min.</td>
    </tr>
  );
}
