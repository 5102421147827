import styled from "styled-components";

export const Content = styled.div`
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction:column;
    gap: 16px;

    h3 {
        margin:0px
    }
`;

export const CheckBoxArea = styled.div`
    display:flex;
    flex-direction:column;
    padding-bottom:16px;
    border-bottom: 1px solid #EDEDED;
    gap:10px;
`

export const InputsArea = styled.div`
    display:flex;
    align-items:center;
    gap:16px;
`