import React, { useState, useCallback, useEffect } from "react";
import { AiOutlineEye } from "react-icons/ai";
import formatCompleteDate from "../../../services/formatCompleteDate";
import EyeModalOrders from "../EyeModalOrders";
import { Button, Card, CardBody, Col, Modal, ModalBody, Row, Table } from "reactstrap";
import { Form } from "@unform/web";
import Select from "components/Form/SelectInput";
import apiGd from "services/apiGd";
import { generateResearchSheet } from "services/generateResearchSheet";
import { FaDownload } from 'react-icons/fa';
import FileSaver from 'file-saver';

import { Eye, RestauranteInfo, StatusTagInvoice } from "./styles";
import formatDateYear from "services/formatDateYear";
import { FaFileInvoiceDollar } from "react-icons/fa";
import CardDash from "components/Cards/CardDash";

export default function ResearchTR({ research }) {
  const tokenGd = localStorage.getItem("@QRExpress:tokenGd");

  const [answers, setAnswers] = useState([]);

  const getAnswers = useCallback(async () => {
    const response = await apiGd.get(`/admin/research/${research.id}`, {
      headers: {
        Authorization: `Bearer: ${tokenGd}`,
      },
    });

    setAnswers(response.data);
  }, [tokenGd, research]);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
    if(!modal === true){
      getAnswers();
    }
  }

  function getType(type){
    switch(type){
      case 'one-per-restaurant':
        return 'Uma resposta por restaurante';
      case 'one-per-user':
        return 'Uma resposta por usuário';
      case 'one-per-user-per-month':
        return 'Uma resposta por usuário por mês';
    }
  }

  function getNPS(value){
    // value = value ? parseFloat(value) : null;
    if(value >= 91) {
      return 'Zona de encantamento';
    }else if (value >= 76 && value <= 90) {
      return 'Zona de excelência';
    }else if (value >= 51 && value <= 75) {
      return 'Zona de qualidade';
    } else if (value >= 1 && value <= 50) {
      return 'Zona de aperfeiçoamento';
    } else if (value >= -100 && value <= 0) {
      return 'Zona crítica';
    } else {
      return null;
    }
  }

  //nps
  const detratoresPercent = (answers?.answers?.filter(ans => ans?.value !== null && parseFloat(ans?.value || 0) <= 6).length || 0) / (answers?.answers?.length || 1);
  const promotoresPercent = (answers?.answers?.filter(ans => ans?.value !== null && [9,10].includes(parseInt(ans?.value || 0))).length || 0) / (answers?.answers?.length || 1);

  const nps = answers?.answers?.length > 0 ? 
    (promotoresPercent - detratoresPercent) * 100
  : 0;

  //ces
  const poucoEsforcoPercent = (answers?.answers?.filter(ans => ans?.value !== null && [1, 2].includes(parseFloat(ans?.value || 0))).length || 0) / (answers?.answers?.length || 1);
  const muitoEsforcoPercent = (answers?.answers?.filter(ans => ans?.value !== null && [4, 5].includes(parseFloat(ans?.value || 0))).length || 0) / (answers?.answers?.length || 1);

  const ces = answers?.answers?.length > 0 ? 
    (poucoEsforcoPercent - muitoEsforcoPercent) * 100
  : 0;

  const handleDownloadSheet = async () => {
    try {
      const sheet = await generateResearchSheet(
        answers.answers
      );

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `${research.title}.xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  return (
    <>
      <tr style={{cursor: 'pointer'}} onClick={() => toggleModal()}>
        <td>
          <strong>{research.title}</strong>
          <p>{research.question}</p>
          <p>ID: {research.id} - {formatDateYear(research.createdAt)} - {getType(research.type)}</p>
          <p>Método: {research.method.toUpperCase()}</p>
        </td>
      </tr>

      <Modal isOpen={modal} toggle={toggleModal} size='xl'>
        <ModalBody>
          <Row>
            <Col md="10">
              <h4>{research.title}</h4>
              <p>{research.question}</p>
              <p>ID: {research.id} - {formatDateYear(research.createdAt)}  - {getType(research.type)}</p>   
              <p>Método: {research.method === 'nps' ? 'NPS' : 'CES'}</p>
            </Col>
            <Col md="2">
              <Button name="download_button" style={{float: 'right', marginTop: 20}} color="default" onClick={() => handleDownloadSheet()}>
                <FaDownload size={15}/>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <CardDash
                title="Respostas"
                total={answers?.answers?.length}
                icon="nc-icon nc-tap-01 text-warning"
              />
            </Col>
            <Col md="6">
            {research?.method === 'nps' ?
              <CardDash
                title="NPS"
                total={`${nps.toFixed(2)}%`}
                icon="nc-icon nc-zoom-split text-info"
                obs={getNPS(nps)}
              />
              :
                <CardDash
                  title="CES"
                  total={`${ces.toFixed(2)}%`}
                  icon="nc-icon nc-zoom-split text-info"
                  obs={ces >= 3.55 && 'Satisfatório'}
                />
            }
            </Col>
          </Row>
          <Row>
            {research?.method === 'nps' ?
              <>
                <Col md="4">
                  <CardDash
                    title="Detratores"
                    total={answers?.answers?.filter(ans => ans?.value !== null && parseFloat(ans?.value || 0) <= 6).length}
                  />
                </Col>
                <Col md="4">
                  <CardDash
                    title="Neutros" // 0 a 6 detrator; 7 e 8 neutros; 9 e 10 promotores
                    total={answers?.answers?.filter(ans => ans?.value !== null && [7,8].includes(parseInt(ans?.value || 0))).length}
                  />
                </Col>
                <Col md="4">
                  <CardDash
                    title="Promotores"
                    total={answers?.answers?.filter(ans => ans?.value !== null && [9,10].includes(parseInt(ans?.value || 0))).length}
                  />
                </Col>
              </> :
              <>
              <Col md="4">
                <CardDash
                  title="Pouco esforço"
                  total={answers?.answers?.filter(ans => ans?.value !== null && [1,2].includes(parseFloat(ans?.value || 0))).length}
                />
              </Col>
              <Col md="4">
                <CardDash
                  title="Esforço Esperado" // 0 a 6 detrator; 7 e 8 neutros; 9 e 10 promotores
                  total={answers?.answers?.filter(ans => ans?.value !== null && [3].includes(parseInt(ans?.value || 0))).length}
                />
              </Col>
              <Col md="4">
                <CardDash
                  title="Muito esforço"
                  total={answers?.answers?.filter(ans => ans?.value !== null && [4,5].includes(parseInt(ans?.value || 0))).length}
                />
              </Col>
            </>
            }
          </Row>
          <Table>
            <thead>
              <tr>
                <th className="text-left">Restaurante</th>
                <th className="text-left">Nota</th>
                <th className="text-left">Comentário</th>
                <th className="text-right">Data</th>
                <th className="text-right">Usuário</th>
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {
                answers?.answers?.map(answer => 
                  <tr>
                    <td className="text-left">
                      <strong>{answer.restaurant?.fantasy_name}</strong>
                      <RestauranteInfo>{answer.restaurant?.name}</RestauranteInfo>
                      <RestauranteInfo>{answer.restaurant?.place_tables?.length} mesas</RestauranteInfo>
                      {answer.restaurant?.has_nfce && <RestauranteInfo>Fiscal</RestauranteInfo>}
                      {answer.restaurant?.has_clube && <RestauranteInfo>Clube</RestauranteInfo>}
                      {answer.restaurant?.delivery_info?.is_delivery_allowed && <RestauranteInfo>Delivery</RestauranteInfo>}
                      {answer.restaurant?.has_ifood && <RestauranteInfo>iFood</RestauranteInfo>}
                    </td>
                    <td style={{color: 'orange'}} className="text-left"><strong>{answer.value ? parseInt(answer.value) : '-'} ★</strong></td>
                    <td className="text-left">{answer.comment}</td>
                    <td className="text-right">{formatCompleteDate(answer.createdAt)}</td>
                    <td className="text-right">
                      <RestauranteInfo>{answer?.user?.name}</RestauranteInfo>
                      <RestauranteInfo><i>{answer?.user?.access}</i></RestauranteInfo>
                    </td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    </>
  );
}
