import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Button,
  Row,
  Col,
} from "reactstrap";

export default function CardTopProduct({ source, name }) {
  return (
    <Card>
      <CardHeader>
        <Row>
          <Col sm="12">
            <div className="numbers pull-left">Mais Vendido</div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <h6 className="big-title">
          Esse foi o produto que mais vendeu nesse estabelecimento
        </h6>
        <Row md="12">
          <div style={{ margin: "auto" }}>
            <img alt="..." src={require(`assets/img/${source}.png`)} />
          </div>
        </Row>
      </CardBody>
      <CardFooter>
        <hr />
        <Row>
          <Col sm="7">
            <div className="footer-title">{name}</div>
          </Col>
          <Col sm="5">
            <div className="pull-right">
              <Button className="btn-round btn-icon" color="warning" size="sm">
                <i className="nc-icon nc-alert-circle-i" />
              </Button>
            </div>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
}
