import React from "react";
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes/index";
import { createBrowserHistory } from "history";
import { RestaurantsProvider } from "context/RestaurantsContext";
import { ToastContainer } from "react-toastify";
const hist = createBrowserHistory();

function App() {
  return (
    <div>
      <Router history={hist}>
        <AuthProvider>
          <RestaurantsProvider>
            <ToastContainer />
            <Routes />
          </RestaurantsProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
