import styled from "styled-components";

export const Content = styled.div`
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction:column;
    gap: 16px;
`;

export const Body = styled.div`
    display:flex;
    gap:16px;
`

