import React, { useState, useEffect, useRef, useCallback } from "react";
import NotificationAlert from "react-notification-alert";
import { Button, Row, Col } from "reactstrap";
import CardRestaurantShop from "components/Cards/express/CardRestaurantShop/index";
import api from "../../../services/api";
import { LinkStyled } from "./styles.js";

function Shoppings() {
  const notificationAlert = useRef(null);
  let [shoppings, setShoppings] = useState([]);
  const token = localStorage.getItem("@QRExpress:token");

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const getShoppings = useCallback(async () => {
    try {
      const response = await api.get("admin/food-courts", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setShoppings(response.data);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token]);

  useEffect(() => {
    getShoppings();
  }, [getShoppings]);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row md="12">
          <Col md="8">
            <h3>Praças de Alimentação de Shoppings</h3>
          </Col>
          <Col md="4">
            <div style={{ float: "right" }}>
              <LinkStyled to={"/qr/shoppings/new"}>
                <Button className="btn-round" color="success" outline>
                  <i className="fa fa-plus" />
                  Nova Praça
                </Button>
              </LinkStyled>
            </div>
          </Col>
        </Row>
        <Row>
          {shoppings.map(
            (shopping) =>
              shopping.is_single_restaurant === false &&
              shopping.username !== "shoppingtakeat" &&
              shopping.username !== "admintakeat" && (
                <Col md="6">
                  <LinkStyled to={`/qr/shoppings/${shopping.id}`}>
                    <CardRestaurantShop
                      shopping={shopping}
                    ></CardRestaurantShop>
                  </LinkStyled>
                </Col>
              )
          )}
        </Row>
      </div>
    </>
  );
}

export default Shoppings;
