import React, { useEffect, useState, useCallback } from "react";
import { Content, Body, BodyImage } from "./styles";
import { Button, Checkbox, DefaultInput, DefaultSelect } from "ui-kit-takeat";
import AvatarInputGd from "views/components/AvatarInputGd";
import { Col, Row } from "reactstrap";
import FileInput from "../../../../components/FileInput";
import { FaCheck } from "react-icons/fa";
import apiGd from "services/apiGd";

function EditProfile({hasNickname, setHasNickname, name, token, id, restaurantData, setRestaurantData, setInputSheet, setProdInputSheet, sendExcel, handleSetFile, users, sendInputsExcel, sendProductInputsExcel }) {
  const [listOfUserResponsible, setListOfUserResponsible] = useState([]);
  const [userResponsible,setUserResponsible] = useState({
    value:'',
    label:''
  });

  const handleChange = (field, value) => {
    setRestaurantData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (users && users.length) {
      setListOfUserResponsible(users);
    }
  }, [users]);

  useEffect(()=>{
    const user = listOfUserResponsible.find((user)=> user.value === restaurantData.responsible_user_id);
    setUserResponsible(user);

    
  },[restaurantData,listOfUserResponsible])

  const downloadProductInputsModel = useCallback(
    async () => {
      const reqConfig = {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      };

      const filename = `Ficha_Tecnica_${name}`;
      await apiGd.get(
        `/admin/download/product-inputs-sheet/${id}`,
        reqConfig
      ).then((r) => downloadFile(r.data, filename));
    },
    [id, name, token]
  );

  async function downloadInputsModel() {
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob',
    };

    const url =
      'https://takeat-imgs.s3.us-east-1.amazonaws.com/Modelo_Insumos.xlsx';

    fetch(url)
      .then(response => response.blob())
      .then((blob) => downloadFile(blob, 'Modelo_Insumos.xlsx'))
  }  

  function downloadFile(data, filename) {
    const href = URL.createObjectURL(data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }



  function toggleHasNickname(){
    setHasNickname(!hasNickname)
  }

  return (
    <Content>
    
      <Col>
        <span style={{ fontFamily: 'Poppins', fontSize: 24, fontWeight: 600 }}>
          Editar perfil
        </span>
        <Body>
          <DefaultInput
            title="Nome"
            value={restaurantData?.fantasy_name}
            onChange={(e) => handleChange('fantasy_name', e.target.value)}
            containerStyles={{ gridArea: "name" }}
          />
          <DefaultInput
            title="Username"
            value={restaurantData?.name}
            onChange={(e) => handleChange('name', e.target.value)}
            containerStyles={{ gridArea: "username" }}
            disabled
          />
          <Checkbox
            label="Apelido"
            checked={hasNickname}
            onClick={toggleHasNickname}
            containerStyles={{ gridArea: 'checkbox' }}
          />
          {hasNickname && (
            <DefaultInput              
              value={restaurantData?.nickname}
              onChange={(e) => handleChange('nickname', e.target.value)}
            />
          )
          }
          <DefaultInput
            title="E-mail"
            value={restaurantData?.email}
            onChange={(e) => handleChange('email', e.target.value)}
            containerStyles={{ gridArea: "email" }}
          />
          <DefaultInput
            title="Telefone"
            value={restaurantData?.phone}
            onChange={(e) => handleChange('phone', e.target.value)}
            containerStyles={{ gridArea: "phone" }}
          />
          <DefaultInput
            title="Whatsapp"
            value={restaurantData?.owner_wpp}
            onChange={(e) => handleChange('owner_wpp', e.target.value)}
            containerStyles={{ gridArea: "whatsapp" }}
          />
          <DefaultSelect
            label="Usuário responsável"
            options={listOfUserResponsible}
            value={userResponsible}
            onChange={(e)=> handleChange('responsible_user_id',e.value)}
            containerStyles={{ gridArea: "responsavel"}}
            controlStyles={{ height: 48 }}
          />
          <DefaultInput
            title="Instagram"
            value={restaurantData?.instagram}
            onChange={(e) => handleChange('instagram', e.target.value)}
            containerStyles={{ gridArea: "instagram" }}
          />

      
        </Body>


       <Col>
       <Row style={{ marginBottom: '5px' }}>
          <Col md="10" style={{ paddingLeft: '5px', paddingRight: '3px' }}>
            <FileInput
              label="Cardápio"
              name="excel_file"
              onChange={(e) => handleSetFile(e)}
            />
          </Col>

          <Col
            md="2"
            style={{
              display: 'flex',
              padding: '0px 5px 0px 3px',
            }}
          >
            <Button
              title="Enviar"
              style={{
                margin: '0px 0px 10px 0px',
                padding: '12px 14px',
                alignSelf: 'end',
              }}
              onClick={sendExcel}
                buttonColor="#7a7a7a"
            >
              <FaCheck />
            </Button>
          </Col>
        </Row>

        <Row style={{ marginBottom: '5px' }}>
          <Col md="10" style={{ paddingLeft: '5px', paddingRight: '3px' }}>
            <FileInput
              label="Ficha Técnica"
              name="prod_inputs_sheet"
              onModelClick={() => downloadProductInputsModel()}
              onChange={(e) => setProdInputSheet(e.target.files[0])}
            />
          </Col>

          <Col
            md="2"
            style={{
              display: 'flex',
              padding: '0px 5px 0px 3px',
            }}
          >
            <Button
              title="Enviar"
              style={{
                margin: '0px 0px 10px 0px',
                padding: '12px 14px',
                alignSelf: 'end',
              }}
              onClick={sendProductInputsExcel}
              buttonColor="#7a7a7a"
            >
              <FaCheck />
            </Button>
          </Col>
        </Row>

        <Row style={{ marginBottom: '5px' }}>
          <Col md="10" style={{ paddingLeft: '5px', paddingRight: '3px' }}>
            <FileInput
              label="Insumos"
              name="inputs_sheet"
              onModelClick={() => downloadInputsModel()}
              onChange={(e) => setInputSheet(e.target.files[0])}
            />
          </Col>

          <Col
            md="2"
            style={{
              display: 'flex',
              padding: '0px 5px 0px 3px',
            }}
          >
            <Button
              title="Enviar"
              style={{
                margin: '0px 0px 10px 0px',
                padding: '12px 14px',
                alignSelf: 'end',
              }}
              onClick={sendInputsExcel}
               buttonColor="#7a7a7a"
            >
              <FaCheck />
            </Button>
          </Col>
        </Row>

       </Col>
      </Col>

      <Col md="4">          
        <AvatarInputGd name="avatar_id" setRestaurantData={setRestaurantData}/>
      </Col>
    </Content>
  );
}

export default EditProfile;
