import React, {useState, useEffect} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
} from "reactstrap";

export default function CardTopEstablishment({ source }) {
  let url = source[0] ? source[0].avatar_url : "";
  let name = source[0] ? source[0].fantasy_name : "";
  let amount = source[0] ? source[0].amount : "";
  let price = source[0] ? source[0].total_price : "";
  let percent = source[0] ? source[0].percent : "";

  const [hasOrder,setHasOrder] = useState(source.length > 0 ? true : false);
  useEffect(() => {
    setHasOrder(source.length > 0 ? true : false)
    // setMonth();
  }, [source.length]);

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col sm="12">
            <div className="numbers pull-left">Top Estabelecimento</div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <h6 className="big-title">
          Esse foi o estabelecimento que mais arrecadou nesse período
        </h6>
        <Row md="12">
          <div style={{ margin: "auto" }}>
            <img alt={name} src={url} />
            {hasOrder === false && <p style={{color: "red"}}>Não houve nenhum pedido no período selecionado.</p>}
          </div>
        </Row>
        <Row md="12">
        {hasOrder === true &&
          <p style={{padding: "10px", textAlign: "center"}}>O {name} obteve {amount} pedidos, arrecadou R${price} e
          representou {percent}% das vendas do QR Express.
          </p>}
        </Row>
      </CardBody>
      
    </Card>
  );
}
