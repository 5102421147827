import React from "react";
import { CardBody, Row, Col } from "reactstrap";
import { LinkRestaurant, CardStyled } from "./styles";

export default function CardRestaurantConc({ source, name, adress, period }) {
  return (
    <LinkRestaurant href="restaurants/mcdonalds">
      <CardStyled>
        <CardBody>
          <Row>
            <Col md="4" style={{ margin: "auto" }}>
              <img alt="..." src={require(`assets/img/${source}`)} />
            </Col>
            <Col md="8">
              <h6>{name}</h6>
              <p>{adress}</p>
              <p>Cliente a {period} mês</p>
            </Col>
          </Row>
        </CardBody>
      </CardStyled>
    </LinkRestaurant>
  );
}
