import React, { useState, useRef } from "react";
import { Form } from "@unform/web";
import history from "../../../services/history";
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Row,
  Col,
  FormText,
  Spinner,
  CustomInput
} from "reactstrap";
import Input from "../../components/Input";
import AvatarInput from "../../components/AvatarInput";
import Select from '../../components/Form/SelectInput';
import api from "../../../services/api";

export default function NewRestaurantExpress() {
  const notificationAlert = useRef(null);
  const [clone, setClone] = useState(false);
  const [restaurants, setRestaurants] = useState({});
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("@QRExpress:token");

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };


  async function getRestaurants() {
    try {
      // setLoading(true);
      const response = await api.get("admin/restaurants", {
        headers: { Authorization: `Bearer ${token}` },
      });
      let restaurants = response.data.map((restaurant) => ({value: restaurant.id, label: `${restaurant.fantasy_name} - ${restaurant.username}`}));
      setRestaurants(restaurants);
      setClone(true);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }

  function changeCloneToClone(){
    if(!clone){
      getRestaurants();
    }else{
      setClone(false);
    }
  }

  async function handleSubmitNewQRR(data) {
    try {
      setLoading(true);
      const profile = {
        username: data.username,
        fantasy_name: data.fantasy_name,
        email: data.email,
        password: data.password,
        phone: data.phone,
        avatar_id: data.avatar,
        is_single_restaurant: true,
      };

      const praca = await api.post("admin/food-courts", profile, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const tax = (data.tax_multiplier / 100).toFixed(2);

      const restaurantProfile = {
        username: data.username,
        fantasy_name: data.fantasy_name,
        email: data.email,
        password: data.password,
        phone: data.phone,
        avatar_id: data.avatar,
        food_court_id: praca.data.id,
        sms_service_price: data.sms_service_price,
        tax_multiplier: tax,
        is_priority: true,
      };

      const response = await api.post(
        "admin/restaurants",
        restaurantProfile,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if(clone){
        try{
          const to_clone = {
            restaurant_id1: data.restaurant_to_clone,
            restaurant_id2: response.data.id,
          };
          
          await api.post("admin/restaurants/clone-menu", to_clone, {
            headers: { Authorization: `Bearer ${token}` },
          });
        }catch(err){
          notify("tr", "danger", "Erro ao clonar estabelecimento.");
        }
      }
      
      setLoading(false);
      history.goBack();
      notify("tr", "success", "Cadastrado com sucesso!");
    } catch (err) {
      notify("tr", "danger", "Erro ao cadastrar.");
    }
  }


  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="3">
            <Button color="default" onClick={() => history.goBack()}>
              <span className="btn-label">
                <i className="nc-icon nc-minimal-left" />
              </span>
              Voltar
            </Button>
          </Col>
        </Row>
        <h2>Cadastro de Estabelecimentos QR Express</h2>
        <Form className="form-horizontal" onSubmit={handleSubmitNewQRR}>
          <Row>
            <Col md="4" sm="4">
              <CardTitle tag="h4">Logo</CardTitle>
              <AvatarInput name="avatar" />
            </Col>
            <Col md="8">
              <Card>
                <CardBody>
                  <Row>
                    <Label sm="2">Clone de Restaurante</Label>
                    <Col style={{marginTop: 15}} sm="10">
                        <CustomInput onClick={changeCloneToClone} type="switch" id="switchClone" name="switchToClone"/>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Nome de Exibição</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" name="fantasy_name" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Nome de Usuário</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" name="username" />
                        <FormText color="default" tag="span">
                          Esse nome é um identificador único do
                          estabelecimento, deve ter letras minúsculas e sem
                          espaço. Ex: 'mcdonalds' / 'bobs' / 'burgerking'
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Telefone</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="phone" name="phone" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Taxa de SMS (R$)</Label>
                    <Col md="4">
                      <Input
                        name="sms_service_price"
                        type="text"
                        placeholder="R$"
                      />
                    </Col>
                    <Label sm="3">Taxa do Estabelecimento (%)</Label>
                    <Col md="3">
                      <FormGroup>
                        <Input
                          name="tax_multiplier"
                          type="text"
                          placeholder="%"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">E-mail</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="email" name="email" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Senha</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input
                          type="password"
                          name="password"
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  {clone &&
                  <Row style={{ paddingTop: 15 }}>
                    <Label sm="2">Restaurante para Clonar:</Label>
                    <Col sm="10">
                      <Select
                        name="restaurant_to_clone"
                        options={restaurants}
                      />
                    </Col>
                  </Row>}


                  <Row style={{ paddingTop: 15 }}>
                    <Col md="3" className="ml-auto">
                      <Button color="success" type="submit">
                        {loading ? <span><Spinner size="sm" color="white" /></span> : <span>Cadastrar</span>}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
