import React, { useState, useEffect, useRef, useCallback } from 'react';
import NotificationAlert from 'react-notification-alert';

import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,

  Spinner,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import Select from '../../../components/Form/SelectInput';
import { Form } from '@unform/web';

import apiGd from '../../../../services/apiGd';
import api from 'services/api';

function MenuClone() {
  const notificationAlert = useRef(null);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: 'now-ui-icons ui-1_bell-53',
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const tokenGd = localStorage.getItem('@QRExpress:tokenGd');
  const token = localStorage.getItem('@QRExpress:token');

  let [restaurantsGD, setRestaurantsGD] = useState([]);
  let [restaurantsQR, setRestaurantsQR] = useState([]);

  let [deleteModalData, setDeleteModalData] = useState(null);
  let [cloneConfirmModalData, setCloneConfirmModalData] = useState(null);

  let [disableCloneBtn, setDisableCloneBtn] = useState(false);

  const getRestaurantsGD = useCallback(async () => {
    try {
      const response = await apiGd.get('admin/restaurants?count_sessions=false', {
        headers: { Authorization: `Bearer ${tokenGd}` },
      });

      const resturants = response.data.filter((r) => !r.deleted_at);

      const options = resturants.map(restaurant => {
        return {
          label: restaurant.name,
          value: restaurant.id
        }
      });

      setRestaurantsGD(options);
    } catch (err) {
      notify('tr', 'danger', 'Erro ao carregar informações.');
    }
  }, [tokenGd]);

  const getRestaurantsQR = useCallback(async () => {
    try {
      const response = await api.get('admin/restaurants', {
        headers: { Authorization: `Bearer ${token}` },
      });

      const options = response.data.map(restaurant => {
        return {
          label: restaurant.username,
          value: restaurant.id
        }
      });

      setRestaurantsQR(options);
    } catch (err) {
      notify('tr', 'danger', 'Erro ao carregar informações.');
    }
  }, [token]);

  async function deleteWholeMenu(data) {
    const { restaurant_id } = data;

    if (!restaurant_id) {
      notify('tr', 'warning', 'Escolha o restuarante para deletar o menu.');
      return;
    }

    try {
      await apiGd.put(
        'admin/restaurants/menu/delete',
        { restaurant_id },
        {
          headers: {
            Authorization: `Bearer ${tokenGd}`,
          },
        }
      );

      setDeleteModalData(null);
      notify('tr', 'success', 'Menu deletado com sucesso.');
    } catch (error) {
      setDeleteModalData(null);
      notify('tr', 'danger', 'Erro ao deletar.');
    }
  }

  async function cloneFromGDToGD(data) {
    const { restaurant_id1, restaurant_id2 } = data;

    if (!restaurant_id1 || !restaurant_id2) {
      notify('tr', 'warning', 'Escolha os dois restuarantes.');
      return;
    }

    if (restaurant_id1 === restaurant_id2) {
      notify('tr', 'danger', 'Os restaurantes de origem e destino não podem ser iguais.');
      return;
    }

    try {
      const testMenuResponse = await apiGd.get(
        `admin/restaurants/menu/test/${restaurant_id2}`,
        {
          headers: {
            Authorization: `Bearer ${tokenGd}`,
          },
        }
      );

      const { empty } = testMenuResponse.data;

      if (!empty) {
        setCloneConfirmModalData(data);
        return;
      }

      setDisableCloneBtn(true);

      await apiGd.post(
        `admin/restaurants/clone-menu`,
        { restaurant_id1, restaurant_id2 },
        {
          headers: {
            Authorization: `Bearer ${tokenGd}`,
          },
        }
      );

      setDisableCloneBtn(false);

      notify('tr', 'success', 'Clonagem feita com sucesso.');
    } catch (error) {
      setDisableCloneBtn(false);
      setCloneConfirmModalData(null);

      notify('tr', 'danger', 'Erro ao clonar.');
    }
  }

  async function cloneFromGDToGDWithoutTests(data) {
    const { restaurant_id1, restaurant_id2 } = data;

    try {
      setCloneConfirmModalData(null);
      setDisableCloneBtn(true);

      await apiGd.post(
        `admin/restaurants/clone-menu`,
        { restaurant_id1, restaurant_id2 },
        {
          headers: {
            Authorization: `Bearer ${tokenGd}`,
          },
        }
      );

      setDisableCloneBtn(false);

      notify('tr', 'success', 'Clonagem feita com sucesso.');
    } catch (error) {
      setDisableCloneBtn(false);
      setCloneConfirmModalData(null);

      notify('tr', 'danger', 'Erro ao clonar.');
    }
  }

  async function cloneFromQRToGD(data) {
    const { qr_restaurant, gd_restaurant } = data;

    if (!qr_restaurant || !gd_restaurant) {
      notify('tr', 'warning', 'Escolha os dois restuarantes.');
      return;
    }

    try {
      await apiGd.post(
        '/admin/restaurants/clone-qr-menu',
        {
          qr_restaurant_id: qr_restaurant,
          gd_restaurant_id: gd_restaurant,
          qr_admin_token: token
        },
        {
          headers: {
            Authorization: `Bearer ${tokenGd}`,
          },
        }
      )

      notify('tr', 'success', 'Clonagem feita com sucesso.');
    } catch(error) {
      notify('tr', 'danger', 'Erro ao clonar.');
    }
  }

  useEffect(() => {
    getRestaurantsQR();
  }, [getRestaurantsQR]);

  useEffect(() => {
    getRestaurantsGD();
  }, [getRestaurantsGD]);

  return (
    <>
      <NotificationAlert ref={notificationAlert}/>

      <div className="content" style={{marginLeft: '50px', marginRight: '50px'}}>
        <Form onSubmit={(data) => setDeleteModalData(data)}>
          <Card body>
            <CardBody>
              <Row>
                <Col md="12">
                  <h3>Apagar cardápio de um restaurante</h3>
                </Col>
              </Row>

              <Row>
                <Col md="10">
                  <FormGroup>
                    <Select
                       label="Escolha o restaurante"
                       name="restaurant_id"
                       options={restaurantsGD}
                     />
                  </FormGroup>
                </Col>

                <Col
                  md="2"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'end'
                  }}>
                  <Button color="danger" type="submit"> Apagar </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>

        <Form onSubmit={cloneFromGDToGD}>
          <Card body>
            <CardBody>
              <Row>
                <Col md="12">
                  <h3>Clonar cardápio entre restaurantes do GD</h3>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <FormGroup>
                    <Select
                      label="Escolha o restaurante modelo"
                      name="restaurant_id1"
                      options={restaurantsGD}
                    />
                  </FormGroup>
                </Col>

               <Col md="6">
                 <FormGroup>
                   <Select
                      label="Escolha o restaurante destino"
                      name="restaurant_id2"
                      options={restaurantsGD}
                    />
                 </FormGroup>
               </Col>
              </Row>

              <Row>
                <Col md="12" style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Button
                    disabled={disableCloneBtn}
                    color="success"
                    type="submit">
                    Clonar
                    {disableCloneBtn && (
                      <>
                        {" "} <Spinner size="sm" color="white" />
                      </>
                    )}
                  </Button>

                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>

        <Form onSubmit={cloneFromQRToGD}>
          <Card body>
            <CardBody>
              <Row>
                <Col md="12">
                  <h3>Clonar cardápio do Qr Express</h3>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <FormGroup>
                    <Select
                      label="Escolha o restaurante modelo"
                      name="qr_restaurant"
                      options={restaurantsQR}
                    />
                  </FormGroup>
                </Col>

               <Col md="6">
                 <FormGroup>
                   <Select
                      label="Escolha o restaurante destino"
                      name="gd_restaurant"
                      options={restaurantsGD}
                    />
                 </FormGroup>
               </Col>
              </Row>

              <Row>
                <Col md="12" style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Button color="success" type="submit"> Clonar </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </div>

      <Modal isOpen={deleteModalData} toggle={() => setDeleteModalData(null)}>
        <ModalHeader> Apagar Cardápio </ModalHeader>

        <ModalBody>
          <Container>
            <p>
              Essa operação deletará os todos os{" "}
              <i style={{ color: "blue" }}>
                produtos, complementos e insumos
              </i>
               {" "}associados.
            </p>
            <p>
              Deseja realmente deletar o cardápio desse restaurante?
            </p>
          </Container>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={() => deleteWholeMenu(deleteModalData)}>
            Confirmar
          </Button>{" "}

          <Button
            color="danger"
            style={{marginRight: '10px'}}
            onClick={() => setDeleteModalData(null)}>

            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={cloneConfirmModalData != null}
        toggle={() => setCloneConfirmModalData(null)}
      >
        <ModalHeader> Clonar Cardápio </ModalHeader>

        <ModalBody>
          <Container>
            <p>
              O restaurante de destino possui produtos, complementos ou categorias de produtos e complementos cadastrados.
              Pode ocorrer duplicação de nomes após a clonagem.
            </p>
            <p>
              Tem certeza que deseja clonar?
            </p>
          </Container>
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            onClick={() => cloneFromGDToGDWithoutTests(cloneConfirmModalData)}>
            Confirmar
          </Button>{" "}

          <Button
            color="danger"
            style={{marginRight: '10px'}}
            onClick={() => setCloneConfirmModalData(null)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default MenuClone;
