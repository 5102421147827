import React, { useEffect, useState } from "react";
import { Content, CheckBoxArea, InputsArea } from './styles';
import { Button, Checkbox, DefaultInput, DefaultSelect } from "ui-kit-takeat";
import { FaTrashAlt } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import apiGd from "services/apiGd";



function EditPayments({   
    tableLimitTypeDefault,
    setTableLimitType,
    setIsTableLimitDisabled,
    setTableLimitTypeDefault,
    tableLimitValue,
    isTableLimitDisabled,
    notify, 
    restaurantData, 
    setRestaurantData,
    updateProfile, 
    id, 
    token, 
    ifoodRestaurants, 
    setIfoodRestaurants,
    setTableLimitValue
 }) {
           
    const handleChange = (field, value) => {
        setRestaurantData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const [infoIfood, setInfoIfood] = useState({
        merchant_id: '',
        name: ''
    });

    const handleAddIfoodId = async () => {
        const reqConfig = {
          headers: { Authorization: `Bearer ${token}` },
        };
    
        if (infoIfood.merchant_id === "" || infoIfood.name === "") {
          notify("tr", "danger", "Preencha nome e merchant id do ifood");
        } else {
          try {
            const data = {
              restaurant_id: id,
              name: infoIfood.name,
              merchant_id: infoIfood.merchant_id,
            };
            const response = await apiGd.post(
              `admin/restaurants/ifood/merchant`,
              data,
              reqConfig
            );
            setInfoIfood({name: "", merchant_id: ""})
            setIfoodRestaurants((state) => [...state, response.data]);
            notify("tr", "success", "Inserido com sucesso");
          } catch (err) {
            notify("tr", "danger", "Erro ao inserir.");
          }
        }
      };

  
    const handleDeleteIfoodId = async (ifood_merchant_id) => {
        const reqConfig = {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            restaurant_id: id,
            merchant_id: ifood_merchant_id,
          },
        };
    
        try {
          await apiGd.delete(`admin/restaurants/ifood/merchant`, reqConfig);
          setIfoodRestaurants((state) =>
            state.filter((rest) => rest.ifood_merchant_id !== ifood_merchant_id)
          );
          notify("tr", "success", "Deletado com sucesso");
        } catch (err) {
          notify("tr", "danger", "Erro ao remover.");
        }
      };

      const tableOptions = [
    { value: "only-balcony", label: "Somente balcão" },
    { value: "limited-tables-10", label: "Limite de 10 mesas" },
    { value: "limited-tables-30", label: "Limite de 30 mesas" },
    { value: "limited-tables", label: "Limitar mesas" },
    { value: "unlimited-tables", label: "Mesas ilimitadas" },
  ];

      return (
        <Content>
            <CheckBoxArea>
                <span style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 500 }}>Pagamento online</span>
                <Checkbox
                    label="Pix online"
                    checked={restaurantData?.has_pix}
                    onClick={(e) => handleChange('has_pix', e)}
                />
                <Checkbox
                    label="Cartão de credito online"
                    checked={restaurantData?.has_credit_card}
                    onClick={(e) => handleChange('has_credit_card', e)}
                />
                
                {(restaurantData?.has_pix || restaurantData?.has_credit_card) && (
             
                    <DefaultInput
                        placeholder="Token"
                        type="text"
                        value={restaurantData?.paytime_seller_id}
                        onChange={(e) => handleChange('paytime_seller_id', e.target.value)}
                    />
                    
                )}
                
            </CheckBoxArea>

            <CheckBoxArea>
                <span style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 500 }}>Cardápio digital</span>
                <Checkbox
                    label="Habilitar pedidos via QR code"
                    checked={!restaurantData?.only_qrcode}
                    onClick={(e) => {
                        setRestaurantData((prevData) => ({
                            ...prevData,
                            ["only_qrcode"]: !e,
                        }));
                    }}
                />
                <Checkbox
                    label="Habilitar balcão"
                    checked={restaurantData?.settings.has_balcony}
                    onClick={(e) => {
                        setRestaurantData((prevData) => ({
                            ...prevData,
                            settings: {
                                ...prevData.settings,
                                ["has_balcony"]: e,
                            }                            
                        }));
                    }}
                />
            </CheckBoxArea>

            <CheckBoxArea>
                <span style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 500 }}>Delivery</span>
                <Checkbox
                    label="Apenas delivery"
                    checked={restaurantData?.only_delivery}
                    onClick={(e) => handleChange('only_delivery', e)}
                />
                <Checkbox
                    label="Habilitar delivery e retirada"
                    checked={restaurantData?.delivery_info.is_delivery_allowed}
                    onClick={(e) => {
                        setRestaurantData((prevData) => ({
                            ...prevData,
                            delivery_info: {
                                ...prevData.delivery_info,
                                ["is_delivery_allowed"]: e,
                            }
                        }))
                    }}
                />
                <Checkbox
                    label="Habilitar o cadastro dos endereços de entrega"
                    checked={restaurantData?.delivery_info.allow_delivery_addresses}
                    onClick={(e)=>{
                        setRestaurantData((prevData) => ({
                            ...prevData,
                            delivery_info: {
                                ...prevData.delivery_info,
                                ["allow_delivery_addresses"]: e,
                            }
                        }))
                    }}
                />
            </CheckBoxArea>

            <CheckBoxArea>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Checkbox 
                    label="Ifood Ativo"
                    checked={restaurantData.has_ifood} 
                    onClick={() => {
                        setRestaurantData((old) => ({
                        ...old,
                        has_ifood: !restaurantData.has_ifood,
                        }));
                    }}
                    />

                    <InputsArea style={{marginTop: 10}}>
                    {restaurantData.has_ifood && (
                        <>
                        <DefaultInput
                            placeholder="iFood ID"
                            value={infoIfood.id}
                            onChange={(e) => setInfoIfood({ ...infoIfood, merchant_id: e.target.value })}
                        />
                        <DefaultInput
                            placeholder="Nome iFood"
                            value={infoIfood.name}
                            onChange={(e) => setInfoIfood({ ...infoIfood, name: e.target.value })}
                        />
                        <FaPlus
                            color="white"
                            style={{
                            cursor: 'pointer',
                            backgroundColor: '#C8131B',
                            padding: 12,
                            borderRadius: 8,
                            width: 55,
                            height: 40,
                            }}
                            onClick={handleAddIfoodId}
                        />
                        </>
                    )}
                    </InputsArea>

                    {restaurantData.has_ifood && ifoodRestaurants.length > 0 &&
                    ifoodRestaurants.map((item) => (
                        <div key={item.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ fontSize: 16, fontWeight: 500 }}>{item.ifood_merchant_id}</span>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                            <span style={{ fontSize: 16, fontWeight: 600 }}>{item.name}</span>
                            <FaTrashAlt
                            size={15}
                            color="#C8131B"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDeleteIfoodId(item.ifood_merchant_id)}
                            />
                        </div>
                        </div>
                    ))}
                </div>
                {restaurantData.has_ifood && (
                    <Checkbox
                    label="Integração com Código PDV"
                    checked={restaurantData?.settings.use_ifood_pdv_code}
                    onClick={(e) => {
                        setRestaurantData(old => {
                            return {
                                ...old,
                                settings: {
                                    ...old.settings,
                                    use_ifood_pdv_code: e
                                }
                            }
                        })
                    }}
                />
                )}
                
            </CheckBoxArea>


            <CheckBoxArea>
                <span style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 500 }}>Estoque</span>
                <Checkbox
                    label="Utilizar controle de estoque"
                    checked={restaurantData?.use_stock}
                    onClick={(e) => handleChange('use_stock', e)}
                />
            </CheckBoxArea>

            <CheckBoxArea>
                <span style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 500 }}>Totem | TEF PDV</span>
                <Checkbox
                    label="Totem"
                    checked={restaurantData?.has_stone_pos}
                    onClick={(e) => handleChange('has_stone_pos', e)}
                />
                <Checkbox
                    label="TEF PDV"
                    checked={restaurantData?.settings.has_stone_pdv}
                    onClick={(e)=>{
                        setRestaurantData((prevData) => ({
                            ...prevData,
                            settings: {
                                ...prevData.settings,
                                ["has_stone_pdv"]: e,
                            }
                        }))
                    }}
                />
                <DefaultInput
                    title="Secret Key Stone"
                    value={restaurantData?.sk_stone}
                    onChange={(e) => handleChange('sk_stone', e.target.value)}
                />
            </CheckBoxArea>

            <CheckBoxArea>
                <span style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 500 }}>Mesas</span>
                <InputsArea>
                    <DefaultSelect
                       name="table_limit_type"
                       options={tableOptions}
                       label="Limitar mesas"
                       value={tableLimitTypeDefault}
                       onChange={(e) => {
                         setTableLimitType(e.value);
                         e.value === "limited-tables"
                           ? setIsTableLimitDisabled(false)
                           : setIsTableLimitDisabled(true);
                         setTableLimitTypeDefault(e);
                       }}
                        controlStyles={{ height: 48 }}
                    />

                    <DefaultInput
                        title="Limitar mesas"
                        disabled={isTableLimitDisabled}
                        value={tableLimitValue}
                        type="number"
                        onChange={(e) =>
                          setTableLimitValue(e.target.value)
                        }
                    />
                </InputsArea>
            </CheckBoxArea>

            <CheckBoxArea>
                <span style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 500 }}>Automação do WhatsApp</span>
                <Checkbox
                    label="Habilitar automação de WhatsApp"
                    checked={restaurantData?.use_wpp}
                    onClick={(e) => handleChange('use_wpp', e)}
                />
            </CheckBoxArea>

            <CheckBoxArea>
                <span style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 500 }}>Smart POS</span>
                <Checkbox
                    label="Utilizar smart POS"
                    checked={restaurantData?.settings.use_smart_pos}
                    onClick={(e)=>{
                        setRestaurantData((prevData) => ({
                            ...prevData,
                            settings: {
                                ...prevData.settings,
                                ["use_smart_pos"]: e,
                            }
                        }))
                    }}
                />
            </CheckBoxArea>

            <CheckBoxArea>
                <span style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 500 }}>Tablet</span>
                <Checkbox
                    label="Utilizar tablet"
                    checked={restaurantData?.settings.use_tablet}
                    onClick={(e)=>{
                        setRestaurantData((prevData) => ({
                            ...prevData,
                            settings: {
                                ...prevData.settings,
                                ["use_tablet"]: e,
                            }
                        }))
                    }}
                />
            </CheckBoxArea>

            {/* <CheckBoxArea>
                <span style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 500 }}>SMS</span>
                <DefaultInput
                    title="Serviço de SMS (R$)"
                    value={restaurantData?.delivery_info.sms_service_price}
                    onChange={(e)=>{
                        setRestaurantData((prevData) => ({
                            ...prevData,
                            delivery_info: {
                                ...prevData.delivery_info,
                                ["sms_service_price"]: e.target.value,
                            }
                        }))
                    }}
                />
                <Checkbox
                    label="Painel de senha eletrônica"
                    checked={restaurantData?.delivery_info.is_sms_service_optional}
                    onClick={(e)=>{
                        setRestaurantData((prevData) => ({
                            ...prevData,
                            delivery_info: {
                                ...prevData.delivery_info,
                                ["is_sms_service_optional"]: e,
                            }
                        }))
                    }}
                />
                <Checkbox
                    label="SMS Disponível"
                    checked={restaurantData?.delivery_info.has_sms_service
                    }
                    onClick={(e)=>{
                        setRestaurantData((prevData) => ({
                            ...prevData,
                            delivery_info: {
                                ...prevData.delivery_info,
                                ["has_sms_service"]: e,
                            }
                        }))
                    }}
                />
                <Checkbox
                    label="SMS Pago pelo cliente"
                    checked={restaurantData?.delivery_info.client_pay_sms}
                    onClick={(e)=>{
                        setRestaurantData((prevData) => ({
                            ...prevData,
                            delivery_info: {
                                ...prevData.delivery_info,
                                ["client_pay_sms"]: e,
                            }
                        }))
                    }}
                />
            </CheckBoxArea> */}

            <CheckBoxArea>
                <span style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 500 }}>Aviso de pagamento</span>
                <Checkbox
                    label="Ativar aviso de pagamento"
                    checked={restaurantData?.suspension_alert}
                    onClick={(e) => handleChange('suspension_alert', e)}
                />
            </CheckBoxArea>
            <div style={{display:'flex',justifyContent:'flex-end'}}>
                <Button
                    title='Atualizar'
                    buttonColor="#2EC9B7"
                    onClick={()=> updateProfile(restaurantData)}
                    />
            </div>
        </Content>
    );
}

export default EditPayments;
