import styled from "styled-components";

export const Content = styled.div`
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction:column;
    gap: 16px;
`;

export const Body = styled.div`
    display:grid;
    align-items:end;
    gap:16px;
    grid-template:
    "productionToken productionToken cnpj tributaryMode"
    "nfce . . ."
    "takeatToken takeatToken takeatToken deletebutton"
    ;

    grid-template-columns: repeat(4, 1fr);
`

