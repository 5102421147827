import React from "react";

// reactstrap components
import {
  Button,
  Row,
  Col,
} from "reactstrap";

import CardRestaurantConc from "components/Cards/concierge/CardRestaurantConc/index";

class Franchise extends React.Component {
  render() {
    return (
      <>
        <div className="content">
          <Row md="12">
            <Col md="9">
              <div className="typography-line">
                <h3>Franquias com QR Concierge</h3>
              </div>
            </Col>
            <Col md="3">
              <Button
                className="btn-round"
                href="../new-restaurants"
                color="success"
                outline
              >
                <i className="fa fa-plus" />
                Nova Franquia
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <CardRestaurantConc
                source="motel-lasvegas.jpg"
                name="Las Vegas Motel"
                adress="Espírito Santo"
                period="1"
              ></CardRestaurantConc>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Franchise;
