import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: #ffffff;
  width: 100%;

  label {
    font-weight: bold;
    color: #3d3d3d;
    font-size: 16px;
    font-family: "Poppins";
  }

  input {
    width: 100%;
    height: 40px;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    padding: 10px;

    &:disabled {
      background: #e3e3e3;
    }

    ::file-selector-button {
      cursor: pointer;
    }

    ::file-selector-button:hover {
    }
  }

  span {
    font-weight: bold;
    color: blue;
  }

  #link {
    color: #51cbce;

    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
