import React, { useRef, useState, useEffect, useCallback } from "react";
import FileSaver from 'file-saver';

import { Form } from "@unform/web";
import NotificationAlert from "react-notification-alert";

import history from "../../../../services/history";
import {
  Button,
  Card,
  Row,
  Col,
  Label,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { useRestaurants } from "context/RestaurantsContext";
import { useAuth } from "context/AuthContext";


import { ButtonTab } from "./styles";
import Input from "../../../components/Input";

import RecoverMenu from "./RecoverMenu";

import { generateProductsSheet } from "services/generateProductsSheet";
import apiGd from "../../../../services/apiGd";
import { format } from "date-fns";
import EditProfile from "./EditProfile/EditProfile";
import EditLocation from "./EditLocation/EditLocation";
import EditPlan from "./Editplan/EditPlan";
import EditNFE from "./EditNFE/EditNFE";
import EditPayments from "./EditPayments/EditPayments";
import EditArea from "./EditArea/EditArea";

function EditRestaurantGD(props) {
  const formRef = useRef(null);
  const notificationAlert = useRef(null);
  const { id } = props.match.params;
  const [restaurant, setRestaurant] = useState([]);
  const token = localStorage.getItem("@QRExpress:tokenGd");
  const { getRestaurants } = useRestaurants();
  const { user } = useAuth();

  const [nfceCheck, setNfceCheck] = useState(false);
  const [clubeCheck, setClubeCheck] = useState(false);
  // const [nfceToken, setNfceToken] = useState(null);
  const [deleteClubeModal, setDeleteClubeModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState("");

  const [isActive, setIsActive] = useState(false);
  const [asaasId, setAsaasId] = useState(null);
  const [onlyDelivery, setOnlyDelivery] = useState(false);
  const [onlyQrcode, setOnlyQrcode] = useState(false);
  const [useStock, setUseStock] = useState(false);
  const [useWpp, setUseWpp] = useState(false);

  const [isDeliveryAllowed, setIsDeliveryAllowed] = useState(false);
  const [isWithdrawalAllowed, setIsWithdrawalAllowed] = useState(false);
  const [isDeliveryAddressesAllowed, setIsDeliveryAddressesAllowed] =
    useState(false);
  const [isPixAllowed, setIsPixAllowed] = useState(false);
  const [isCreditCardAllowed, setIsCreditCardAllowed] = useState(false);

  const [hasStonePos, setHasStonePos] = useState(false);
  const [hasStonePdv, setHasStonePdv] = useState(false);
  const [skStone, setSkStone] = useState('');

  const [buttonTab, setButtonTab] = useState(1);

  const [suspensionAlert, setSuspensionAlert] = useState(false);

  const [restHasIfood, setRestHasIfood] = useState(false);
  
  const [hasNickname, setHasNickname] = useState(false);
  const [nickname, setNickname] = useState("");
  const [plan, setPlan] = useState();
  const [passwordPanel, setPasswordPanel] = useState(false);
  const [smsService, setSmsService] = useState(false);
  const [clientPaySms, setClientPaySms] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [nameIfood, setNameIfood] = useState("");
  const [merchantIfood, setMerchantIfood] = useState("");
  const [ifoodRestaurants, setIfoodRestaurants] = useState([]);
  const [asaasRestaurants, setAsaasRestaurants] = useState([]);
  const [users, setUsers] = useState([]);
  const [hasBalcony, setHasBalcony] = useState(false);
  const [responsibleUser, setResponsibleUser] = useState(null);
  const [ifoodPdv, setIfoodPdv] = useState(false);

  const [name, setName] = useState("");
  const [instagram, setInstagram] = useState("");

  const [onlyQrcodeDisabled, setOnlyQrcodeDisabled] = useState(false);

  const [tableLimitType, setTableLimitType] = useState("unlimited-tables");
  const [isTableLimitDisabled, setIsTableLimitDisabled] = useState(true);
  const [tableLimitValue, setTableLimitValue] = useState(null);
  const [tableLimitTypeDefault, setTableLimitTypeDefault] = useState({});
  const [Adress, setAdress] = useState({
    city: "",
    neighborhood: "",
    state: "",
    street: "",
    zip_code: "",
    number: ""
  });

  const [Settings, setSettings] = useState({
    has_balcony: false,
    use_smart_pos: false,
    plan: null,
    use_tablet: false,
    has_stone_pdv: false,
    use_ifood_pdv_code: false,
    wpp_report_active: false
  });

  const [deliveryInfo,setDeliveryInfo] = useState({
    is_delivery_allowed: false,
    allow_delivery_addresses: false,
    sms_service_price: "",
    is_sms_service_optional: false,
    has_sms_service: false,
    client_pay_sms: false,
    is_withdrawal_allowed: false
  });

  const [restaurantData, setRestaurantData] = useState({
    adress: {
      city: "",
      neighborhood: "",
      state: "",
      street: "",
      zip_code: "",
      number: ""
    },
    asaas_id: "",
    avatar_id: null,
    city: "",
    cnpj: "",
    delivery_info: deliveryInfo,
    email: "",
    excel_file: "",
    fantasy_name: "",
    geolocation_cb: false,
    has_balcony: false,
    has_clube: false,
    has_credit_card: false,
    has_ifood: false,
    has_nfce: false,
    has_nickname: false,
    has_pix: false,
    has_stone_pos: false,
    has_stone_pdv: false,
    ifood_merchant_id: "",
    ifood_name: "",
    instagram: "",
    is_active: false,
    is_location_limited: false,
    latitude: "",
    longitude: "",
    model_clube: "",
    name: "",
    nickname: null,
    nfce_token: "",
    number: "",
    only_delivery: false,
    only_qrcode: false,
    owner_wpp: "",
    paytime_seller_id: "",
    phone: "",
    regime_tributario: "",
    settings: Settings,
    sk_stone: "",
    state: "",
    street: "",
    suspension_alert: false,
    table_limit: 0,
    table_limit_type: "",
    token_clube: "",
    type_of_plan: "",
    use_ifood_pdv_code: false,
    use_smart_pos: false,
    use_stock: false,
    use_tablet: false,
    use_wpp: false,
    wpp_report_active: false,
    zip_code: ""
  });
  

  function changeBalconyCheck() {
    setHasBalcony(!hasBalcony);
  }

  async function updatePassword() {
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      if (newPassword.length < 6) {
        notify("tr", "danger", "Senha tem que ter no mínimo 6 caracteres");
      } else {
        const data = { password: newPassword };
        await apiGd.put(`admin/restaurants/password/${id}`, data, reqConfig);
        toggleModalPassword();
        notify("tr", "success", "Senha atualizada com sucesso!");
      }
    } catch (err) {
      notify("tr", "danger", "Erro ao atualizar senha");
    }
  }

  function changeNfceCheck() {
    setNfceCheck(!nfceCheck);
  }

  function changeClubeCheck() {
    setClubeCheck(!clubeCheck);
  }

  function changePixAllowed() {
    setIsPixAllowed(!isPixAllowed);
  }

  function changeCreditCardAllowed() {
    setIsCreditCardAllowed(!isCreditCardAllowed);
  }

  function changeOnlyDelivery() {
    setOnlyDelivery(!onlyDelivery);
    if (!onlyDelivery === true) {
      setOnlyQrcode(true);
      setOnlyQrcodeDisabled(true);
    } else {
      setOnlyQrcodeDisabled(false);
    }
    setIsDeliveryAllowed(true);
  }

  function changeOnlyQrcode() {
    setOnlyQrcode(!onlyQrcode);
  }

  function changeDeliveryAllowed() {
    setIsDeliveryAllowed(!isDeliveryAllowed);
  }

  function changeWithdrawalAllowed() {
    setIsWithdrawalAllowed(!isWithdrawalAllowed);
  }

  function changeDeliveryAddressesAllowed() {
    setIsDeliveryAddressesAllowed(!isDeliveryAddressesAllowed);
  }

  function changeUseStock() {
    setUseStock(!useStock);
  }

  function changeSmsService() {
    setSmsService(!smsService);

    if (smsService) {
      setClientPaySms(false);
    }
  }

  function changeClientPaySms() {
    setClientPaySms(!clientPaySms);
  }

  function changeSuspensionAlert() {
    setSuspensionAlert(!suspensionAlert);
  }

  function changePasswordPanel() {
    setPasswordPanel(!passwordPanel);

    if (passwordPanel) {
      setSmsService(true);
    }
  }

  const [locationLimitedCheck, setLocationLimitedCheck] = useState();
  function changeLocationCheck() {
    setLocationLimitedCheck(!locationLimitedCheck);
  }

  const [modal, setModal] = useState(false);
  function toggleModal() {
    setModal(!modal);
  }

  const [modalPassword, setModalPassword] = useState(false);
  function toggleModalPassword() {
    setModalPassword(!modalPassword);
  }

  const getUsers = useCallback(async () => {
    try {
      const res = await apiGd.get("/restaurants/users", {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      });

      //removendo o usuario Admin/Padrao do nosso dash
      const users = res.data
        .filter((data) => data.id !== 1)
        .map((data) => {
          return {
            label: data.name,
            value: data.id,
          };
        });

      setUsers(users);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
      console.log(err)
    }
  }, [token]);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };

    notificationAlert.current.notificationAlert(options);
  };

  const [inicialLocation, setInicialLocation] = useState([0, 0]);
  const [selectedPosition, setSelectedPosition] = useState([0, 0]);
  const [useSmartPos, setUseSmartPos] = useState(false);
  const [useTablet, setUseTablet] = useState(false);

  const [ownerWpp, setOwnerWpp] = useState('');

  function changeUseSmartPos() {
    setUseSmartPos(!useSmartPos);
  }

  // função antiga
  // const getInfo = useCallback(async () => {
  //   const reqConfig = {
  //     headers: { Authorization: `Bearer ${token}` },
  //   };

  //   try {
  //     const response = await apiGd.get(`admin/restaurants/${id}`, reqConfig);

  //     setRestaurant(response.data);

  //     setResponsibleUser(response.data.responsible_user_id || null);

  //     if (!!response.data.longitude && !!response.data.latitude) {
  //       setInicialLocation([response.data.latitude, response.data.longitude]);
  //       setSelectedPosition([response.data.latitude, response.data.longitude]);
  //     }

  //     setName(response.data.name);
  //     setInstagram(response.data.instagram);
  //     setLocationLimitedCheck(response.data.is_location_limited);
  //     setNfceCheck(response.data.has_nfce);
  //     setRestHasIfood(response.data.has_ifood);
  //     setOwnerWpp(response.data.owner_wpp);
  //     setHasNickname(response.data.nickname ? true : false);
  //     setNickname(response.data.nickname);
  //     // setNfceToken(response.data.nfce_token);
  //     setClubeCheck(response.data.has_clube);
  //     setIfoodRestaurants(response.data.ifood_restaurants);
  //     setIsActive(response.data.is_active);
  //     setSuspensionAlert(response.data.suspension_alert);
  //     setHasBalcony(response.data.settings.has_balcony);
  //     setUseSmartPos(response.data.settings.use_smart_pos);
  //     setUseTablet(response.data.settings.use_tablet);

  //     setHasStonePos(response.data.has_stone_pos);
  //     setHasStonePdv(response.data.settings.has_stone_pdv);
  //     setSkStone(response.data.sk_stone);

  //     setIfoodPdv(response.data.settings.use_ifood_pdv_code);

  //     if (response.data?.settings?.plan) {
  //       setPlan({
  //         label: response.data?.settings?.plan,
  //         value: response.data?.settings?.plan,
  //       });
  //     }

  //     if (!!response.data.only_delivery === true) {
  //       setOnlyQrcodeDisabled(true);
  //     }

  //     setOnlyDelivery(!!response.data.only_delivery);
  //     setOnlyQrcode(response.data.only_qrcode);
  //     setUseStock(!!response.data.use_stock);
  //     setUseWpp(!!response.data.use_wpp);
  //     setIsPixAllowed(!!response.data.has_pix);
  //     setIsCreditCardAllowed(!!response.data.has_credit_card);
  //     setTableLimitType(response.data.table_limit_type);
  //     setTableLimitValue(
  //       response.data.table_limit ? parseInt(response.data.table_limit) : null
  //     );

  //     switch (response.data.table_limit_type) {
  //       case "only-balcony":
  //         setTableLimitTypeDefault({
  //           value: response.data.table_limit_type,
  //           label: "Somente balcão",
  //         });
  //         setIsTableLimitDisabled(true);
  //         break;
  //       case "limited-tables":
  //         setTableLimitTypeDefault({
  //           value: "limited-tables",
  //           label: "Limitar mesas",
  //         });
  //         setIsTableLimitDisabled(false);
  //         break;
  //       case "unlimited-tables":
  //         setTableLimitTypeDefault({
  //           value: "unlimited-tables",
  //           label: "Mesas ilimitadas",
  //         });
  //         setIsTableLimitDisabled(true);
  //         break;
  //       default:
  //         break;
  //     }

  //     const delivery_info = response.data.delivery_info || {};

  //     setIsDeliveryAllowed(!!delivery_info.is_delivery_allowed);
  //     setIsWithdrawalAllowed(!!delivery_info.is_withdrawal_allowed);
  //     setIsDeliveryAddressesAllowed(!!delivery_info.allow_delivery_addresses);

  //     setSmsService(!!delivery_info.has_sms_service);
  //     setClientPaySms(!!delivery_info.client_pay_sms);
  //     setPasswordPanel(!!delivery_info.is_sms_service_optional);

  //     //asaas
  //     if (!response.data.asaas_id) {
  //       try {
  //         const responseAsaas = await apiGd.get(
  //           `admin/asaas-customers`,
  //           reqConfig
  //         );

  //         setAsaasRestaurants(responseAsaas.data?.response_array);

  //         let asaasDefault = [{ label: "Selecione..", value: null }];
  //         // if(response.data.asaas_id){
  //         //   asaasDefault = responseAsaas.data?.response_array.find(
  //         //     (rest) => rest.value === response.data.asaas_id
  //         //   );
  //         // }

  //         setAsaasDefault(asaasDefault);
  //         // setAsaasId(asaasDefault?.value);
  //       } catch (err) {
  //         notify("tr", "danger", "Erro ao carregar informações Asaas.");
  //       }
  //     }
  //   } catch (err) {
  //     notify("tr", "danger", "Erro ao carregar informações.");
  //   }
  // }, [id, token]);

  const getInfo = useCallback(async () => {
      const reqConfig = {
        headers: { Authorization: `Bearer ${token}` },
      };
    
      try {
        const response = await apiGd.get(`admin/restaurants/${id}`, reqConfig);
        const restaurantData = {
          adress: response.data.adress,
          asaas_id: response.data.asaas_id,
          avatar_id: response.data.avatar ? response.data.avatar.id : null,
          city: response.data.city,
          cnpj: response.data.cnpj || "",
          delivery_info: {
            is_delivery_allowed: response.data.delivery_info.is_delivery_allowed,
            allow_delivery_addresses: response.data.delivery_info.allow_delivery_addresses,
            sms_service_price: response.data.delivery_info.sms_service_price,
            is_sms_service_optional: response.data.delivery_info.is_sms_service_optional,
            has_sms_service: response.data.delivery_info.has_sms_service,
            client_pay_sms: response.data.delivery_info.client_pay_sms,
            is_withdrawal_allowed: response.data.delivery_info.is_withdrawal_allowed,
          },
          email: response.data.email,
          excel_file: response.data.excel_file,
          fantasy_name: response.data.fantasy_name,
          geolocation_cb: response.data.geolocation_cb,
          has_balcony: response.data.has_balcony,
          has_clube: response.data.has_clube,
          has_credit_card: response.data.has_credit_card,
          has_ifood: response.data.has_ifood,
          has_nfce: response.data.has_nfce,
          has_nickname: response.data.has_nickname,
          has_pix: response.data.has_pix,
          has_stone_pos: response.data.has_stone_pos,
          has_stone_pdv: response.data.has_stone_pdv,
          ifood_merchant_id: response.data.ifood_merchant_id,
          ifood_name: response.data.ifood_name,
          instagram: response.data.instagram,
          is_active: response.data.is_active,
          is_location_limited: response.data.is_location_limited,
          latitude: response.data.latitude,
          longitude: response.data.longitude,
          model_clube: response.data.model_clube,
          name: response.data.name,
          nickname: response.data.nickname,
          nfce_token: response.data.nfce_token || "",
          number: response.data.number,
          only_delivery: response.data.only_delivery,
          only_qrcode: response.data.only_qrcode,
          owner_wpp: response.data.owner_wpp,
          paytime_seller_id: response.data.paytime_seller_id,
          phone: response.data.phone,
          regime_tributario: response.data.regime_tributario,
          settings: response.data.settings,
          sk_stone: response.data.sk_stone,
          state: response.data.state,
          street: response.data.street,
          suspension_alert: response.data.suspension_alert,
          table_limit: response.data.table_limit,
          table_limit_type: response.data.table_limit_type,
          token_clube: response.data.token_clube,
          type_of_plan: response.data.type_of_plan,
          use_ifood_pdv_code: response.data.use_ifood_pdv_code,
          use_smart_pos: response.data.use_smart_pos,
          use_stock: response.data.use_stock,
          use_tablet: response.data.use_tablet,
          use_wpp: response.data.use_wpp,
          wpp_report_active: response.data.settings.wpp_report_active,
          zip_code: response.data.zip_code,
          responsible_user_id: response.data.responsible_user_id
        }    

      
        setRestaurantData(restaurantData);
        setRestaurant(response.data);
  
        setResponsibleUser(response.data.responsible_user_id || null);
  
        if (!!response.data.longitude && !!response.data.latitude) {
          setInicialLocation([response.data.latitude, response.data.longitude]);
          setSelectedPosition([response.data.latitude, response.data.longitude]);
        }
  
        setName(response.data.name);
        setInstagram(response.data.instagram);
        setLocationLimitedCheck(response.data.is_location_limited);
        setNfceCheck(response.data.has_nfce);
        setRestHasIfood(response.data.has_ifood);
        setOwnerWpp(response.data.owner_wpp);
        setHasNickname(response.data.nickname ? true : false);
        setNickname(response.data.nickname);
        // setNfceToken(response.data.nfce_token);
        setClubeCheck(response.data.has_clube);
        setIfoodRestaurants(response.data.ifood_restaurants);
        setIsActive(response.data.is_active);
        setSuspensionAlert(response.data.suspension_alert);
        setHasBalcony(response.data.settings.has_balcony);
        setUseSmartPos(response.data.settings.use_smart_pos);
        setUseTablet(response.data.settings.use_tablet);
  
        setHasStonePos(response.data.has_stone_pos);
        setHasStonePdv(response.data.settings.has_stone_pdv);
        setSkStone(response.data.sk_stone);
  
        setIfoodPdv(response.data.settings.use_ifood_pdv_code);
  
        if (response.data?.settings?.plan) {
          setPlan({
            label: response.data?.settings?.plan,
            value: response.data?.settings?.plan,
          });
        }
  
        if (!!response.data.only_delivery === true) {
          setOnlyQrcodeDisabled(true);
        }
  
        setOnlyDelivery(!!response.data.only_delivery);
        setOnlyQrcode(response.data.only_qrcode);
        setUseStock(!!response.data.use_stock);
        setUseWpp(!!response.data.use_wpp);
        setIsPixAllowed(!!response.data.has_pix);
        setIsCreditCardAllowed(!!response.data.has_credit_card);
        setTableLimitType(response.data.table_limit_type);
        setTableLimitValue(
          response.data.table_limit ? parseInt(response.data.table_limit) : null
        );
  
        switch (response.data.table_limit_type) {
          case "only-balcony":
            setTableLimitTypeDefault({
              value: response.data.table_limit_type,
              label: "Somente balcão",
            });
            setIsTableLimitDisabled(true);
            break;
          case "limited-tables":
            setTableLimitTypeDefault({
              value: "limited-tables",
              label: "Limitar mesas",
            });
            setIsTableLimitDisabled(false);
            break;
          case "unlimited-tables":
            setTableLimitTypeDefault({
              value: "unlimited-tables",
              label: "Mesas ilimitadas",
            });
            setIsTableLimitDisabled(true);
            break;
          default:
            break;
        }
  
        const delivery_info = response.data.delivery_info || {};
  
        setIsDeliveryAllowed(!!delivery_info.is_delivery_allowed);
        setIsWithdrawalAllowed(!!delivery_info.is_withdrawal_allowed);
        setIsDeliveryAddressesAllowed(!!delivery_info.allow_delivery_addresses);
  
        setSmsService(!!delivery_info.has_sms_service);
        setClientPaySms(!!delivery_info.client_pay_sms);
        setPasswordPanel(!!delivery_info.is_sms_service_optional);
  
        //asaas
        if (!response.data.asaas_id) {
          try {
            const responseAsaas = await apiGd.get(
              `admin/asaas-customers`,
              reqConfig
            );
  
            setAsaasRestaurants(responseAsaas.data?.response_array);
  
            let asaasDefault = [{ label: "Selecione..", value: null }];
            // if(response.data.asaas_id){
            //   asaasDefault = responseAsaas.data?.response_array.find(
            //     (rest) => rest.value === response.data.asaas_id
            //   );
            // }
  
            setAsaasDefault(asaasDefault);
            // setAsaasId(asaasDefault?.value);
          } catch (err) {
            notify("tr", "danger", "Erro ao carregar informações Asaas.");
          }
        }
      } catch (err) {
        notify("tr", "danger", "Erro ao carregar informações.");
        console.log(err)
      
      }
    }, [id, token]);

  const [loadingAsaas, setLoadingAsaas] = useState(false);

  const getAsaas = useCallback(async () => {
    setLoadingAsaas(true);
    try {
      const reqConfig = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const responseAsaas = await apiGd.get(`admin/asaas-customers`, reqConfig);

      setAsaasRestaurants(responseAsaas.data?.response_array);

      let asaasDefault = [{ label: "Selecione..", value: null }];
      if (restaurant.asaas_id) {
        asaasDefault = responseAsaas.data?.response_array.find(
          (rest) => rest.value === restaurant.asaas_id
        );
      }

      setAsaasDefault(asaasDefault);
      setAsaasId(asaasDefault?.value);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações Asaas.");
    }
    setLoadingAsaas(false);
  }, [restaurant, token]);

  const deleteMenu = useCallback(async () => {
    if (window.confirm("Tem certeza que deseja deletar esse cardapio?")) {
      const reqConfig = {
        headers: { Authorization: `Bearer ${token}` },
      };

      try {
        await apiGd.put(
          `admin/restaurants/menu/delete`,
          {
            restaurant_id: id,
          },
          reqConfig
        );

        notify("tr", "success", "Cardápio apagado.");
      } catch (err) {
        notify("tr", "danger", "Erro ao carregar informações Asaas.");
      }
    }
  }, [token, id]);

  const handleDownloadSheet = useCallback(async () => {
    try {
      const response = await apiGd.get( `/admin/products-fiscal/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const { products, complements } = response.data;

      const sheet = await generateProductsSheet(
        products,
        complements
      );

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(blob, `Fiscal de produtos.xlsx`);

      notify("tr", "success", "Sucesso");
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informções de produtos.");
    }
  }, [token, id]);

  const submitDeleteClube = async () => {
    if (confirmDelete === "DELETAR") {
      try {
        apiGd.delete(
          `/restaurants/clube/delete/${id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        notify("tr", "success", "Clube deletado com sucesso.");
        setDeleteClubeModal(false);
        setClubeCheck(false);
      } catch (err) {
        notify("tr", "danger", "Erro ao deletar clube.");
      }
    } else {
      notify("tr", "danger", "Digite DELETAR para confirmar.");
    }
  };

  const createTablePos = useCallback(async () => {
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      await apiGd.post(`admin/tables/pos/${id}`, null, reqConfig);

      notify("tr", "success", "Mesa criada.");
    } catch (err) {
      notify("tr", "danger", "Erro ao criar mesa. Tente novamente");
    }
  }, [token, id]);

  const handleAddIfoodId = async () => {
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    if (merchantIfood === "" || nameIfood === "") {
      notify("tr", "danger", "Preencha nome e merchant id do ifood");
    } else {
      try {
        const data = {
          restaurant_id: id,
          name: nameIfood,
          merchant_id: merchantIfood,
        };
        const response = await apiGd.post(
          `admin/restaurants/ifood/merchant`,
          data,
          reqConfig
        );
        setNameIfood("");
        setMerchantIfood("");
        setIfoodRestaurants((state) => [...state, response.data]);
        notify("tr", "success", "Inserido com sucesso");
      } catch (err) {
        notify("tr", "danger", "Erro ao inserir.");
      }
    }
  };

  const handleDeleteIfoodId = async (ifood_merchant_id) => {
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        restaurant_id: id,
        merchant_id: ifood_merchant_id,
      },
    };

    try {
      await apiGd.delete(`admin/restaurants/ifood/merchant`, reqConfig);
      setIfoodRestaurants((state) =>
        state.filter((rest) => rest.ifood_merchant_id !== ifood_merchant_id)
      );
      notify("tr", "success", "Deletado com sucesso");
    } catch (err) {
      notify("tr", "danger", "Erro ao remover.");
    }
  };

  async function updateProfile(data) {
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    if (data.delivery_info.is_delivery_allowed || data.only_delivery) {
      data.delivery_info.is_withdrawal_allowed = true;
    } else {
      data.delivery_info.is_withdrawal_allowed = false;
    }

    try {
         
      data.asaas_id =  asaasId || restaurantData.asaas_id;

      if (data.has_clube) {
        data.model_clube = "cashback";
      } else {
        data.token_clube = null;
      }

      data.name = data.name?.toLowerCase();

      if(data.nickname){
        data.nickname = data.nickname?.toLowerCase();
      }

      if (!hasNickname) {
        data.nickname = null;
      }

      let table_limit_type = tableLimitType;
      let table_limit = tableLimitValue;

      if (tableLimitType === "limited-tables-10") {
        table_limit_type = "limited-tables";
        table_limit = 10;
      } else if (tableLimitType === "limited-tables-30") {
        table_limit_type = "limited-tables";
        table_limit = 30;
      } else if (tableLimitType === "limited-tables") {
        if (!table_limit || table_limit === "") {
          notify("tr", "danger", "Limite o numero de mesas.");
          return false;
        }
      }

      data.table_limit_type = table_limit_type || tableLimitType;
      data.table_limit = table_limit
        ? parseInt(table_limit)
        : tableLimitValue
        ? parseInt(tableLimitValue)
        : null;

      if(data.has_pix === false && data.has_credit_card === false){
        data.paytime_seller_id = null;
      }

      if(data.cnpj){
        data.cnpj = data.cnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-','');
      }

      data.onlyQrcode = !data.onlyQrcode

      const filteredData = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value !== null && value !== undefined)
      );

      console.log(filteredData)
    
      const response = await apiGd.put(
        `admin/restaurants/${id}`,
        filteredData,
        reqConfig
      );
      setRestaurant(response.data);
      setLatitude(response.data.latitude);

      console.log(data)
      // getRestaurants();
      notify("tr", "success", "Atualizado com sucesso!");
    } catch (err) {
      notify(
        "tr",
        "danger",
        "Erro ao atualizar."
      );
      console.log(err.message);
    }
  }

  async function deleteRestaurant() {
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      await apiGd.delete(`admin/restaurants/${id}`, reqConfig);

      toggleModal();
      getRestaurants();
      history.goBack();
    } catch (e) {
      notify("tr", "danger", "Erro ao apagar restaurantes.");
    }
  }

 const changeIsActive = useCallback(async()=> {
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const data = {
        is_active: !restaurantData.is_active,
      };

      const response = await apiGd.put(
        `admin/restaurants/${id}`,
        data,
        reqConfig
      );

      setRestaurantData({
        ...restaurantData,
        is_active: !restaurantData.is_active,       
      
      });
     
    } catch (e) {
      notify("tr", "danger", "Erro ao ativar/desativar restaurantes.");
    }
  },[restaurantData])

 const changeWppReport = useCallback(async()=> {
    const reqConfig = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const data = {
       settings: {
        ...restaurantData.settings,
        wpp_report_active: !restaurantData.settings.wpp_report_active
       }
      };

      await apiGd.put(
        `admin/restaurants/${id}`,
        data,
        reqConfig
      );

        setRestaurantData({
          ...restaurantData,
          settings:{
            ...restaurantData.settings,
            wpp_report_active: !restaurantData.settings.wpp_report_active,
          }
        
        });

        notify("tr", "success", "Atualizado com sucesso.");
    } catch (e) {
      notify("tr", "danger", "Erro ao ativar/desativar relatório via whatsapp.");
    }
  },[restaurantData])

  let initialData = {
    fantasy_name: restaurant.fantasy_name,
    name: restaurant.name,
    nickname: restaurant.nickname,
    email: restaurant.email,
    phone: restaurant.phone,
    avatar_id: restaurant.avatar,
    longitude: restaurant.longitude,
    latitude: restaurant.latitude,
    is_location_limited: restaurant.is_location_limited,
    nfce_token: restaurant.nfce_token,
    cnpj: restaurant.cnpj,
    token_clube: restaurant.token_clube,
    paytime_seller_id: restaurant.paytime_seller_id,
    delivery_info: restaurant.delivery_info || {},
    ifood_merchant_uuid: restaurant.ifood_merchant_uuid,
    instagram: restaurant.instagram,
    city: restaurant.adress?.city,
    neighborhood: restaurant.adress?.neighborhood,
    state: restaurant.adress?.state,
    street: restaurant.adress?.street,
    zip_code: restaurant.adress?.zip_code,
    number: restaurant.adress?.number < 0 ? "" : restaurant.adress?.number,
    use_wpp: restaurant.use_wpp,
    regime_tributario: restaurant.regime_tributario,
    responsible_user_id: restaurant.responsible_user_id || null,
    sk_stone: restaurant.sk_stone
  };

  const [zipCodeState, setZipCodeState] = useState(restaurant.adress?.zip_code);

  function handleMapClick(event) {
    setSelectedPosition([event.latlng.lat, event.latlng.lng]);
    setLatitude(event.latlng.lat);
    setLongitude(event.latlng.lng);
    document.getElementById("latitude").value = event.latlng.lat;
    document.getElementById("longitude").value = event.latlng.lng;
  }

  const [latitudeHere, setLatitudeHere] = useState(0);
  const [longitudeHere, setLongitudeHere] = useState(0);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitudeHere(position.coords.latitude);
      setLongitudeHere(position.coords.longitude);
    });
    getInfo();
    getUsers();
  }, [getInfo, getUsers]);

  const [latitude, setLatitude] = useState(initialData.latitude);
  const [longitude, setLongitude] = useState(initialData.longitude);
  const [asaasDefault, setAsaasDefault] = useState(null);

  function searchLocation() {
    if (latitude !== undefined && longitude !== undefined) {
      setInicialLocation([latitude, longitude]);
      setSelectedPosition([latitude, longitude]);
    } else {
      setInicialLocation([0, 0]);
      setSelectedPosition([0, 0]);
      document.getElementById("latitude").value = 0;
      document.getElementById("longitude").value = 0;
    }
  }

  function locationHere() {
    document.getElementById("latitude").value = latitudeHere;
    document.getElementById("longitude").value = longitudeHere;
    setInicialLocation([latitudeHere, longitudeHere]);
    setSelectedPosition([latitudeHere, longitudeHere]);
    setLatitude(latitudeHere);
    setLongitude(longitudeHere);
  }

  const [excelFile, setExcelFile] = useState(null);

  const sendExcel = useCallback(
    async (data) => {
      try {
        if (excelFile) {
          const formData = new FormData();
          formData.append("file", excelFile, excelFile.name);
          const reqConfig = {
            headers: { Authorization: `Bearer ${token}` },
          };

          await apiGd.post(
            `/restaurants/excel-files/${id}`,
            formData,
            reqConfig
          );

          notify("tr", "success", "Cardápio atualizado com sucesso!");
        } else {
          notify("tr", "danger", "Selecione um arquivo antes!");
        }
      } catch (error) {
        console.log(error);
        notify(
          "tr",
          "danger",
          "Erro ao atualizar cardapio! Confira as informacoes no excel"
        );
      }
    },
    [excelFile, id, token]
  );

function handleSetFile(e) {
  const file = e.target.files[0];
  setExcelFile(file);
}

const [inputSheet, setInputSheet] = useState(null);
const [prodInputSheet, setProdInputSheet] = useState(null); // Ficha Técnica  

const sendInputsExcel = useCallback(
    async (data) => {
      try {
        if (inputSheet) {
          const formData = new FormData();
          formData.append("file", inputSheet, inputSheet.name);

          const reqConfig = {
            headers: { Authorization: `Bearer ${token}` },
          };

          await apiGd.post(
            `/admin/upload/inputs/${id}?version=2`,
            formData,
            reqConfig
          );

          notify("tr", "success", "Insumos criados com sucesso!");
        } else {
          notify("tr", "danger", "Selecione um arquivo antes!");
        }
      } catch (error) {
        notify(
          "tr",
          "danger",
          "Erro ao atualizar cardapio! Confira as informacoes no excel"
        );
      }
    },
    [inputSheet, id, token]
  );

  // Envio de ficha técnica
  const sendProductInputsExcel = useCallback(
    async (data) => {
      try {
        if (prodInputSheet) {
          const formData = new FormData();
          formData.append("file", prodInputSheet, prodInputSheet.name);

          const reqConfig = {
            headers: { Authorization: `Bearer ${token}` },
          };

          await apiGd.post(
            `/admin/upload/product-inputs-sheet/${id}`,
            formData,
            reqConfig
          );

          notify("tr", "success", "Ficha técnica atualizada com sucesso!");
        } else {
          notify("tr", "danger", "Selecione um arquivo antes!");
        }
      } catch (error) {
        notify(
          "tr",
          "danger",
          "Erro ao atualizar cardapio! Confira as informacoes no excel"
        );
      }
    },
    [prodInputSheet, id, token]
  );



  const tableOptions = [
    { value: "only-balcony", label: "Somente balcão" },
    { value: "limited-tables-10", label: "Limite de 10 mesas" },
    { value: "limited-tables-30", label: "Limite de 30 mesas" },
    { value: "limited-tables", label: "Limitar mesas" },
    { value: "unlimited-tables", label: "Mesas ilimitadas" },
  ];

  const regimeTributarioOptions = [
    { value: "1", label: "Simples Nacional" },
    { value: "3", label: "Regime Normal" },
  ];

  useEffect(() => {
    switch (tableLimitType) {
      case "unlimited-tables":
        setTableLimitValue("");
        break;
      case "limited-tables-10":
        setTableLimitValue(10);
        break;
      case "limited-tables-30":
        setTableLimitValue(30);
        break;
      case "limited-tables":
        setTableLimitValue("");
        break;
      case "only-balcony":
        setTableLimitValue("");
        break;
    }
  }, [tableLimitType]);

  const [deletedMenu, setDeletedMenu] = useState([]);
  const [loadingDeletedMenu, setLoadingDeletedMenu] = useState([]);

  async function getDeletedMenu(e) {
    setLoadingDeletedMenu(true);
    try {
      const reqConfig = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await apiGd.get(
        `/admin/restaurants/menu/deleted/${id}`,
        reqConfig
      );

      setDeletedMenu(response.data);
    } catch (error) {
      console.log(error);
      notify("tr", "danger", "Erro ao atualizar cardapio deletado");
    }
    setLoadingDeletedMenu(false);
  }

  function changeUseWpp() {
    setUseWpp(!useWpp);
  }

  const typeOfPlansOptions = [
    { value: "BÁSICO", label: "BÁSICO" },
    { value: "INTERMEDIÁRIO", label: "INTERMEDIÁRIO" },
    { value: "INOVAÇÃO", label: "INOVAÇÃO" },
    { value: "PRO", label: "PRO" },
    { value: "APENAS CARDÁPIO", label: "APENAS CARDÁPIO" },
  ];

  function handleChangeTypeOfPlans(e) {
    setPlan({
      label: e,
      value: e,
    });

    if (e === "APENAS CARDÁPIO") {
      setRestaurantData(old => {
        return {
          ...old,
          only_qrcode: true,
          only_delivery: false,
          has_pix: false,
          has_credit_card: false,
          has_clube: false,
          has_nfce: false,
          has_balcony: false,
          use_wpp: false,
          delivery_info:{
            ...old.delivery_info,
            allow_delivery_addresses: false,
            is_delivery_allowed: false,
          }
        }
      })
    }

    if (e === "BÁSICO") {
      setRestaurantData(old => {
        return {
          ...old,
          only_qrcode: true,
          only_delivery: false,
          has_pix: false,
          has_credit_card: false,
          has_clube: false,
          has_nfce: false,
          has_balcony: false,
          use_wpp: false,
          delivery_info:{
            ...old.delivery_info,
            allow_delivery_addresses: false,
            is_delivery_allowed: true,
          }
        }
      })
    }

    if (e === "INTERMEDIÁRIO") {
      setRestaurantData(old => {
        return {
          ...old,
          only_qrcode: true,
          only_delivery: false,
          has_pix: false,
          has_credit_card: false,
          has_clube: false,
          has_nfce: false,
          has_balcony: true,
          use_wpp: false,
          delivery_info:{
            ...old.delivery_info,
            is_delivery_allowed: true,
          }
        }
      })
    }

    if (e === "INOVAÇÃO") {
      setRestaurantData(old => {
        return {
          ...old,
          only_qrcode: false,
          only_delivery: false,
          has_pix: false,
          has_credit_card: false,
          has_clube: false,
          has_nfce: false,
          has_balcony: true,
          use_wpp: false,
          delivery_info:{
            ...old.delivery_info,
            is_delivery_allowed: true,
          }
        }
      })
    }

    if (e === "PRO") {
      setRestaurantData(old => {
        return {
          ...old,
          only_qrcode: false,
          only_delivery: false,
          has_pix: false,
          has_credit_card: false,
          has_clube: true,
          has_nfce: true,
          has_balcony: true,
          use_wpp: true,
          delivery_info:{
            ...old?.delivery_info,
            allow_delivery_addresses: false,
            is_delivery_allowed: true,
          }
        }
      })
    }
  }

  function validateWppReport(mrr, expires){
    const mr = parseFloat(mrr || 0);
    let toReturn = '';

    if(mr >= 395){
      toReturn = 'Ilimitado';
    }else{
      if(expires){
        toReturn = format(new Date(expires), 'dd/MM/yyyy - HH:mm');
      }else{
        toReturn = 'Primeiro relatório ainda não enviado. Validade de 21 dias após a primeira mensagem.'
      }
    }

    return toReturn;
  }

  
  return (
    <>
      <NotificationAlert ref={notificationAlert} />

      <div className="content">
        <Row style={{ marginBottom: 20 }}>
          <Col md="12">
            <ButtonTab
              thisButton={1}
              activeButton={buttonTab}
              onClick={() => setButtonTab(1)}
            >
              Informações Gerais
            </ButtonTab>
            <ButtonTab
              thisButton={2}
              activeButton={buttonTab}
              onClick={() => {
                setButtonTab(2);
                getDeletedMenu();
              }}
            >
              Recuperar Cardápio
            </ButtonTab>
          </Col>
        </Row>

        {buttonTab === 1 && (
          <>
            <Row>
              <Col md="3">
                <Button color="#7A7A7A" onClick={() => history.goBack()}>
                  <span className="btn-label">
                    <i className="nc-icon nc-minimal-left" />
                  </span>
                  Voltar
                </Button>
              </Col>

              <Col>
                <Button
                  onClick={toggleModal}
                  style={{ float: "right",backgroundColor:"#C8131B",borderRadius:8 }}
                >
                  Apagar
                </Button>
                <Button
                  onClick={deleteMenu}
                  style={{
                    float: "right",
                    marginRight: 10,
                    backgroundColor:"#F3F3F3",
                    color:"#C8131B",
                    border: "1px solid #C8131B",
                    borderRadius:8
                    //background: "#95340E",
                  }}

                >
                  Apagar Cardápio
                </Button>
                <Button
                  onClick={toggleModalPassword}
                  style={{ float: "right", marginRight: 10,backgroundColor:"#FFB32F",borderRadius:8 }}
                >
                  Alterar Senha
                </Button>
                <Button
                  onClick={handleDownloadSheet}
                  style={{
                    float: "right",
                    marginRight: 10,
                    background: "#01AFFF",
                    borderRadius:8
                  }}
                >
                  Planilha Fiscal
                </Button>
              </Col>
            </Row>
            <Form
              ref={formRef}
              initialData={initialData}
              onSubmit={updateProfile}
            >
              <Row>
                <Col md="12">
                  <Card>
                    <EditProfile 
                      handleSetFile={handleSetFile}
                      sendExcel={sendExcel}
                      sendInputsExcel={sendInputsExcel}
                      sendProductInputsExcel={sendProductInputsExcel}
                      restaurantData={restaurantData}
                      setRestaurantData={setRestaurantData}
                      setInputSheet={setInputSheet}
                      setProdInputSheet={setProdInputSheet}
                      token={token}
                      id={id}
                      name={name}
                      users={users}
                      hasNickname={hasNickname}
                      setHasNickname={setHasNickname}
                    />
                  </Card>

                  <EditArea
                    user={user}
                    changeIsActive={changeIsActive}
                    changeWppReport={changeWppReport}
                    isActive={isActive}
                    restaurantData={restaurantData}
                    setRestaurantData={setRestaurantData}
                    createTablePos={createTablePos}
                    getAsaas={getAsaas}
                    asaasRestaurants={asaasRestaurants}
                    asaasDefault={asaasDefault}
                    setAsaasId={setAsaasId}
                    notify={notify}
                    id={id}
                  />

                  <Card>
                    <EditLocation
                    restaurantData={restaurantData}
                    setRestaurantData={setRestaurantData}
                    />
                  </Card>

                  <Card>
                    <EditPlan
                      restaurantData={restaurantData}
                      setRestaurantData={setRestaurantData}
                      handleChangeTypeOfPlans={handleChangeTypeOfPlans}
                    />
                  </Card>

                  <Card>
                    <EditNFE
                      restaurantData={restaurantData}
                      setRestaurantData={setRestaurantData}
                      setDeleteClubeModal={setDeleteClubeModal}
                      deleteClubeModal={deleteClubeModal}
                    />
                  </Card>

                  <Card>
                    <EditPayments
                      restaurantData={restaurantData}
                      setRestaurantData={setRestaurantData}
                      ifoodRestaurants={ifoodRestaurants}
                      setIfoodRestaurants={setIfoodRestaurants}
                      updateProfile={updateProfile}
                      id={id}
                      token={token}
                      notify={notify}
                      tableLimitTypeDefault={tableLimitTypeDefault}
                      setTableLimitType={setTableLimitType}
                      setIsTableLimitDisabled={setIsTableLimitDisabled}
                      setTableLimitTypeDefault={setTableLimitTypeDefault}
                      tableLimitValue={tableLimitValue}
                      isTableLimitDisabled={isTableLimitDisabled}
                      setTableLimitValue={setTableLimitValue}
                    />
                  </Card>

                </Col>
              </Row>
            </Form>

            <Modal isOpen={modal} toggle={toggleModal}>
              <ModalHeader> Apagar Restaurante </ModalHeader>
              <ModalBody>
                <Container>
                  Deseja realmente apagar o restaurante{" "}
                  <b>{initialData.fantasy_name}</b>?
                  <p>
                    {" "}
                    Ao confirmar, você estará removendo este restaurante do
                    sistema, assim como todos os{" "}
                    <i style={{ color: "yellow" }}>
                      pedidos, produtos, mesas, garçons e etc
                    </i>
                    , que estejam associados a ele.{" "}
                  </p>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={deleteRestaurant}>
                  Confirmar
                </Button>{" "}
                <Button color="danger" onClick={toggleModal}>
                  Cancelar
                </Button>
              </ModalFooter>
            </Modal>

            <Modal isOpen={modalPassword} toggle={toggleModalPassword}>
              <ModalHeader> Alterar senha </ModalHeader>
              <ModalBody>
                <Form>
                  <Container>
                    <Row>
                      <Col md="12">
                        <label> Nova Senha </label>
                        <Input
                          name="password"
                          type="text"
                          placeholder="Mínimo 6 caracteres"
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Container>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={updatePassword}>
                  Confirmar
                </Button>{" "}
                <Button color="danger" onClick={toggleModalPassword}>
                  Cancelar
                </Button>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={deleteClubeModal}
              toggle={() => setDeleteClubeModal(!deleteClubeModal)}
            >
              <ModalHeader>Deletar clube</ModalHeader>
              <ModalBody>
                <Container>
                  Deseja realmente deletar o clube do restaurante{" "}
                  <b>{initialData.fantasy_name}</b>?
                  <p> Para confirmar digite "DELETAR" e clique no botão.</p>
                </Container>
              </ModalBody>
              <ModalFooter>
                <Form
                  onSubmit={submitDeleteClube}
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Input
                    name="confirm_delete"
                    value={confirmDelete}
                    onChange={(e) => setConfirmDelete(e.target.value)}
                  />
                  <Button color="danger" type="submit">
                    Deletar
                  </Button>
                </Form>
              </ModalFooter>
            </Modal>
          </>
        )}

        {buttonTab === 2 && (
          <RecoverMenu
            menu={deletedMenu}
            loading={loadingDeletedMenu}
            getDeletedMenu={getDeletedMenu}
            notify={notify}
          />
        )}
      </div>
    </>
  );
}

export default EditRestaurantGD;
