import React, { Component } from "react";
import { Form } from "@unform/web";
import { toast } from "react-toastify";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Row,
  Col,
  FormText,
} from "reactstrap";

import Input from "../../components/Input";
import AvatarInput from "../../components/AvatarInput";

export default class NewRestaurant extends Component {
  render() {
    // TODO: Verificar por que NewConcierge não é usado
    async function handleSubmitNewRestaurant(data) {
      try {
        // const profile = {
        //   username: data.username,
        //   fantasy_name: data.fantasy_name,
        //   email: data.email,
        //   password: data.password,
        //   phone: data.phone,
        //   open_time: data.open_time,
        //   close_time: data.close_time,
        //   avatar_id: data.avatar,
        // };

        // console.log(data);

        // const response = await api.post("food-courts/restaurants", profile, {
        //   headers: { Authorization: `Bearer ${token}` },
        // });
        alert("Cadastrado");
        toast.success("Perfil do restaurante atualizado com sucesso!");
      } catch (err) {
        alert("Erro");
        toast.error("Falha ao atualizar o perfil do restaurante.");
      }
    }

    return (
      <div className="content">
        <h2>Cadastro de Hotel, Motel e Pousada</h2>
        <Form className="form-horizontal" onSubmit={handleSubmitNewRestaurant}>
          <Row>
            <Col md="4" sm="4">
              <CardTitle tag="h4">Logo</CardTitle>
              <AvatarInput name="avatar" />
            </Col>
            <Col md="8">
              <Card>
                <CardBody>
                  <Row>
                    <Label sm="2">Nome de Exibição</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" name="fantasy_name" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Nome de Usuário</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="text" name="username" />
                        <FormText color="default" tag="span">
                          Esse nome é um identificador único do estabelecimento,
                          deve ter letras minúsculas e sem espaço. Ex:
                          'mcdonalds' / 'bobs' / 'burgerking'
                        </FormText>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row style={{ paddingTop: 15 }}>
                    <Label sm="2">Horário de Abertura</Label>
                    <Col sm="6">
                      <FormGroup>
                        <ReactDatetime
                          dateFormat={false}
                          defaultValue="11:00 AM"
                          inputProps={{
                            className: "form-control",
                          }}
                          name="open_time"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ paddingBottom: 15 }}>
                    <Label sm="2">Horário de Fechamento</Label>
                    <Col sm="6">
                      <FormGroup>
                        <ReactDatetime
                          dateFormat={false}
                          defaultValue="10:00 PM"
                          inputProps={{
                            className: "form-control",
                          }}
                          name="close_time"
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                  <Row>
                    <Label sm="2">Telefone</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="phone" name="phone" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">E-mail</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input type="email" name="email" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Senha</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input
                          type="password"
                          name="password"
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row style={{ paddingTop: 15 }}>
                    <Col md="3" className="ml-auto">
                      <Button color="success" type="submit">
                        Cadastrar
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
