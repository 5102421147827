import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import NotificationAlert from "react-notification-alert";
import api from "../../../services/api";
import { LinkStyled, ShoppingInfos } from "./styles.js";
import history from "../../../services/history";
import { Line, Pie } from "react-chartjs-2";

import { 
  format,
  startOfDay,
  endOfDay,
  subHours,
  getDate,
  getDay,
  startOfWeek,
  isBefore,
  startOfMonth,
  addHours,
  addDays,
  isAfter,
  addMonths,
  lastDayOfMonth,
  startOfYear,
  getMonth
} from 'date-fns';

import {
  ToLine,
  ToPie,
} from "variables/charts.jsx";

import { Form } from "@unform/web";
import { Input } from "@material-ui/core";
import { DateSelectorDiv, DateDiv, SubmitDate } from "./styles";
import formatCompleteDate from "../../../services/formatCompleteDate";
import formatPhoneString from "../../../services/formatPhoneString";

import CardDash from "components/Cards/CardDash";
import CardTable from "components/Cards/CardTable/CardTable";
import CardTopEstablishment from "components/Cards/CardTopEstablishment";
import {ButtonLink} from "components/Cards/styles.js";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Nav, NavItem, NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
  Collapse,
  Modal,
  ModalBody,
  Table,
} from "reactstrap";

function ShoppingDashboard(props) {
  const { id } = props.match.params;
  const notificationAlert = useRef(null);
  const formRef = useRef(null);
  // const [ordersPending, setOrdersPending] = useState([]);
  const [ordersNotPending, setOrdersNotPending] = useState([]);
  const [clients, setClients] = useState([]);
  const [ordersCanceled, setOrdersCanceled] = useState(0);
  const [ordersExpired, setOrdersExpired] = useState(0);
  const [ordersTotal, setOrdersTotal] = useState(0);
  const token = localStorage.getItem("@QRExpress:token");
  const [restaurantsData, setRestaurantsData] = useState([]);
  const [foodCourt, setFoodCourt] = useState([]);

  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  let inicialFormat = format(new Date(defaultInicial), "dd/MM/yyyy, HH:mm");
  let finalFormat = format(new Date(defaultFinal), "dd/MM/yyyy, HH:mm");
  let phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  const [payments, setPayments] = useState([]);

  function setToday(){
    setInicialDate(format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek(){
    setInicialDate(format(startOfWeek(subHours(new Date(), 3), {weekStartsOn: 1}), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfWeek(subHours(new Date(), 3), {weekStartsOn: 1}), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth(){
    setInicialDate(format(startOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfMonth(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  let [initialPre, setInitialPre] = useState(inicialDate);
  let [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    setInicialDate(format(subHours(new Date(initialPre), 3), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(subHours(new Date(finalPre), 3), "yyyy-MM-dd'T'HH:mm:ss"));
    let inicialFormat = format(new Date(initialPre), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(new Date(finalPre), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    
    setHourPhrase(phrase);
  }

  const [inicialDateSearch, setInicialDateSearch] = useState(format(addHours(new Date(inicialDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));
  const [finalDateSearch, setFinalDateSearch] = useState(format(addHours(new Date(finalDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));

  useEffect(() => {
    setInicialDateSearch(format(addHours(new Date(inicialDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDateSearch(format(addHours(new Date(finalDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));  
  }, [inicialDate, finalDate]);


  const getFoodCourt = useCallback(async () => {
    try {
      const response = await api.get(`admin/food-courts/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFoodCourt(response.data);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [id, token]);

  const getPayments = useCallback(async () => {
    try {
      const response = await api.get(`admin/food-courts/${id}/reports/cashier`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          start_date: `${inicialDateSearch}`,
          end_date: `${finalDateSearch}`,
        },
      });
      setPayments(response.data);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [id, token, inicialDateSearch, finalDateSearch]);

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const getOrdersNotPending = useCallback(async () => {
    try {
      const response = await api.get(`admin/food-courts/${id}/orders`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          start_date: `${inicialDateSearch}`,
          end_date: `${finalDateSearch}`,
        },
      });
      let orderNotPending = response.data.filter(
        (order) =>
          order.payment_approved_date !== null &&
          order.order_status !== "pending" &&
          order.order_status !== "canceled" &&
          order.order_status !== "chargeback"
      );
      setOrdersNotPending(orderNotPending);
      console.log(orderNotPending);
      let ordersTotalPrice = 0;

      orderNotPending.forEach((order) => {
        ordersTotalPrice = ordersTotalPrice + parseFloat(order.total_price_with_sms);
      });

      setOrdersTotal(`R$${ordersTotalPrice.toFixed(2)}`);

      let orderCanceled = response.data.filter(
        (order) =>
          order.order_status === "canceled" ||
          order.order_status === "chargeback"
      );
      setOrdersCanceled(orderCanceled.length);

      setOrdersExpired(response.data.filter(
        (order) =>
          order.order_status === "expired"
      ).length);

      let restaurants = [];
      let clients = [];
      let restaurantsDataLocal = [];
      let vetor = orderNotPending;
      let clientsTotal = [];

      for (let i=0; i<vetor.length; i++){
        !restaurants.includes(vetor[i].restaurant.fantasy_name) && restaurants.push(vetor[i].restaurant.fantasy_name);
        !clients.includes(vetor[i].buyer.phone) && clients.push(vetor[i].buyer.phone);
      }

      for (let x=0; x<clients.length; x++){
        let total = 0;
        let count = 0;
        let lastDate = "";
        orderNotPending.forEach((order) => {
          if(order.buyer.phone === clients[x]){
            total = total + parseFloat(order.total_price_with_sms);
            count++;
            lastDate = (lastDate === "" ? order.payment_approved_date : (isAfter(new Date(order.payment_approved_date), new Date(lastDate)) ? order.payment_approved_date : lastDate));
          }
        });

        clientsTotal.push([clients[x], total.toFixed(2), count, (total*100/ordersTotalPrice).toFixed(2), lastDate]);
      }

      function ordenarPorVendasClients(a, b){
        return b[1] - a[1];
      }
      clientsTotal = clientsTotal.sort(ordenarPorVendasClients);
      setClients(clientsTotal);

      for (let j=0; j<restaurants.length; j++){
        let count = 0;
        let totalPrice = 0;
        let url = '';
        let accepted = 0;
        let time_to_done = 0;
        for(let a=0; a<vetor.length; a++){
          if(restaurants[j]===vetor[a].restaurant.fantasy_name){
            count++;
            totalPrice = totalPrice + parseFloat(vetor[a].total_price_with_sms);
            accepted = accepted + vetor[a].time_to_accept;
            time_to_done = time_to_done + vetor[a].time_to_be_done;
            if (url === ''){
              url = vetor[a].restaurant.avatar.url;
            }
          }
        }
        restaurantsDataLocal.push({"amount": count, "fantasy_name": restaurants[j], "time_to_accept_pound": accepted/count, "time_to_be_done_pound": time_to_done/count ,"total_price": totalPrice.toFixed(2), "percent": (totalPrice === 0 ? 0 : (totalPrice * 100/ordersTotalPrice).toFixed(2)), "avatar_url": url}); 
      }

      function ordenarPorVendas(a, b){
        return b.total_price - a.total_price;
      }

      console.log(restaurantsDataLocal.sort(ordenarPorVendas));
      restaurantsDataLocal = restaurantsDataLocal.sort(ordenarPorVendas);
      setRestaurantsData(restaurantsDataLocal);
      console.log('restaurants data local');
      console.log(restaurantsDataLocal);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [id, token, inicialDateSearch, finalDateSearch]);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [modalPayments, setModalPayments] = useState(false);
  const togglePayments = () => setModalPayments(!modalPayments);

  const [modalClients, setModalClients] = useState(false);
  const toggleClients = () => setModalClients(!modalClients);

  const [modalOrders, setModalOrders] = useState(false);
  const toggleOrders = () => setModalOrders(!modalOrders);

  const [chartSelected, setChartSelected] = useState("weekly");

  //gráficos
  const [chartWeekly, setChartWeekly] = useState(ToLine([], []));
  const getChartWeekly = useCallback(async () => {
    try {
      let inicialDate = format(addHours(startOfWeek(subHours(new Date(), 3), {weekStartsOn: 1}), 3), "yyyy-MM-dd'T'HH:mm:ss");
      let finalDate = format(addHours(endOfDay(subHours(new Date(), 3)), 3), "yyyy-MM-dd'T'HH:mm:ss");

      const response = await api.get(`admin/food-courts/${id}/orders`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          start_date: `${inicialDate}`,
          end_date: `${finalDate}`,
        },
      });
      let orderNotPending = response.data.filter(
        (order) =>
          order.payment_approved_date !== null &&
          order.order_status !== "pending" &&
          order.order_status !== "canceled" &&
          order.order_status !== "chargeback"
      );

      let n = getDay(new Date()) === 0 ? 7 : getDay(new Date());

      let data = [];
      for (let i=0; i<n; i++){
        let totalDaily = 0;
        let filterDateInicial = format(addDays(new Date(inicialDate), i), "yyyy-MM-dd'T'HH:mm:ss");
        let filterDateFinal = format(addDays(new Date(inicialDate), i+1), "yyyy-MM-dd'T'HH:mm:ss");
        
        orderNotPending.forEach((order)=> {
          if (isBefore(new Date(order.payment_approved_date), new Date(filterDateFinal)) && isAfter(new Date(order.payment_approved_date), new Date(filterDateInicial))){
            totalDaily = totalDaily + parseFloat(order.total_price_with_sms);
          }
        });
        data.push(totalDaily.toFixed(2));
      }
      console.log("dataaa");
      console.log(data);
      setChartWeekly(ToLine("Arrecadado (R$)", ["Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado", "Domingo"], data, "#17a2b8"));
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [id, token]);

  const [chartMonthly, setChartMontly] = useState(ToLine([], []));
  const getChartMonthly = useCallback(async () => {
    try {
      let inicialDate = format(addHours(startOfMonth(subHours(new Date(), 3)), 3), "yyyy-MM-dd'T'HH:mm:ss");
      let finalDate = format(addHours(endOfDay(subHours(new Date(), 3)), 3), "yyyy-MM-dd'T'HH:mm:ss");

      const response = await api.get(`admin/food-courts/${id}/orders`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          start_date: `${inicialDate}`,
          end_date: `${finalDate}`,
        },
      });
      let orderNotPending = response.data.filter(
        (order) =>
          order.payment_approved_date !== null &&
          order.order_status !== "pending" &&
          order.order_status !== "canceled" &&
          order.order_status !== "chargeback"
      );

      let data = [];
      let label = [];
      for (let i = 0; i < getDate(new Date()); i++){
        let totalDaily = 0;
        let filterDateInicial = format(addDays(new Date(inicialDate), i), "yyyy-MM-dd'T'HH:mm:ss");
        let filterDateFinal = format(addDays(new Date(inicialDate), i+1), "yyyy-MM-dd'T'HH:mm:ss");
        
        orderNotPending.forEach((order)=> {
          if (isBefore(new Date(order.payment_approved_date), new Date(filterDateFinal)) && isAfter(new Date(order.payment_approved_date), new Date(filterDateInicial))){
            totalDaily = totalDaily + parseFloat(order.total_price_with_sms);
          }
        });
        label.push((i+1).toString());
        data.push(totalDaily.toFixed(2));
      }
      setChartMontly(ToLine("Arrecadado (R$)",label, data, "#ef8157"));
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [id, token]);

  const [chartYearly, setChartYearly] = useState(ToLine([], []));
  const getChartYearly = useCallback(async () => {
    try {
      let inicialDate = format(addHours(startOfYear(subHours(new Date(), 3)), 3), "yyyy-MM-dd'T'HH:mm:ss");
      let finalDate = format(addHours(endOfDay(subHours(new Date(), 3)), 3), "yyyy-MM-dd'T'HH:mm:ss");

      const response = await api.get(`admin/food-courts/${id}/orders`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          start_date: `${inicialDate}`,
          end_date: `${finalDate}`,
        },
      });
      let orderNotPending = response.data.filter(
        (order) =>
          order.payment_approved_date !== null &&
          order.order_status !== "pending" &&
          order.order_status !== "canceled" &&
          order.order_status !== "chargeback"
      );

      let data = [];
      let label = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
      for (let i=0; i<getMonth(new Date()) + 1; i++){
        let totalDaily = 0;
        let filterDateInicial = format(startOfMonth(addMonths(new Date(inicialDate), i)), "yyyy-MM-dd'T'HH:mm:ss");
        let filterDateFinal = format(lastDayOfMonth(addMonths(new Date(inicialDate), i)), "yyyy-MM-dd'T'HH:mm:ss");
        orderNotPending.forEach((order)=> {
          if (isBefore(new Date(order.payment_approved_date), new Date(filterDateFinal)) && isAfter(new Date(order.payment_approved_date), new Date(filterDateInicial))){
            totalDaily = totalDaily + parseFloat(order.total_price_with_sms);
          }
        });
        data.push(totalDaily.toFixed(2));
      }
      setChartYearly(ToLine("Arrecadado (R$)",label, data, "green"));
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [id, token]);

  const [chartPayments, setChartPayments] = useState(ToLine([], []));
  const getChartPayments = useCallback(async () => {
    try {
      let labels = ["Picpay (R$)", "Cartão de Crédito (R$)", "Pagar na Retirada (R$)"];
      let label = "Formas de Pagamento";
      let picpay = payments.total_picPay_payment > 0 ? payments.total_picPay_payment.toFixed(2) : 0;
      let paytime = payments.total_paytime_payment > 0 ? payments.total_paytime_payment.toFixed(2) : 0;
      let withdrawal = payments.total_withdrawal_payment > 0 ? payments.total_withdrawal_payment.toFixed(2) : 0;
      let data = [picpay, paytime, withdrawal];
      let colors = ["#11c76f", "blue", "red"];
      setChartPayments(ToPie(labels, label, data, colors));
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [
    payments.total_withdrawal_payment,
    payments.total_picPay_payment,
    payments.total_paytime_payment,
  ]);

  useEffect(() => {
    getFoodCourt();
    getOrdersNotPending();
    getPayments();
    getChartWeekly();
    getChartMonthly();
    getChartYearly();
    getChartPayments();
  }, [getFoodCourt, getOrdersNotPending, getPayments, getChartWeekly, getChartMonthly, getChartYearly, getChartPayments]);

  let logoFoodcourt = foodCourt.avatar ? foodCourt.avatar.url : "";

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="8">
            <Button color="default" onClick={() => history.goBack()}>
              <span className="btn-label">
                <i className="nc-icon nc-minimal-left" />
              </span>
              Voltar
            </Button>
          </Col>
          <Col md="4">
            <div style={{ float: "right" }}>
              <LinkStyled to={`/qr/shoppings/view/${foodCourt.id}`}>
                <Button className="btn-round" color="info" outline>
                  Ver Estabelecimentos
                </Button>
              </LinkStyled>
            </div>
          </Col>
        </Row>
        <Row md="12">
          <ShoppingInfos>
            {logoFoodcourt !== "" && (
              <Col md="3">
                <img src={logoFoodcourt} alt="..." />
              </Col>
            )}
            <Col md="9">
              <h3>{foodCourt.fantasy_name}</h3>
            </Col>
          </ShoppingInfos>
        </Row>
        <Row>
            <Col md="4"><p>{hourPhrase}</p></Col>
            <Col md="8">
              <div style={{ float: "right" }}>
                <Button className="btn-round mr-auto" onClick={setToday} color="info">
                  Hoje
                </Button>
                <Button className="btn-round mr-auto" onClick={setWeek} color="info">
                  Essa semana
                </Button>
                <Button className="btn-round mr-auto" onClick={setMonth} color="info">
                  Esse mês
                </Button>
                <Button className="btn-round mr-auto" onClick={toggle} color="info">
                  Selecionar Período
                  <b className="caret" style={{marginLeft: "10px"}}/>
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div style={{ float: "right", marginRight: "15px" }}>
                <Collapse isOpen={isOpen}>
                  <Form onSubmit={handleChangeDate} ref={formRef}>
                    <DateSelectorDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Inicial"
                          type="datetime-local"
                          name="initialDate"
                          onChange={(e) => setInitialPre(e.target.value)}
                          defaultValue={defaultInicial}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Final"
                          type="datetime-local"
                          name="finalDate"
                          onChange={(e) => setFinalPre(e.target.value)}
                          defaultValue={defaultFinal}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <div>
                        <SubmitDate onClick="submit">Filtrar</SubmitDate>
                      </div>
                    </DateSelectorDiv>
                  </Form>
                </Collapse>
              </div>
            </Col>
          </Row>
         
          <Row>
          <Col lg="4" md="6" sm="6">
            <CardDash
              title="Arrecadação"
              total={ordersTotal}
              icon="nc-icon nc-money-coins text-success"
              toggle={togglePayments}
            ></CardDash>
          </Col>

          <Modal isOpen={modalPayments} toggle={togglePayments}>
            <ModalBody>
              <h3>Formas de Pagamento</h3>
              <Table>
                <thead>
                  <tr>
                    <th>Forma</th>
                    <th>Arrecadado</th>
                    <th className="text-right">Porcentagem</th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                      <td>Picpay</td>
                      <td>R${parseFloat(payments.total_picPay_payment).toFixed(2)}</td>
                      <td className="text-right">{payments.total_payment_received > 0 ? (payments.total_picPay_payment * 100/payments.total_payment_received).toFixed(2) : 0}%</td>
                    </tr>
                    <tr>
                      <td>Cartão de Crédito</td>
                      <td>R${parseFloat(payments.total_paytime_payment).toFixed(2)}</td>
                      <td className="text-right">{payments.total_payment_received > 0 ? (payments.total_paytime_payment * 100/payments.total_payment_received).toFixed(2) : 0}%</td>
                    </tr>
                    <tr>
                      <td>Pagar na Retirada</td>
                      <td>R${parseFloat(payments.total_withdrawal_payment).toFixed(2)}</td>
                      <td className="text-right">{payments.total_payment_received > 0 ? (payments.total_withdrawal_payment * 100/payments.total_payment_received).toFixed(2) : 0}%</td>
                    </tr>
                    <tr>
                      <td><strong>Total</strong></td>
                      <td><strong>R${parseFloat(payments.total_payment_received).toFixed(2)}</strong></td>
                      <td className="text-right"><strong>100%</strong></td>
                    </tr>
                </tbody>
              </Table>
            </ModalBody>
          </Modal>

          <Col lg="4" md="6" sm="6">
            <CardDash
              title="Pedidos Feitos"
              total={ordersNotPending.length}
              icon="nc-icon nc-tap-01 text-danger"
              toggle={toggleOrders}
            ></CardDash>
          </Col>

          <Modal isOpen={modalOrders} toggle={toggleOrders}>
            <ModalBody>
              <h3>Pedidos</h3>
              <Table>
                <tbody>
                    <tr>
                      <td>Carrinhos Abandonados</td>
                      <td>{ordersExpired}</td>
                    </tr>
                    {/* <tr>
                      <td>Pagamentos Recusados</td>
                      <td>{ordersExpired}</td>
                    </tr> */}
                    <tr>
                      <td>Pedidos Cancelados</td>
                      <td>{ordersCanceled}</td>
                    </tr>
                    <tr>
                      <td><strong>Pedidos Feitos e Entregues</strong></td>
                      <td><strong>{ordersNotPending.length}</strong></td>
                    </tr>
                </tbody>
              </Table>
            </ModalBody>
          </Modal>

          <Col lg="4" md="6" sm="6">
              <CardDash
                title="Clientes Únicos"
                total={clients.length}
                icon="nc-icon nc-single-02 text-info"
                toggle={toggleClients}
              ></CardDash>
          </Col>

          <Modal isOpen={modalClients} toggle={toggleClients} size="lg">
            <ModalBody>
              <h3>Clientes</h3>
              <Table>
                <thead>
                  <tr>
                    <th>Cliente</th>
                    <th>Arrecadado</th>
                    <th>Pedidos</th>
                    <th>Porcentagem</th>
                    <th className="text-right">Última Visita</th>
                  </tr>
                </thead>
                <tbody>
                  {clients.map((client) => 
                    <tr>
                      <td>{formatPhoneString(client[0])}</td>
                      <td>R${parseFloat(client[1]).toFixed(2)}</td>
                      <td>{client[2]}</td>
                      <td>{client[3]}%</td>
                      <td className="text-right">{formatCompleteDate(client[4])}</td>
                    </tr>)}
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
        </Row>

        <Row>
          <Col md="8">
            <Card className="card-chart">
              <CardHeader>
              <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                  <Nav id="tabs" role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        aria-expanded={chartSelected === "weekly"}
                        data-toggle="tab"
                        role="tab"
                        className={
                          chartSelected === "weekly"
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          setChartSelected("weekly")
                        }
                      >
                        Semanal
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        aria-expanded={
                          chartSelected === "monthly"
                        }
                        data-toggle="tab"
                        role="tab"
                        className={
                          chartSelected === "monthly"
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          setChartSelected("monthly")
                        }
                      >
                        Mensal
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        aria-expanded={
                          chartSelected === "yearly"
                        }
                        data-toggle="tab"
                        role="tab"
                        className={
                          chartSelected === "yearly"
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          setChartSelected("yearly")
                        }
                      >
                        Anual
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              </CardHeader>
              <CardBody>
                <TabContent
                  className="text-center"
                  id="my-tab-content"
                  activeTab={chartSelected}
                >
                  <TabPane tabId="weekly" role="tabpanel">
                    <p className="card-category">Arrecadação (R$) x Dia da Semana</p>
                    <Col md="10" className="ml-auto mr-auto">
                      <Line
                        data={chartWeekly.data}
                        options={chartWeekly.options}
                      />
                    </Col>  
                  </TabPane>
                  <TabPane tabId="monthly" role="tabpanel">
                    <p className="card-category">Arrecadação (R$) x Dia do Mês</p>
                    <Col md="10" className="ml-auto mr-auto">
                      <Line
                        data={chartMonthly.data}
                        options={chartMonthly.options}
                      />
                    </Col>  
                  </TabPane>
                  <TabPane tabId="yearly" role="tabpanel">
                    <p className="card-category">Arrecadação (R$) x Mês do Ano</p>
                    <Col md="10" className="ml-auto mr-auto">
                      <Line
                        data={chartYearly.data}
                        options={chartYearly.options}
                      />
                    </Col>  
                  </TabPane>
                </TabContent>               
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-calendar" />
                  Os dados apresentados nos gráficos são independentes do período selecionado.
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <CardHeader>
                <CardTitle>Formas de Pagamento</CardTitle>
              </CardHeader>
              <CardBody>
                <div style={{textAlign: "center"}}>
                {payments.total_payment_received === 0 ? <p style={{color: "red"}}>Não houve nenhum pedido no período selecionado.</p> : 
                <Pie
                  data={chartPayments.data}
                  options={chartPayments.options}
                  width={456}
                  height={400}
                />}
                </div>
              </CardBody>
              <CardFooter>
                <div className="legend">
                  <i className="fa fa-circle" style={{color: "#11c76f"}} />
                  Picpay {payments.total_payment_received > 0 ? (payments.total_picPay_payment * 100/payments.total_payment_received).toFixed(2) : 0}%
                </div>
                <div className="legend">
                  <i className="fa fa-circle"  style={{color: "blue"}} />
                  Cartão de Crédito {payments.total_payment_received > 0 ? (payments.total_paytime_payment * 100/payments.total_payment_received).toFixed(2) : 0}%
                </div>
                <div className="legend">
                  <i className="fa fa-circle"  style={{color: "red"}} />
                  Pagar na Retirada {payments.total_payment_received > 0 ? (payments.total_withdrawal_payment * 100/payments.total_payment_received).toFixed(2) : 0}%
                </div>
                <hr />
                <div className="stats">
                <ButtonLink onClick={togglePayments}>
                  Mais detalhes
                </ButtonLink>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="4">
            <CardTopEstablishment source={restaurantsData}></CardTopEstablishment>
          </Col>
          <Col md="8">
            <CardTable
              title="Vendas Por Estabelecimento"
              subtitle="Confira quanto cada estabelecimento esta arrecadando e tenha
                  controle de seu Shopping!"
              restaurants={restaurantsData}
            ></CardTable>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ShoppingDashboard;
