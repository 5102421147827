import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Table,
  Row,
  Col,
  Button,
  Collapse,
  Nav, NavItem, NavLink,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";

import {
  format,
  startOfDay,
  startOfYear,
  subDays,
  getMonth,
  addMonths,
  lastDayOfMonth,
  isBefore,
  isAfter,
  endOfDay,
  subHours,
  startOfWeek,
  startOfMonth,
  addHours
} from 'date-fns';

import { ToLine } from "variables/charts.jsx";

import { Form } from "@unform/web";
import { Input } from "@material-ui/core";
import CardDash from "components/Cards/CardDash";
import NotificationAlert from "react-notification-alert";
import Order from "../../components/Order";

import { DateSelectorDiv, DateDiv, SubmitDate } from "./styles";
import { Line } from "react-chartjs-2";

import api from "../../../services/api";

function DashboardExpress() {
  const formRef = useRef(null);
  const [time, setTime] = useState(null);
  const [ordersPending, setOrdersPending] = useState([]);
  const [ordersNotPending, setOrdersNotPending] = useState([]);
  const [ordersWaitingPayment, setOrdersWaitingPayment] = useState([]);
  const [ordersTotal, setOrdersTotal] = useState(0);
  const [ordersCanceled, setOrdersCanceled] = useState([]);
  const token = localStorage.getItem("@QRExpress:token");
  const notificationAlert = useRef();


  const defaultInicial = useMemo(() => {
    return format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  const defaultFinal = useMemo(() => {
    return format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss");
  }, []);
  let inicialFormat = format(new Date(defaultInicial), "dd/MM/yyyy, HH:mm");
  let finalFormat = format(new Date(defaultFinal), "dd/MM/yyyy, HH:mm");
  let phrase = `De ${inicialFormat} até ${finalFormat}`;

  const [inicialDate, setInicialDate] = useState(defaultInicial);
  const [finalDate, setFinalDate] = useState(defaultFinal);
  const [hourPhrase, setHourPhrase] = useState(phrase);

  function setToday(){
    setInicialDate(format(startOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setWeek(){
    setInicialDate(format(startOfWeek(subHours(new Date(), 3), {weekStartsOn: 1}), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfWeek(subHours(new Date(), 3), {weekStartsOn: 1}), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  function setMonth(){
    setInicialDate(format(startOfMonth(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(endOfDay(subHours(new Date(), 3)), "yyyy-MM-dd'T'HH:mm:ss"));

    let inicialFormat = format(startOfMonth(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(endOfDay(subHours(new Date(), 3)), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    setHourPhrase(phrase);
  }

  let [initialPre, setInitialPre] = useState(inicialDate);
  let [finalPre, setFinalPre] = useState(finalDate);

  function handleChangeDate() {
    setInicialDate(format(subHours(new Date(initialPre), 3), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDate(format(subHours(new Date(finalPre), 3), "yyyy-MM-dd'T'HH:mm:ss"));
    let inicialFormat = format(new Date(initialPre), "dd/MM/yyyy, HH:mm");
    let finalFormat = format(new Date(finalPre), "dd/MM/yyyy, HH:mm");
    let phrase = `De ${inicialFormat} até ${finalFormat}`;
    
    setHourPhrase(phrase);
  }

  const [inicialDateSearch, setInicialDateSearch] = useState(format(addHours(new Date(inicialDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));
  const [finalDateSearch, setFinalDateSearch] = useState(format(addHours(new Date(finalDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));

  useEffect(() => {
    setInicialDateSearch(format(addHours(new Date(inicialDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));
    setFinalDateSearch(format(addHours(new Date(finalDate), 3), "yyyy-MM-dd'T'HH:mm:ss"));  
  }, [inicialDate, finalDate]);


  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const getOrdersPending = useCallback(async () => {
    try {
      const response = await api.get(`admin/food-courts/restaurants/orders`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          status: "pending",
        },
      });

      let orderPending = response.data;
      setOrdersPending(orderPending);

      const data = new Date();
      const time = `${data.getUTCHours() - 3}:${data.getUTCMinutes()}`;
      setTime(time);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token]);

  let [restaurants, setRestaurants] = useState([]);
  const [chartCashier, setChartCashier] = useState(ToLine([], []));
  const [chartClients, setChartClients] = useState(ToLine([], []));
  const [last30Days, setLast30Days] = useState(0);

  const getOrdersNotPending = useCallback(async () => {
    try {
      const response = await api.get(`admin/food-courts/restaurants/orders`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          start_date: `${inicialDateSearch}`,
          end_date: `${finalDateSearch}`,
        },
      });
      let orderNotPending = response.data.filter(
        (order) =>
          order.order_status !== "waiting_payment" &&
          order.order_status !== "pending" &&
          order.order_status !== "canceled" &&
          order.order_status !== "expired" &&
          order.order_status !== "chargeback"
      );
      setOrdersNotPending(orderNotPending);

      let orderWaitingPayment = response.data.filter(
        (order) =>
          order.order_status === "waiting_payment"
      );
      setOrdersWaitingPayment(orderWaitingPayment);

      let ordersTotalPrice = 0;

      orderNotPending.forEach((order) => {
        ordersTotalPrice = ordersTotalPrice + parseFloat(order.total_price);
      });

      setOrdersTotal(`R$${ordersTotalPrice.toLocaleString('pt-BR')}`);

      let orderCanceled = response.data.filter(
        (order) =>
          order.order_status === "canceled" ||
          order.order_status === "chargeback" ||
          order.order_status === "expired"
      );
      setOrdersCanceled(orderCanceled);

      const data = new Date();
      const time = `${data.getUTCHours() - 3}:${
        data.getUTCMinutes() < 10
          ? `0${data.getUTCMinutes()}`
          : data.getUTCMinutes()
      }`;
      setTime(time);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token, inicialDateSearch, finalDateSearch]);

    const getRestaurants = useCallback(async () => {
    try {
      const response = await api.get("admin/restaurants", {
        headers: { Authorization: `Bearer ${token}` },
      });
    
      setRestaurants(response.data);
      let restaurants = response.data;

      let inicialDate = format(addHours(startOfYear(subHours(new Date(), 3)), 3), "yyyy-MM-dd'T'HH:mm:ss");

      let filterDateInicial30Days = format(subDays(subHours(new Date(), 3), 30), "yyyy-MM-dd'T'HH:mm:ss");
      let data = [];
      let last30Days = 0;
      let label = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
      for (let i=0; i<getMonth(new Date()) + 1; i++){
        let totalMonthly = 0;
        let filterDateInicial = format(startOfMonth(addMonths(new Date(inicialDate), i)), "yyyy-MM-dd'T'HH:mm:ss");
        let filterDateFinal = format(lastDayOfMonth(addMonths(new Date(inicialDate), i)), "yyyy-MM-dd'T'HH:mm:ss");
        restaurants.forEach((restaurant)=> {
          if (isBefore(new Date(restaurant.created_at), new Date(filterDateFinal)) && isAfter(new Date(restaurant.created_at), new Date(filterDateInicial))){
            totalMonthly++;
          }
        });
        data.push(totalMonthly);
      }

      restaurants.forEach((restaurant)=>{
        if (isAfter(new Date(restaurant.created_at), new Date(filterDateInicial30Days))){
          last30Days++;
        }
      });
      setChartClients(ToLine("Clientes Novos",label, data, "red"));
      setLast30Days(last30Days);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [token]);

  const [chartSelected, setChartSelected] = useState("clients");
  // const [chartYearly, setChartYearly] = useState(ToLine([], []));
  const [loadingChart, setLoadingChart] = useState(false);
  const getChartYearly = useCallback(async () => {
    try {
      setLoadingChart(true);
      let inicialDate = format(addHours(startOfYear(subHours(new Date(), 3)), 3), "yyyy-MM-dd'T'HH:mm:ss");
      let finalDate = format(addHours(endOfDay(subHours(new Date(), 3)), 3), "yyyy-MM-dd'T'HH:mm:ss");

      const response = await api.get(`admin/food-courts/restaurants/orders`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          start_date: `${inicialDate}`,
          end_date: `${finalDate}`,
        },
      });
      console.log('aq', response.data);
      let orderNotPending = response.data.filter(
        (order) =>
          order.order_status !== "pending" &&
          order.order_status !== "canceled" &&
          order.order_status !== "chargeback" &&
          order.order_status !== "expired" &&
          order.order_status !== "waiting_payment"
      );
      console.log('orderr', orderNotPending);
      let data = [];
      let label = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
      for (let i=0; i<getMonth(new Date()) + 1; i++){
        let totalDaily = 0;
        let filterDateInicial = format(startOfMonth(addMonths(new Date(inicialDate), i)), "yyyy-MM-dd'T'HH:mm:ss");
        let filterDateFinal = format(lastDayOfMonth(addMonths(new Date(inicialDate), i)), "yyyy-MM-dd'T'HH:mm:ss");
        orderNotPending.forEach((order)=> {
          if (isBefore(new Date(order.created_at), new Date(filterDateFinal)) && isAfter(new Date(order.created_at), new Date(filterDateInicial))){
            totalDaily = totalDaily + parseFloat(order.total_price_with_sms);
          }
        });
        data.push(totalDaily.toFixed(2));
      }
      setChartCashier(ToLine("Faturamento (R$)",label, data, "green"));
      setLoadingChart(false);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
      setLoadingChart(false);
    }
  }, [token]);



  useEffect(() => {
    getOrdersPending();
    getOrdersNotPending();
    getRestaurants();
    getChartYearly();
  }, [getOrdersPending, getOrdersNotPending, getRestaurants, getChartYearly]);

  function refresh() {
    getOrdersPending();
    getOrdersNotPending();
    getRestaurants();
    getChartYearly();
  }

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
      <Row>
          <Col md="5">
            <h3>Dashboard QR Express</h3>
          </Col>
          <Col md="7">
            <div style={{ float: "right" }}>
              <Button color="danger" onClick={refresh}>
                <span className="btn-label">
                  <i className="nc-icon nc-refresh-69" />
                </span>
                Atualizar
              </Button>
            </div>
          </Col>
        </Row>
      <Row>
              <Col lg="4">
                <Row>
                <Col lg="12" md="6" sm="6">
                  <CardDash
                    title="Clientes"
                    total={restaurants.length}
                    icon="nc-icon nc-shop text-danger"
                  ></CardDash>
                </Col>
                <Col lg="12" md="6" sm="6">
                  <CardDash
                    title="Clientes Último Mês"
                    total={last30Days}
                    icon="nc-icon nc-refresh-69 text-info"
                  ></CardDash>
                </Col>
                </Row>
              </Col>
              
              <Col md="8">
                {/* <Card className="card-chart">
                  <CardHeader>
                    <h6>Clientes Novos por Mês</h6>
                  </CardHeader>
                  <CardBody>
                    <p className="card-category">Clientes Novos x Mês do Ano</p>
                    <Col md="10" className="ml-auto mr-auto">
                      <Line
                        data={chartYearly.data}
                        options={chartYearly.options}
                      />
                    </Col>  
                  </CardBody>
                  {/* <CardFooter>
                    <hr />
                    <div className="stats">
                      <i className="fa fa-calendar" />
                      Os dados apresentados nos gráficos são independentes do período selecionado.
                    </div>
                  </CardFooter> 
                </Card> */}
                <Card className="card-chart">
              <CardHeader>
              <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                  <Nav id="tabs" role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        aria-expanded={chartSelected === "clients"}
                        data-toggle="tab"
                        role="tab"
                        className={
                          chartSelected === "clients"
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          setChartSelected("clients")
                        }
                      >
                        Clientes
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        aria-expanded={
                          chartSelected === "cashier"
                        }
                        data-toggle="tab"
                        role="tab"
                        className={
                          chartSelected === "cashier"
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          setChartSelected("cashier")
                        }
                      >
                        Faturamento
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              </CardHeader>
              <CardBody>
                <TabContent
                  className="text-center"
                  id="my-tab-content"
                  activeTab={chartSelected}
                >
                  <TabPane tabId="clients" role="tabpanel">
                    <p className="card-category">Clientes Novos por Mês</p>
                    <Col md="10" className="ml-auto mr-auto">
                      <Line
                        data={chartClients.data}
                        options={chartClients.options}
                        type="clients"
                      />
                    </Col>  
                  </TabPane>
                  <TabPane tabId="cashier" role="tabpanel">
                    <p className="card-category">Arrecadação (R$) x Mês</p>
                    <Col md="10" className="ml-auto mr-auto">
                      {loadingChart ?             
                      <Spinner style={{ width: '3rem', height: '3rem' }}  type="grow" color="danger"/>
                      : <Line
                        data={chartCashier.data}
                        options={chartCashier.options}
                        type="clients"
                      />}
                      
                    </Col>  
                  </TabPane>
                  
                </TabContent>               
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-calendar" />
                  Os dados apresentados nos gráficos são independentes do período selecionado.
                </div>
              </CardFooter>
            </Card>
              </Col>
            </Row>


        <Row>
            <Col md="4"><p>{hourPhrase}</p></Col>
            <Col md="8">
              <div style={{ float: "right" }}>
                <Button className="btn-round mr-auto" onClick={setToday} color="info">
                  Hoje
                </Button>
                <Button className="btn-round mr-auto" onClick={setWeek} color="info">
                  Essa semana
                </Button>
                <Button className="btn-round mr-auto" onClick={setMonth} color="info">
                  Esse mês
                </Button>
                <Button className="btn-round mr-auto" onClick={toggle} color="info">
                  Selecionar Período
                  <b className="caret" style={{marginLeft: "10px"}}/>
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div style={{ float: "right", marginRight: "15px" }}>
                <Collapse isOpen={isOpen}>
                  <Form onSubmit={handleChangeDate} ref={formRef}>
                    <DateSelectorDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Inicial"
                          type="datetime-local"
                          name="initialDate"
                          onChange={(e) => setInitialPre(e.target.value)}
                          defaultValue={defaultInicial}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <DateDiv>
                        <Input
                          id="datetime-local"
                          label="Data Final"
                          type="datetime-local"
                          name="finalDate"
                          onChange={(e) => setFinalPre(e.target.value)}
                          defaultValue={defaultFinal}
                          className="data"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </DateDiv>
                      <div>
                        <SubmitDate onClick="submit">Filtrar</SubmitDate>
                      </div>
                    </DateSelectorDiv>
                  </Form>
                </Collapse>
              </div>
            </Col>
          </Row>

        <Row>
          <Col lg="4" md="6" sm="6">
            <CardDash
              title="Arrecadação"
              total={ordersTotal}
              icon="nc-icon nc-money-coins text-success"
            ></CardDash>
          </Col>
          <Col lg="4" md="6" sm="6">
            <CardDash
              title="Pedidos Feitos"
              total={ordersNotPending.length}
              icon="nc-icon nc-tap-01 text-info"
            ></CardDash>
          </Col>
          <Col lg="4" md="6" sm="6">
            <CardDash
              title="Pedidos Cancelados"
              total={ordersCanceled.length}
              icon="nc-icon nc-simple-remove text-danger"
            ></CardDash>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardHeader style={{ display: "flex", flexDirection: "row" }}>
                <Col md="8">
                  <CardTitle tag="h4">Pedidos Pendentes em Shoppings</CardTitle>
                </Col>
                <Col md="4">
                  <div style={{ float: "right" }}>
                    <p>Última Atualização {time}</p>
                  </div>
                </Col>
              </CardHeader>
              <CardBody>
                {ordersPending.length !== 0 ? (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Shopping</th>
                        <th>Restaurante</th>
                        <th>Pedido Feito</th>
                        <th>Pagamento Aprovado</th>
                        <th>Pedido</th>
                        <th>Valor</th>
                        <th>Status</th>
                        <th>Aceito Em</th>
                        <th>Pronto Em</th>
                        <th className="text-right">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordersPending.map((order) => (
                        <Order order={order} />
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div style={{ textAlign: "center", color: "red" }}>
                    <span>Nenhum pedido pendente.</span>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardHeader style={{ display: "flex", flexDirection: "row" }}>
                <Col md="8">
                  <CardTitle tag="h4">Pedidos Aguardando Pagamento</CardTitle>
                </Col>
                <Col md="4">
                  <div style={{ float: "right" }}>
                    <p>Última Atualização {time}</p>
                  </div>
                </Col>
              </CardHeader>
              <CardBody>
                {ordersWaitingPayment.length > 0 ? (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Shopping</th>
                        <th>Restaurante</th>
                        <th>Pedido Feito</th>
                        <th>Pagamento Aprovado</th>
                        <th>Pedido</th>
                        <th>Valor</th>
                        <th>Status</th>

                        <th className="text-right">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordersWaitingPayment.map((order) => (
                        <Order order={order} />
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div style={{ textAlign: "center", color: "red" }}>
                    <span>Nenhum pedido aguardando pagamento.</span>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardHeader style={{ display: "flex", flexDirection: "row" }}>
                <Col md="8">
                  <CardTitle tag="h4">Pedidos Feitos em Shoppings</CardTitle>
                </Col>
                <Col md="4">
                  <div style={{ float: "right" }}>
                    <p>Última Atualização {time}</p>
                  </div>
                </Col>
              </CardHeader>
              <CardBody>
                {ordersNotPending.length > 0 ? (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Shopping</th>
                        <th>Restaurante</th>
                        <th>Pedido Feito</th>
                        <th>Pagamento Aprovado</th>
                        <th>Pedido</th>
                        <th>Valor</th>
                        <th>Status</th>
                        <th>Aceito Em</th>
                        <th>Pronto Em</th>
                        <th className="text-right">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordersNotPending.map((order) => (
                        <Order order={order} />
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div style={{ textAlign: "center", color: "red" }}>
                    <span>Nenhum pedido feito no período selecionado.</span>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardHeader style={{ display: "flex", flexDirection: "row" }}>
                <Col md="8">
                  <CardTitle tag="h4">
                    Pedidos Cancelados em Shoppings
                  </CardTitle>
                </Col>
                <Col md="4">
                  <div style={{ float: "right" }}>
                    <p>Última Atualização {time}</p>
                  </div>
                </Col>
              </CardHeader>
              <CardBody>
                {ordersCanceled.length > 0 ? (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Shopping</th>
                        <th>Restaurante</th>
                        <th>Pedido Feito</th>
                        <th>Pagamento Cancelado</th>
                        <th>Pedido</th>
                        <th>Valor</th>
                        <th>status</th>
                        <th className="text-right">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordersCanceled.map((order) => (
                        <Order order={order} />
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div style={{ textAlign: "center", color: "red" }}>
                    <span>Nenhum pedido cancelado no período selecionado.</span>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* <Row>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Clientes"
                total={987}
                icon="nc-icon nc-single-02 text-info"
              ></CardDash>
            </Col>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Arrecadação"
                total="R$1,247.68"
                icon="nc-icon nc-money-coins text-success"
              ></CardDash>
            </Col>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Pedidos"
                total="125"
                icon="nc-icon nc-tap-01 text-danger"
              ></CardDash>
            </Col>
          </Row>
          <Row>
            <Col lg="4" sm="6">
              <CardChart
                total="124"
                percent="12"
                title="Clientes na última hora"
                color="info"
                chartExample={chartExample3}
              ></CardChart>
            </Col>
            <Col lg="4" sm="6">
              <CardChart
                total="R$258.37"
                percent="16"
                title="Arrecadações na última hora"
                color="success"
                chartExample={chartExample1}
              ></CardChart>
            </Col>
            <Col lg="4" sm="6">
              <CardChart
                total="84"
                percent="11.4"
                title="Pedidos na última hora"
                color="danger"
                chartExample={chartExample2}
              ></CardChart>
            </Col>
          </Row>
          <Row>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Média Avaliações Estabelecimentos"
                total="4.1"
                icon="fa fa-star text-warning"
              ></CardDash>
            </Col>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Praças de Alimentação"
                total="13"
                icon="nc-icon nc-shop text-danger"
              ></CardDash>
            </Col>
            <Col lg="4" md="6" sm="6">
              <CardDash
                title="Estabelecimentos"
                total="45"
                icon="nc-icon nc-shop text-success"
              ></CardDash>
            </Col>
          </Row>
          <Row>
            <Col md="7">
              <CardTable
                title="Vendas Por Estabelecimento"
                subtitle="Confira quanto cada estabelecimento esta arrecadando e tenha
                    controle de seu Shopping!"
              ></CardTable>
            </Col>

            <Col md="5">
              <CardTopEstablishment source="mcdonalds"></CardTopEstablishment>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <CardChartPlus
                title="Vendas de Julho"
                subtitle="Venda total do shopping por dia do mês"
                legend="Dias do Mês"
                footer="Atualizado diariamente"
              ></CardChartPlus>
            </Col>
          </Row> */}
      </div>
    </>
  );
}

export default DashboardExpress;
