import React, { useState, useRef, useEffect } from "react";
import { useField } from "@unform/core";

import { MdInsertPhoto } from "react-icons/md";
import { toast } from "react-toastify";

import { Container, DefaultAvatar } from "./styles";
import apiGd from "../../../services/apiGd";

export default function AvatarInputGd({ name, setRestaurantData = null, ...rest }) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [fileId, setFileId] = useState(defaultValue && defaultValue.id);
  const [preview, setPreview] = useState(defaultValue && defaultValue.url);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: "dataset.fileid",
      clearValue() {
        ref.value = "";
        setPreview(null);
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (defaultValue) {
      setPreview(defaultValue.url);
      setFileId(defaultValue.id);


    }

    console.log(defaultValue)
  }, [defaultValue]);

  async function handleChange(e) {
    try {
      const data = new FormData();
      const token = localStorage.getItem("@QRExpress:tokenGd");
      data.append("file", e.target.files[0]);

      if (fileId) {
        await apiGd.delete(`restaurants/files/${defaultValue.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }

      const response = await apiGd.post("admin/files", data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const { id, url } = response.data;

      if (setRestaurantData) {
        setRestaurantData(old => {
          return {
            ...old,
            avatar_id: id
          }
        })
      }

      setFileId(id);
      setPreview(url);
    } catch (err) {
      toast.error(error.response.data.error);
      console.log("erro no avatar")
    }
  }

  return (
    <Container>
      <label htmlFor="avatar">
        {preview ? (
          <img
            src={preview}
            style={{ height: 200, width: 300 }}
            alt="User Avatar"
          />
        ) : (
          <DefaultAvatar>
            <MdInsertPhoto size={45} color="#ddd" />
            <span>Adicionar Logo</span>
          </DefaultAvatar>
        )}

        <input
          type="file"
          id="avatar"
          accept="image/*"
          data-fileid={fileId}
          onChange={handleChange}
          ref={ref}
          {...rest}
        />
      </label>
    </Container>
  );
}
