import React from "react";

export default function UserTR({ user }) {
  const tokenGd = localStorage.getItem("@QRExpress:tokenGd");

  return (
    <>
      {/* <tr style={{cursor: 'pointer'}} onClick={() => toggleModal()}> */}
      <tr>
        <td>
          <strong>{user.name}</strong>
          <p>{user.email}</p>
        </td>
      </tr>

      {/* <Modal isOpen={modal} toggle={toggleModal} size='xl'>
        <ModalBody>
          <Row>
            <Col md="10">
              <h4>{research.title}</h4>
              <p>{research.question}</p>
              <p>ID: {research.id} - {formatDateYear(research.createdAt)}  - {getType(research.type)}</p>   
              <p>Método: {research.method === 'nps' ? 'NPS' : 'CES'}</p>
            </Col>
            <Col md="2">
              <Button name="download_button" style={{float: 'right', marginTop: 20}} color="default" onClick={() => handleDownloadSheet()}>
                <FaDownload size={15}/>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <CardDash
                title="Respostas"
                total={answers?.answers?.length}
                icon="nc-icon nc-tap-01 text-warning"
              />
            </Col>
            <Col md="6">
            {research?.method === 'nps' ?
              <CardDash
                title="NPS"
                total={`${nps.toFixed(2)}%`}
                icon="nc-icon nc-zoom-split text-info"
                obs={getNPS(nps)}
              />
              :
                <CardDash
                  title="CES"
                  total={`${ces.toFixed(2)}%`}
                  icon="nc-icon nc-zoom-split text-info"
                  obs={ces >= 3.55 && 'Satisfatório'}
                />
            }
            </Col>
          </Row>
          <Row>
            {research?.method === 'nps' ?
              <>
                <Col md="4">
                  <CardDash
                    title="Detratores"
                    total={answers?.answers?.filter(ans => ans?.value !== null && parseFloat(ans?.value || 0) <= 6).length}
                  />
                </Col>
                <Col md="4">
                  <CardDash
                    title="Neutros" // 0 a 6 detrator; 7 e 8 neutros; 9 e 10 promotores
                    total={answers?.answers?.filter(ans => ans?.value !== null && [7,8].includes(parseInt(ans?.value || 0))).length}
                  />
                </Col>
                <Col md="4">
                  <CardDash
                    title="Promotores"
                    total={answers?.answers?.filter(ans => ans?.value !== null && [9,10].includes(parseInt(ans?.value || 0))).length}
                  />
                </Col>
              </> :
              <>
              <Col md="4">
                <CardDash
                  title="Pouco esforço"
                  total={answers?.answers?.filter(ans => ans?.value !== null && [1,2].includes(parseFloat(ans?.value || 0))).length}
                />
              </Col>
              <Col md="4">
                <CardDash
                  title="Esforço Esperado" // 0 a 6 detrator; 7 e 8 neutros; 9 e 10 promotores
                  total={answers?.answers?.filter(ans => ans?.value !== null && [3].includes(parseInt(ans?.value || 0))).length}
                />
              </Col>
              <Col md="4">
                <CardDash
                  title="Muito esforço"
                  total={answers?.answers?.filter(ans => ans?.value !== null && [4,5].includes(parseInt(ans?.value || 0))).length}
                />
              </Col>
            </>
            }
          </Row>
          <Table>
            <thead>
              <tr>
                <th className="text-left">Restaurante</th>
                <th className="text-left">Nota</th>
                <th className="text-left">Comentário</th>
                <th className="text-right">Data</th>
                <th className="text-right">Usuário</th>
              </tr>
            </thead>
            <tbody>
              {
                answers?.answers?.map(answer => 
                  <tr>
                    <td className="text-left">
                      <strong>{answer.restaurant?.fantasy_name}</strong>
                      <RestauranteInfo>{answer.restaurant?.name}</RestauranteInfo>
                      <RestauranteInfo>{answer.restaurant?.place_tables?.length} mesas</RestauranteInfo>
                      {answer.restaurant?.has_nfce && <RestauranteInfo>Fiscal</RestauranteInfo>}
                      {answer.restaurant?.has_clube && <RestauranteInfo>Clube</RestauranteInfo>}
                      {answer.restaurant?.delivery_info?.is_delivery_allowed && <RestauranteInfo>Delivery</RestauranteInfo>}
                      {answer.restaurant?.has_ifood && <RestauranteInfo>iFood</RestauranteInfo>}
                    </td>
                    <td style={{color: 'orange'}} className="text-left"><strong>{answer.value ? parseInt(answer.value) : '-'} ★</strong></td>
                    <td className="text-left">{answer.comment}</td>
                    <td className="text-right">{formatCompleteDate(answer.createdAt)}</td>
                    <td className="text-right">
                      <RestauranteInfo>{answer?.user?.name}</RestauranteInfo>
                      <RestauranteInfo><i>{answer?.user?.access}</i></RestauranteInfo>
                    </td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </ModalBody>
      </Modal> */}
    </>
  );
}
