import React, { useCallback, useState } from "react";
import { Button, Table } from "reactstrap";
import formatCompleteDate from "services/formatCompleteDate";
import { BiRefresh } from "react-icons/bi";
import apiGd from "services/apiGd";


export default function RecoverMenu({ menu, loading, getDeletedMenu, notify }) {
  const token = localStorage.getItem("@QRExpress:tokenGd");

  const recover = useCallback(
    async ({ id, type, name }) => {
      if(window.confirm(`Tem certeza que deseja recuperar ${name}?`)){
        try {
          const reqConfig = {
            headers: { Authorization: `Bearer ${token}` },
          };
  
          await apiGd.put(
            `/admin/restaurants/menu/recover`,
            {
              id, type
            },
            reqConfig
          );
          
          getDeletedMenu();
          notify("tr", "success", "Cardápio atualizado com sucesso!");
        } catch (error) {
          console.log(error);
          notify(
            "tr",
            "danger",
            "Erro ao atualizar cardapio! Confira as informacoes no excel"
          );
        }
      }
    },
    []
  );

  return (
    <>
      {
        loading ? 'Carregando...' : 
        <div>
          <h4>Categorias de Produtos</h4>
          <Table>
            <thead>
              <tr>
                <th>Categoria</th>
                <th>Criada em</th>
                <th>Deletada em</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                menu?.product_categories.map(category => (
                  <tr>
                    <td>
                      {category.name}
                    </td>
                    <td>
                      {category.created_at ? formatCompleteDate(new Date(category.created_at)) : '-'}
                    </td>
                    <td>
                      {category.deleted_at ? formatCompleteDate(new Date(category.deleted_at)) : '-'}
                    </td>
                    <td>
                      <BiRefresh
                        style={{ color: "red", cursor: "pointer", float: 'right' }}
                        size={22}
                        onClick={() => recover({id: category.id, type: 'product_categories', name: category.name})}
                      />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>

          <h4>Produtos</h4>
          <Table>
            <thead>
              <tr>
                <th>Produto</th>
                <th>Preço</th>
                <th>Criada em</th>
                <th>Deletada em</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                menu?.products.map(product => (
                  <tr>
                    <td>
                      {product.name}
                    </td>
                    <td>
                      R${product.price}
                    </td>
                    <td>
                      {product.created_at ? formatCompleteDate(new Date(product.created_at)) : '-'}
                    </td>
                    <td>
                      {product.deleted_at ? formatCompleteDate(new Date(product.deleted_at)) : '-'}
                    </td>
                    <td>
                      <BiRefresh
                        style={{ color: "red", cursor: "pointer", float: 'right' }}
                        size={22}
                        onClick={() => recover({id: product.id, type: 'products', name: product.name})}
                      />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>

          <h4>Categorias de Complementos</h4>
          <Table>
            <thead>
              <tr>
                <th>Categoria</th>
                <th>Criada em</th>
                <th>Deletada em</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                menu?.complement_categories.map(category => (
                  <tr>
                    <td>
                      {category.name}
                    </td>
                    <td>
                      {category.created_at ? formatCompleteDate(new Date(category.created_at)) : '-'}
                    </td>
                    <td>
                      {category.deleted_at ? formatCompleteDate(new Date(category.deleted_at)) : '-'}
                    </td>
                    <td>
                      <BiRefresh
                        style={{ color: "red", cursor: "pointer", float: 'right' }}
                        size={22}
                        onClick={() => recover({id: category.id, type: 'complement_categories', name: category.name})}
                      />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>

          <h4>Complementos</h4>
          <Table>
            <thead>
              <tr>
                <th>Complemento</th>
                <th>Preço</th>
                <th>Criada em</th>
                <th>Deletada em</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                menu?.complements.map(complement => (
                  <tr>
                    <td>
                      {complement.name}
                    </td>
                    <td>
                      R${complement.price}
                    </td>
                    <td>
                      {complement.created_at ? formatCompleteDate(new Date(complement.created_at)) : '-'}
                    </td>
                    <td>
                      {complement.deleted_at ? formatCompleteDate(new Date(complement.deleted_at)) : '-'}
                    </td>
                    <td>
                      <BiRefresh
                        style={{ color: "red", cursor: "pointer", float: 'right' }}
                        size={22}
                        onClick={() => recover({id: complement.id, type: 'complements', name: complement.name})}
                      />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </div>
      }
    </>
  );
}
