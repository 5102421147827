import React, { useState } from "react";
import { Body, Content, InputAddressArea } from "./styles";
import { DefaultInput, Checkbox } from "ui-kit-takeat";
import apiGd from "services/apiGd";
import Input from "views/components/Input";

function EditLocation({ restaurantData, setRestaurantData }) {

  const handleChange = (field, value) => {
    setRestaurantData((prevData) => ({
      ...prevData,
      adress: {
        ...prevData.adress,
        [field]: value,
      },
    }));
  };

  const [validCep, setValidCep] = useState(true);

  async function handleSearchCep(e) {
    if (e.charAt(8) !== "_") {
      const cep = e.replace("-", "").replace(".", "");
      const response = await apiGd.get(`/public/delivery/cep/${cep}`);

      if (!response.data.erro) {
        handleChange("street", response.data.logradouro);
        handleChange("neighborhood", response.data.bairro);
        handleChange("city", response.data.localidade);
        handleChange("state", response.data.uf);
        handleChange("zip_code", response.data.cep);
        setValidCep(true);
      } else {
        handleChange("street", "");
        handleChange("neighborhood", "");
        handleChange("city", "");
        setValidCep(false);
      }
    }
  }

  return (
    <Content>
      <span style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 500 }}>
        Localização
      </span>
      <Body>
        <InputAddressArea>
        <span>CEP</span>
        <Input
          name="CEP"
          value={restaurantData?.adress?.zip_code}
          onChange={(e) => {handleChange('zip_code', e.target.value); handleSearchCep(e.target.value)}}
        />
        </InputAddressArea>
        <InputAddressArea>
          <span>Logradouro</span>
          <Input
            name="Logradouro"
            value={restaurantData?.adress?.street}
            onChange={(e) => handleChange('street', e.target.value)}
          />
        </InputAddressArea>
        <InputAddressArea>
          <span>Número</span>        
          <Input
            name="Número"
            value={restaurantData?.adress?.number}
            onChange={(e) => handleChange('number', e.target.value)}
          />
        </InputAddressArea>
        <InputAddressArea>
          <span>Bairro</span>
          <Input
            name="Bairro"
            value={restaurantData?.adress?.neighborhood}
            onChange={(e) => handleChange('neighborhood', e.target.value)}
          />
        </InputAddressArea>
        <InputAddressArea>
          <span>Cidade</span>
          <Input
            name="Cidade"
            value={restaurantData?.adress?.city}
            onChange={(e) => handleChange('city', e.target.value)}
          />
        </InputAddressArea>
        <InputAddressArea>
          <span>Estado</span>
          <Input
            name="Estado"
            value={restaurantData?.adress?.state}
            onChange={(e) => handleChange('state', e.target.value)}
          />
        </InputAddressArea>    

        {/* <Checkbox
          name="Serviço de geolocalização"
          checked={restaurantData?.geolocation_cb}
          onClick={(e) => handleChange('geolocation_cb', e)}
        /> */}
      </Body>
      {/* <div style={{ display: 'flex', gap: 16 }}>
        <DefaultInput
          title="Latitude"
          value={restaurantData?.latitude}
          onChange={(e) => handleChange('latitude', e.target.value)}
          containerStyles={{ gridArea: "latitude" }}
        />
        <DefaultInput
          title="Longitude"
          value={restaurantData?.longitude}
          onChange={(e) => handleChange('longitude', e.target.value)}
          containerStyles={{ gridArea: "longitude" }}
        />
      </div> */}
    </Content>
  );
}

export default EditLocation;
