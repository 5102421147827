import React, { useRef, useState, useEffect } from "react";
import Input from "../components/Input";
import { Form } from "@unform/web";
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import api from "../../services/api";
import CardSettings from "components/Cards/CardSettings";

import { useRestaurants } from "context/RestaurantsContext";

function Settings() {
  const notificationAlert = useRef(null);

  const { validateUser } = useRestaurants();

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const formRef = useRef(null);
  const token = localStorage.getItem("@QRExpress:token");
  const [initialData, setInitialData] = useState([]);

  async function getSettings() {
    try {
      const response = await api.get("admin/settings", {
        headers: { Authorization: `Bearer ${token}` },
      });
      let { tax_multiplier, sms_service_price } = response.data[0];
      tax_multiplier = tax_multiplier * 100;
      setInitialData({ tax_multiplier, sms_service_price });
      console.log(initialData);
    } catch (err) {
      alert("Erro ao carregar informações");
    }
  }

  async function updateSettings(data) {
    try {
      const update = {
        sms_service_price: data.sms_service_price,
        tax_multiplier: data.tax_multiplier / 100,
      };
      await api.put("admin/settings", update, {
        headers: { Authorization: `Bearer ${token}` },
      });
      notify("tr", "success", "Atualizado com sucesso!");
      getSettings();
    } catch (err) {
      notify("tr", "danger", err?.response?.data?.message || "Erro ao atualizar.");
    }
  }

  useEffect(() => {
    validateUser();
    getSettings();
  }, []);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Form ref={formRef} initialData={initialData} onSubmit={updateSettings}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Configurações</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <label>Taxa de SMS (R$)</label>
                      <Input
                        name="sms_service_price"
                        type="text"
                        placeholder="R$"
                      />
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label>Taxa Paga pelo Estabelecimento (%)</label>
                        <Input
                          name="tax_multiplier"
                          type="text"
                          placeholder="%"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 15 }}>
                    <Col md="3" className="ml-auto">
                      <Button color="success" type="submit">
                        Atualizar Perfil
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col md="4">
            <CardSettings />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Settings;
