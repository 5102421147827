import React, { useRef, useState, useEffect, useCallback } from "react";
import Input from "../../components/Input";
import { Form } from "@unform/web";
import NotificationAlert from "react-notification-alert";
import history from "../../../services/history";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Row,
  Col,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import CheckboxInput from "../../components/Checkbox";
import AvatarInput from "../../components/AvatarInput";
import api from "../../../services/api";

function EditRestaurantExpress(props) {
  const formRef = useRef(null);
  const { id } = props.match.params;
  const [restaurant, setRestaurant] = useState([]);
  const [tax, setTax] = useState();
  const token = localStorage.getItem("@QRExpress:token");
  const notificationAlert = useRef(null);
  const [priority, setPriority] = useState(false);
  const checked = true;
  const [deliveryAllowed, setDeliveryAllowed] = useState(false);

  function changePriority() {
    setPriority(!priority);
  }

  function changeDeliveryAllowed() {
    console.log(!deliveryAllowed);
    setDeliveryAllowed(!deliveryAllowed);
  }

  const [modal, setModal] = useState(false);
  function toggleModal() {
    setModal(!modal);
  }

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const getInfo = useCallback(async () => {
    try {
      const response = await api.get(`admin/restaurants/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRestaurant(response.data);
      setTax(parseFloat(response.data.tax_multiplier * 100).toFixed(2));
      setDeliveryAllowed(response.data.is_delivery_allowed);
      console.log(response.data);
    } catch (err) {
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [id, token]);

  async function updateProfile(data) {
    try {
      console.log(data);
      data.tax_multiplier = data.tax_multiplier / 100;
      const response = await api.put(`admin/restaurants/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response);
      // setRestaurant(response.data);
      // console.log(data);
      notify("tr", "success", "Atualizado com sucesso!");
    } catch (err) {

      notify("tr", "danger", err?.response?.data?.message || "Erro ao atualizar.");
    }
  }

  async function deleteRestaurant() {
    try {
      await api.delete(`admin/restaurants/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      toggleModal();

      if (history.location.search === '?single=false') {
        history.go(-2);
      } else {
        history.goBack();
      }
    } catch (e) {
      notify("tr", "danger", "Erro ao apagar restaurantes.");
    }
  }

  // let tax = !isNaN(restaurant.tax_multiplier) ? restaurant.tax_multiplier : 0;
  let initialData = {
    fantasy_name: restaurant.fantasy_name,
    username: restaurant.username,
    email: restaurant.email,
    phone: restaurant.phone,
    sms_service_price: restaurant.sms_service_price,
    tax_multiplier: tax,
    avatar: restaurant.avatar,
    is_priority: restaurant.is_priority,
  };

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="3">
            <Button color="default" onClick={() => history.goBack()}>
              <span className="btn-label">
                <i className="nc-icon nc-minimal-left" />
              </span>
              Voltar
            </Button>
          </Col>

          <Col>
            <Button color="danger" onClick={toggleModal} style={{ float: 'right' }}>
              Apagar
            </Button>
          </Col>
        </Row>
        <Form ref={formRef} initialData={initialData} onSubmit={updateProfile}>
          <Row>
            <Col md="4">
              <div
                className="image"
                style={{ placeContent: "center", padding: 30 }}
              >
                <AvatarInput name="avatar" />
              </div>
            </Col>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Editar Perfil</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="pr-1" md="7">
                      <label>Nome</label>
                      <Input
                        name="fantasy_name"
                        placeholder="Nome"
                        type="text"
                      />
                    </Col>
                    <Col className="pl-1" md="5">
                      <FormGroup>
                        <label>Username</label>
                        <Input
                          name="username"
                          
                          placeholder="Username"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8" className="pr-1">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">E-mail</label>
                        <Input name="email" type="email" />
                      </FormGroup>
                    </Col>
                    <Col md="4" className="pl-1">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Telefone</label>
                        <Input
                          name="phone"
                          placeholder="(xx) xxxxx-xxxx"
                          type="phone"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="8" className="pr-1">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Serviço de SMS (R$)
                        </label>
                        <Input name="sms_service_price" type="text" />
                      </FormGroup>
                    </Col>
                    <Col md="4" className="pl-1">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Taxa de Manutenção (%)
                        </label>
                        <Input name="tax_multiplier" type="text" />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Label sm="2">Delivery</Label>
                    <Col style={{marginTop: 5, marginLeft: -40}} sm="1">
                        <CustomInput type="switch" id="switchClone" name="is_delivery_allowed"/>
                    </Col> 
                  </Row> */}
                  <Row>
                    <Col sm="10">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Delivery
                        </label>
                        {deliveryAllowed ? (
                          <CheckboxInput
                            name="is_delivery_allowed"
                            onChange={()=> changeDeliveryAllowed()}
                            checked
                          />
                        ) : (
                          <CheckboxInput
                            name="is_delivery_allowed"
                            onChange={()=>changeDeliveryAllowed()}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  
                  {!restaurant.is_single_restaurant && 
                  <Row>
                    <Col sm="10">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Vitrine Principal
                        </label>
                        {priority ? (
                          <CheckboxInput
                            name="is_priority"
                            onChange={changePriority}
                            value={checked}
                          />
                        ) : (
                          <CheckboxInput
                            name="is_priority"
                            onChange={changePriority}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>}
                  
                  <Row style={{ paddingTop: 15 }}>
                    <Col md="4" className="ml-auto">
                      <Button color="success" type="submit">
                        Atualizar Perfil
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader> Apagar Restaurante </ModalHeader>
        <ModalBody>
          <Container>
            Deseja realmente apagar o restaurante <b>{initialData.fantasy_name}</b>?
            <p> Ao confirmar, você estará removendo este restaurante do sistema,
            assim como todos os <i style={{color: 'yellow'}}>pedidos, produtos, categorias e etc</i>,
            que estejam associados a ele. </p>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deleteRestaurant}>
            Confirmar
          </Button>{' '}
          <Button color="danger" onClick={toggleModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default EditRestaurantExpress;
